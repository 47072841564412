import React from 'react'

import MobileHeader from '../components/common/MobileHeader'
import CombineMenu from '../components/combine_menu/CombineMenu'

import Footer from '../components/combine_menu/Footer'
import SwipeMenu from '../components/games/detail/SwipeMenu'
import Intro from '../components/games/detail/Intro'

import Features from '../components/games/detail/Features'
import System from '../components/games/detail/System'
import Gallery from '../components/games/detail/Gallery'
import Download from '../components/games/detail/Download'
import Coupon from '../components/games/detail/Coupon'

import BottomTheme from '../components/common/theme/BottomTheme'
import Recommend from '../components/games/detail/Recommend'

/* 게임별 Component 추가 필요함 */
import IcarusmkoClass from '../components/games/detail/class/IcarusmkoClass'
import FishislandkoClass from '../components/games/detail/class/FishislandkoClass'
import HeroClass from '../components/games/detail/class/HeroClass'
import O2jamClass from '../components/games/detail/class/O2jamClass'
import RaiddungeonClass from '../components/games/detail/class/RaiddungeonClass'

/* 게임별 json 파일 추가 필요함 */
import icarusmkoData from '../components/games/detail/data/icarusmko.json'
import fishislandkoData from '../components/games/detail/data/fishislandko.json'
import heroData from '../components/games/detail/data/hero.json'
import o2jamData from '../components/games/detail/data/o2jam.json'
import raiddungeonData from '../components/games/detail/data/raiddungeon.json'

import { HTML_TITLE_PREFIX } from '../utils/Config'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import '../assets/css/swiper/pagination.scss'
import 'swiper/components/effect-fade/effect-fade.min.css'
import '../assets/css/game/GameDetail.scoped.scss'

class GameDetail extends React.Component {
    constructor(props) {
        super(props)

        var jsonData = ''

        if (props.match.params.service_code === 'icarusmko') {
            jsonData = icarusmkoData
        } else if (props.match.params.service_code === 'fishislandko') {
            jsonData = fishislandkoData
        } else if (props.match.params.service_code === 'hero') {
            jsonData = heroData
        } else if (props.match.params.service_code === 'o2jam') {
            jsonData = o2jamData
        } else if (props.match.params.service_code === 'raiddungeon') {
            jsonData = raiddungeonData
        } else {
            alert('존재하지 않는 게임입니다.')
            window.location.href = '/'
        }
        this.state = {
            service_code: props.match.params.service_code,
            intro_data: jsonData.intro,
            features_data: jsonData.features,
            world_view: jsonData.world_view,
            class_data: jsonData.class,
            system_data: jsonData.system,
            gallery_data: jsonData.gallery,
            download_data: jsonData.download,
            coupon_data: jsonData.coupon,
        }
    }

    componentDidMount() {
        document.title = HTML_TITLE_PREFIX + ' - ' + this.state.intro_data.title
    }
    render() {
        const { location } = this.props
        /* 게임별 Component 추가 필요함 */
        const classes = {
            IcarusmkoClass,
            FishislandkoClass,
            HeroClass,
            O2jamClass,
            RaiddungeonClass,
        }
        const classComponentName =
            this.state.service_code.charAt(0).toUpperCase() +
            this.state.service_code.slice(1) +
            'Class'
        const ClassCompoent = classes[classComponentName]

        return (
            <>
                <MobileHeader current_menu={'all_games'} />
                <CombineMenu />
                <div
                    id="content"
                    className="clearfix gameDetail"
                    data-game={this.state.service_code}
                >
                    <Intro
                        intro_data={this.state.intro_data}
                        download_data={this.state.download_data}
                    />
                    <SwipeMenu />

                    {// 쿠폰 데이터 : 드루와 던전만 있음
                    location.pathname === '/game/detail/raiddungeon' ? (
                        <Coupon coupon_data={this.state.coupon_data} />
                    ) : null}

                    <Features
                        features_data={this.state.features_data}
                        world_view={this.state.world_view}
                    />

                    {// 갤러리 데이터 : 오투잼은 없음
                    location.pathname !==
                    ('/game/detail/o2jam' || '/game/detail/raiddundeon') ? (
                        <ClassCompoent class_data={this.state.class_data} />
                    ) : (
                        <></>
                    )}
                    <System system_data={this.state.system_data} />
                    {// 갤러리 데이터 : 오투잼은 없음
                    location.pathname !== '/game/detail/o2jam' ? (
                        <Gallery gallery_data={this.state.gallery_data} />
                    ) : (
                        <></>
                    )}
                    <Download download_data={this.state.download_data} />

                    <Recommend />

                    <BottomTheme />
                </div>
                <Footer />
            </>
        )
    }
}
export default GameDetail
