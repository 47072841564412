import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'

class Recommend extends React.Component {
    render() {
        return (
            <section className="recommend" id="recommend">
                <div className="row-w">
                    <div className="all-list _1  clearfix">
                        <h3>추천 게임</h3>
                        <div className="pc-list">
                            <div
                                className="clearfix  slick-slider"
                                id="recom"
                            >
                                <div className="slick-list draggable">
                                    <div className="slick-track">
                                        <Swiper
                                            slidesPerView={3}
                                            loop={true}
                                            autoplay={{
                                                delay: 3000,
                                                disableOnInteraction: false,
                                            }}
                                            navigation={true}
                                        >

                                            <SwiperSlide >
                                                <div className="item" >
                                                    <Link to="/game/detail/fishislandko" title="피시 아일랜드 바로가기">
                                                        <div className="normal clearfix">
                                                            <div className="img-area">
                                                                <img
                                                                    src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/fishislandko-big-thumb-min.jpg"
                                                                    alt="피시 아일랜드"
                                                                />
                                                                <span className="logo">
                                                                        <img
                                                                            src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/fishislandko-logo-min.png"
                                                                            alt="피시 아일랜드 로고"
                                                                        />
                                                                    </span>
                                                            </div>
                                                            <div className="tit">
                                                                <dl>
                                                                    <dt>
                                                                        피시 아일랜드
                                                                    </dt>
                                                                    <dd>
                                                                        <span className="fas fa-desktop" />
                                                                        &nbsp;&nbsp;캐주얼, 낚시 게임
                                                                    </dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            </SwiperSlide>

                                            <SwiperSlide>

                                                    <div className="item">
                                                        <Link to="https://r2beat-ko.valofe.com/landing/cbt/" title="알투비트 바로가기">
                                                            <div className="normal">
                                                                <div className="img-area">
                                                                    <img
                                                                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/r2beatko-big-thumb-min.jpg"
                                                                        alt="알투비트"
                                                                    />
                                                                    <span className="logo">
                                                                        <img
                                                                            src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/r2beatko-logo-min.png"
                                                                            alt="알투비트 로고"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="tit">
                                                                    <dl>
                                                                        <dt>
                                                                            알투비트  {/*<span style={{color: '#5BE4F8', fontWeight: 'normal'}}>바로가기!</span>*/}
                                                                        </dt>
                                                                        <dd>
                                                                            <span className="fas fa-desktop" />
                                                                            &nbsp;&nbsp;리듬 액션
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>

                                            </SwiperSlide>
                                                
                                            <SwiperSlide >

                                                    <div className="item">
                                                        <Link to="https://at-ko.valofe.com" title="아틀란티카 홈페이지 바로가기">
                                                            <div className="normal">
                                                                <div className="img-area">
                                                                    <img
                                                                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/atko-big-thumb-min.jpg"
                                                                        alt="아틀란티카"
                                                                    />
                                                                    <span className="logo">
                                                                        <img
                                                                            src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/atko-logo-min.png"
                                                                            alt="아틀란티카 로고"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="tit">
                                                                    <dl>
                                                                        <dt>
                                                                            아틀란티카
                                                                        </dt>
                                                                        <dd>
                                                                            <span className="fas fa-desktop" />
                                                                            &nbsp;&nbsp;MMORPG
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>

                                            </SwiperSlide>
                                            <SwiperSlide >

                                                    <div className="item">
                                                        <Link to="https://blacksquad.valofe.com" title="블랙스쿼드 홈페이지 바로가기">
                                                            <div className="normal">
                                                                <div className="img-area">
                                                                    <img
                                                                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/blacksquadko-big-thumb-min.jpg"
                                                                        alt="블랙스쿼드"
                                                                    />
                                                                    <span className="logo">
                                                                        <img
                                                                            src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/blacksquadko-logo-min.png"
                                                                            alt="블랙스쿼드 로고"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="tit">
                                                                    <dl>
                                                                        <dt>
                                                                            블랙스쿼드
                                                                        </dt>
                                                                        <dd>
                                                                            <span className="fas fa-desktop" />
                                                                            &nbsp;&nbsp;MMORPG
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>

                                            </SwiperSlide>
                                            <SwiperSlide >
                                                    <div className="item" >
                                                        <Link to="https://icarusonline.co.kr" title="이카루스 온라인 홈페이지 바로가기">
                                                            <div className="normal clearfix">
                                                                <div className="img-area">
                                                                    <img
                                                                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/ica-big-thumb-min.jpg"
                                                                        alt="이카루스 온라인"
                                                                    />
                                                                    <span className="logo">
                                                                        <img
                                                                            src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/ica-logo-min.png"
                                                                            alt="이카루스 온라인 로고"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="tit">
                                                                    <dl>
                                                                        <dt>
                                                                            이카루스
                                                                            온라인
                                                                        </dt>
                                                                        <dd>
                                                                            <span className="fas fa-desktop" />
                                                                            &nbsp;&nbsp;MMORPG
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>

                                            </SwiperSlide>
                                            <SwiperSlide >

                                                    <div className="item">
                                                        <Link to="http://lostsaga-ko.valofe.com/" title="로스트 사가 홈페이지 바로가기">
                                                            <div className="normal clearfix">
                                                                <div className="img-area">
                                                                    <img
                                                                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/lostsaga-big-thumb-min.jpg"
                                                                        alt="로스트사가"
                                                                    />
                                                                    <span className="logo">
                                                                        <img
                                                                            src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/game/enroll/lostsaga-logo-min.png"
                                                                            alt="로스트 사가 로고"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="tit">
                                                                    <dl>
                                                                        <dt>
                                                                            로스트사가
                                                                        </dt>
                                                                        <dd>
                                                                            <span className="fas fa-desktop" />
                                                                            &nbsp;&nbsp;액션
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Recommend
