import React from 'react'
import { Link } from 'react-router-dom'

import './Social.scoped.scss'

class Social extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // class_data: props.class_data,
            // openYoutubePopup: false,
            // youtubeURL: '',
            articleUrl: '',
            articlePage: false,
            comment_count: props.comment_count,
            board_idx: props.board_idx,
        }
    }
    fnSeeMore(articleNum) {
        this.setState({
            articlePage: !this.state.articlePage,
            articleUrl: articleNum,
        })
    }
    render() {
        return (
            <>
                <div className="social">
                    <div className="sum-activity clearfix">
                        <span className="left">
                            {' '}
                            <i className="icon like" />
                            132
                        </span>
                        <Link
                            to="#"
                            onClick={e => this.props.handleReplyWrite(e)}
                            data-boardidx={this.state.board_idx}
                        >
                            <span
                                className="right"
                                data-boardidx={this.state.board_idx}
                            >
                                <i
                                    className="icon reply"
                                    data-boardidx={this.state.board_idx}
                                />
                                {this.state.comment_count}
                            </span>
                        </Link>
                    </div>
                    {/* 버튼 */}
                    <div className="btns-social">
                        <Link to="#" className="btns like" />
                        <Link
                            to="#"
                            className="btns reply"
                            onClick={e => this.props.handleReplyWrite(e)}
                            data-boardidx={this.state.board_idx}
                        />
                        <Link to="#" className="btns share" />
                    </div>
                </div>
            </>
        )
    }
}

export default Social
