import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper'
import Iframe from 'react-iframe'

import '../../../../assets/css/game/detail/IcarusmkoClass.scoped.scss'

SwiperCore.use([EffectFade, Navigation, Pagination])

class IcarusmkoClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            class_data: props.class_data,
            openYoutubePopup: false,
            youtubeURL: '',
        }
    }

    fnYtPlayClick(selUrl) {
        this.setState({
            openYoutubePopup: !this.state.openYoutubePopup,
            youtubeURL: selUrl,
        })
    }

    render() {
        const bullet = [
            {

                name: '프리스트',
            },
            {

                name: '글래디에이터',
            },
            {

                name: '아티스트',
            },
            {

                name: '위저드',
            },
            {

                name: '레인저',
            },
            {

                name: '어쌔신',
            },
        ]

        return (
            <>
                {/*<!-- 직업소개  -->*/}
                <section id="class" className="icarusmko">
                    <div className="bg">
                        <div className="row-w">
                            <h3>클래스 소개</h3>
                            <div
                                id="classSlider"
                                className="slider-single classBox"
                            >
                                <div className="slick-list draggable">
                                    <div className="slick-track">
                                        <Swiper
                                            effect={'fade'}
                                            spaceBetween={30}
                                            autoplay={true}
                                            pagination={{
                                                clickable: true,
                                                renderBullet: (
                                                    index,
                                                    className
                                                ) => {
                                                    return (
                                                        '<span class="swiper-pagination-bullet">' +
                                                       // '<img src="' +
                                                       // bullet[index]['image'] +
                                                       // '" />' +
                                                        '<span class="bullet-name">' +
                                                        bullet[index]['name'] +
                                                        '</span>' +
                                                        '</span>'
                                                    )
                                                },
                                            }}
                                        >
                                            {this.state.class_data.map(
                                                (class_data, index) => (
                                                    <SwiperSlide>
                                                        <div className="clearfix">
                                                            <img
                                                                src={
                                                                    class_data.img
                                                                }
                                                                alt=""
                                                                className="bg-img"
                                                            />
                                                            <article className="article">
                                                                <div className="class-desc">
                                                                    <p className="desc-top">
                                                                        {
                                                                            class_data.top_description
                                                                        }
                                                                    </p>
                                                                    <p className="desc-name">
                                                                        {
                                                                            class_data.name
                                                                        }
                                                                    </p>
                                                                    <p
                                                                        className="desc-bottom"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                                class_data.bottom_description,
                                                                        }}
                                                                    />
                                                                    <p
                                                                        className="desc-weapon"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                                class_data.weapon,
                                                                        }}
                                                                    />
                                                                    <p
                                                                        className="desc-skill"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                                class_data.skill_description,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <Link
                                                                    to="#"
                                                                    className="btn-class-video"
                                                                    onClick={e =>
                                                                        this.fnYtPlayClick(
                                                                            class_data.video_url
                                                                        )
                                                                    }
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            class_data.video_desc,
                                                                    }}
                                                                />
                                                            </article>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            )}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                            {/*<!-- #classSlider  -->*/}
                        </div>
                        {/*<!-- row-w -->*/}
                    </div>
                    {/*<!-- ./bg -->*/}
                    {/*<!-- ./ row-w -->*/}
                </section>
                {/*<!-- ./ 직업소개 -->*/}
                {/*<!-- 클래스 영상 -->*/}
                <div
                    className="layer-wrap"
                    id="yt"
                    style={{
                        display: this.state.openYoutubePopup ? 'block' : 'none',
                        opacity: this.state.openYoutubePopup ? 1 : 0,
                    }}
                >
                    <div className="pop-layer">
                        <div className="pop-container">
                            <Link
                                to="#"
                                className="close"
                                id="closeyt"
                                onClick={e => this.fnYtPlayClick()}
                            >
                                close
                            </Link>
                            <h2 className="skip">이카루스 클래스 스킬 영상</h2>
                            <div className="frame">
                                <Iframe
                                    width="1152"
                                    height="650"
                                    className="yt_meida_popup"
                                    id="ytURL"
                                    url={this.state.youtubeURL}
                                    frameBorder="0"
                                    allow="fullscreen"
                                    allowFullScreen={true}
                                    allowscriptaccess="always"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="shadow yt"
                        style={{
                            display: this.state.openYoutubePopup
                                ? 'block'
                                : 'none',
                            opacity: this.state.openYoutubePopup ? 1 : 0,
                        }}
                    />
                </div>
                {/*<!-- ./ 클래스 영상 -->*/}
            </>
        )
    }
}

export default IcarusmkoClass
