import React from 'react'
import SwiperCore, { EffectFade, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import axios from 'axios'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import '../../assets/css/swiper/pagination.scss'
import 'swiper/components/effect-fade/effect-fade.min.css'
import 'classnames/bind'
import '../../assets/css/main/mainBanner.css'
import { Event } from '../Tracking';
import { IMAGE_DNS } from '../../utils/Config'
import Spinner from '../../components/common/Spinner'

import InfoContext from '../../contexts/InfoContext'
import { redirectURL } from '../../utils/CommonFunc'

class MainBanner extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        SwiperCore.use([EffectFade, Pagination, Autoplay])
        this.state = {
            thumbsSwiper: '',
            isLoading: true,
            ssoinfo:
                context.state.ssoinfo === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.user_id === false
                    ? 'none'
                    : context.state.user_id,
            jumin: context.state.jumin === false ? 'none' : context.state.jumin,
            mainBannerList: '',
            mainBannertitle: '',
        }
        this.getFeaturedList()
    }

    getFeaturedList() {
        let data = {}
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS + '/v1/Vfun/getFeatureInfo',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                var gameTitle = [[], [], [], [], [], [], [], []]
                res.data.data.map(
                    (banner, index) =>
                        (gameTitle[index]['gameTitle'] = banner.title)
                )
                //console.log(gameTitle[0].gameTitle)
                this.setState({
                    mainBannerList: res.data.data,
                    mainBannertitle: gameTitle,
                    isLoading: false,
                })
                //console.log(gameTitle)
                //console.log(this.state.mainBannertitle)
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }

    render() {
        return (
            <section className="visual">
                {this.state.isLoading === true ? <Spinner /> : null}
                <div className="sliderContainer">
                    {this.state.isLoading === true ? (
                        <Swiper
                            effect={'fade'}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                            }}
                            watchSlidesProgress
                            className="mySwiper"
                        />
                    ) : (
                        <Swiper
                            effect={'fade'}
                            pagination={{
                                clickable: true,
                                renderBullet: (index, className) => {
                                    return (
                                        '<span class="swiper-pagination-bullet">' +
                                        this.state.mainBannertitle[
                                            parseInt(index)
                                        ]['gameTitle'] +
                                        '</span>'
                                    )
                                },
                            }}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                            }}
                            watchSlidesProgress
                            className="mySwiper"
                        >
                            {this.state.isLoading === true ? (
                                <SwiperSlide />
                            ) : (
                                this.state.mainBannerList.map(
                                    (banner, index) => (
                                        <SwiperSlide
                                            key={index}
                                            onClick={e =>
                                                [redirectURL(
                                                    banner.link_url,
                                                    '_self'
                                                ),
                                                Event("메인배너", "Click",  banner.title)
                                                ]
                                            }
                                        >
                                            <img
                                                className="img"
                                                src={banner.image_url}
                                                alt={banner.title}
                                            />
                                            <article className="article">
                                                <img
                                                    className="logo"
                                                    src={
                                                        IMAGE_DNS +
                                                        '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                        banner.service_code +
                                                        '-logo' +
                                                        '-min.png'
                                                    }
                                                    alt={banner.title}
                                                />
                                                <span
                                                    className="tit"
                                                    dangerouslySetInnerHTML={{
                                                        __html: banner.desc,
                                                    }}
                                                />
                                            </article>
                                        </SwiperSlide>
                                    )
                                )
                            )}
                        </Swiper>
                    )}
                </div>
            </section>
        )
    }
}

export default MainBanner
