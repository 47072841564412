//import ReactGA from "react-ga";
import ReactGA from "react-ga4";

export const initGA = (trackingID) => {
    ReactGA.initialize(trackingID);
    //ReactGA.initialize('G-312620525');
}


export const PageView = () => {
    if (typeof window !== 'undefined') {
        ReactGA.pageview(window.location.pathname +
            window.location.search);
    }
 /*   ReactGA.pageview(window.location.pathname +
        window.location.search);*/
}

/**
 * Event - Add custom tracking event.
 * //@param {string} window
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
    ReactGA.event({
        //clientWindow: window,
        category: category,
        action: action,
        label: label
    });
};