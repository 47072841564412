import React from 'react'
import { Link } from 'react-router-dom'

import { IMAGE_DNS } from '../../../utils/Config'

import { redirectURL } from '../../../utils/CommonFunc'

class Intro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            intro_data: props.intro_data,
            download_data: props.download_data,
        }
    }
    render() {
        return (
            <section className="more-visual mt0 clearfix">
                <img
                    className="img"
                    src={this.state.intro_data.image_url}
                    alt={this.state.intro_data.title}
                />
                <div className="more-cont">
                    <div className="row-w">
                        <h2 className="more-name">
                            {this.state.intro_data.title}
                            <span className="sub-name" />
                        </h2>
                        <p
                            className="more-desc"
                            dangerouslySetInnerHTML={{
                                __html: this.state.intro_data.description,
                            }}
                        />
                        <p className="more-info clearix">
                            <span className="genre">
                                {this.state.intro_data.genre}
                            </span>
                            {this.state.intro_data.naver_cafe_url !== undefined ? (
                            <>
                                &nbsp;&nbsp;| {/*&nbsp;
                                <Link to="#" className="btn-like">
                                    <span className="fa fa-heart" id="like22" />
                                </Link>
                                &nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;
                                <Link to="#" className="btn-share">
                                    <span className="fa fa-share-alt" />
                                </Link>
                                &nbsp;&nbsp;&nbsp;|*/}
                                &nbsp;&nbsp;
                                <span className="list sns">
                                    <Link
                                        className="icon-a"
                                        to="#"
                                        onClick={e => redirectURL(this.state.intro_data.naver_cafe_url,'_blank')}
                                    >
                                        <span className="icon naver" />
                                    </Link>
                                </span>
                            </>
                            ) : (
                                null
                            )}
                        </p>
                        <ul className="more-download clearfix ">
                            {this.state.download_data.store_url.map(
                                (store, index) => (
                                    <li key={index}>
                                        <Link
                                            to="#"
                                            onClick={e =>
                                                redirectURL(store.url, '_blank')
                                            }
                                            className={
                                                'btn-download ' + store.name
                                            }
                                        >
                                            <img
                                                src={
                                                    IMAGE_DNS +
                                                    '/Valofe_file/web/vfun-ko/images/common/btn-download-' +
                                                    store.name +
                                                    '.png'
                                                }
                                                alt={store.name + ' download'}
                                            />{' '}
                                            {store.full_name}
                                        </Link>
                                    </li>
                                )
                            )}
                        </ul>
                        <div className="wrap-input" id="pushToggle">
                            Receive the latest news for{' '}
                            <span className="color-emp">
                                {this.state.intro_data.title}
                            </span>
                            <label className="switch">
                                <input
                                    className="push_box js-push-btn"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                    </div>
                    {/*<!-- row-w -->*/}
                </div>
            </section>
        )
    }
}

export default Intro
