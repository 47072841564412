import React from 'react'
import { Link } from 'react-router-dom'
import WorldView from './WorldView'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import './Features.scoped.scss'

SwiperCore.use([Autoplay, Navigation])


class Features extends React.Component {
    constructor(props) {
        super(props)
        this.handleResize = this.handleResize.bind(this)
        this.state = {
            features_data: props.features_data,
            world_view: props.world_view,
            service_code: props.service_code,
            openFeaturePopup: false,
            slideNum : 0,
            feature_slider: [],
            innerWidth: window.innerWidth
        }
        window.addEventListener('resize', e => this.handleResize())
    }
    handleResize() {
        this.setState({ innerWidth: window.innerWidth })
    }
    changeFeaturePopup(e) {
        var feature_slider = [];
        var start = parseInt(e.target.dataset.slidenum)+1
        var end = start + 1
        //console.log('Features_start::: ' + start)
        //console.log('Features_end::: ' + end)
        if(end > this.state.features_data.length){
            start = 0;
            end = 1;
        }
        for(var i=0;i<this.state.features_data.length;i++){
            feature_slider.push(this.state.features_data.slice(start, end)[0])
            start = start + 1;
            end = start + 1;
            if(end > this.state.features_data.length){
                start = 0;
                end = 1;
            }
        }

        this.setState({
            openFeaturePopup: !this.state.openFeaturePopup,
            slideNum: e.target.dataset.slidenum !== undefined ? e.target.dataset.slidenum : 0,
            feature_slider: e.target.dataset.slidenum !== undefined ? feature_slider : []
        })
    }

    render() {

        return (
            <>
                {/*<!-- 게임특징, 슬라이드  -->*/}
                <section id="introduction" className="">
                    <div className="row-w">
                        <h3>게임 소개</h3>

                        <div className="features">
                            <h4>게임 특징</h4>
                            <Swiper
                                slidesPerGroup={1}
                                spaceBetween={30}
                                loop={true}
                                navigation={true}
                                slidesPerView={
                                    this.state.innerWidth > 640
                                        ? 2
                                        : 1
                                }

                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                               /* on= {{
                                    slideChange: function (el) {
                                        console.log('슬라이드 체인지');
                                        document.querySelector('.swiper-slide').each(function () {
                                            var youtubePlayer = document.querySelector(this).querySelector('iframe').get(0);
                                            if (youtubePlayer) {
                                                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                                            }
                                        });
                                    },
                                }}*/
                                id="featureSlider"
                            >
                                {this.state.features_data.map(
                                    (feature, index) => (
                                        <SwiperSlide key={index}>
                                            <Link
                                                to="#"
                                                onClick={e => this.changeFeaturePopup(e) }
                                            >
                                                {feature.youtube_url ?
                                                    <div className="item youtube">
                                                        <iframe id="player1"
                                                                src={'https://www.youtube.com/embed/' + feature.youtube_url + '?enablejsapi=1&html5=1&rel=0'}
                                                                frameBorder="0" allowFullScreen iframe-video="true"
                                                                title="Game Features Youtube"
                                                        />
                                                    </div>
                                                    :

                                                    <div className="item image">
                                                        <img
                                                            src={feature.image_url}
                                                            alt={feature.title}
                                                            data-slidenum={index}
                                                        />
                                                    </div>
                                                }
                                            </Link>
                                        </SwiperSlide>
                                    )
                                )}
                            </Swiper>
                        </div>
                        {/*<!--// 게임 특징 -->*/}
                        {this.state.world_view !== undefined ? (
                            <WorldView world_view={this.state.world_view} service_code={this.state.service_code} />
                        ) : null}
                        {/*<!--// 월드 뷰 -->*/}
                    </div>
                </section>
                {/*<!-- 팝업 3. 피처드 팝업 -->*/}
                <div
                    className="layer-wrap"
                    id="featureList"
                    style={{ display: this.state.openFeaturePopup ? 'block' : 'none'}}
                >
                    <div className="pop-layer">
                        <div className="pop-container">
                            <Link
                                to="#"
                                className="close"
                                id="closeFeatureList"
                                onClick={e => this.changeFeaturePopup(e)}
                            >
                                close
                            </Link>
                            <h2 className="skip">게임 특징</h2>
                            <div className="frame">
                                <div className="wrap-featureList card">
                                    <Swiper
                                        id="featureBigSlide"
                                        slidesPerView={1}
                                        loop={true}
                                        navigation={true}
                                    >
                                         {this.state.feature_slider.map(
                                            (feature, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className="wrap-media type2 card item image">
                                                        <div className="media">
                                                            <div className="bg-gradient" />
                                                            {feature.youtube_url ?
                                                                <iframe id="player2"
                                                                        src={'https://www.youtube.com/embed/' + feature.youtube_url + '?enablejsapi=1&html5=1&rel=0'}
                                                                        frameBorder="0" allowFullScreen
                                                                        iframe-video="true"
                                                                        title="Game Feature Image Slide"
                                                                />
                                                                :
                                                                <img className="art-img"
                                                                     src={feature.image_url}
                                                                     alt={feature.alt}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        )}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow featureList" />
                </div>
                {/*<!-- ./ LAYER-WRAP -->*/}
            </>
        )
    }
}

export default Features
