import React from 'react'

import MobileHeader from '../components/common/MobileHeader'
import CombineMenu from '../components/combine_menu/CombineMenu'
import DownloadContents from '../components/games/DownloadContents'
import Footer from '../components/combine_menu/Footer'
import { HTML_TITLE_PREFIX } from '../utils/Config'

class Download extends React.Component {
    componentDidMount() {
        document.title = HTML_TITLE_PREFIX + ' - 다운로드'
    }
    render() {
        return (
            <>
                <MobileHeader current_menu={'download'} />
                <CombineMenu />
                <div id="content" className="clearfix">
                    <DownloadContents />
                </div>
                <Footer />
            </>
        )
    }
}

export default Download
