import React, { Component } from 'react'

import MobileHeader from '../common/MobileHeader'
import CombineMenu from '../combine_menu/CombineMenu'
import Footer from '../combine_menu/Footer'

import '../../assets/css/error/InspectionBg.scoped.scss'

class InspectionBgContents extends Component {
    render() {
        return (
            <>

                <div id="wrapper">
                    <MobileHeader/>
                    <CombineMenu/>
                    <div id="content" className="clearfix inspectionBg">
                        <div className="chr"/>
                        <section id="inspection" className="clearfix mb0 pb0">
                            <article>
                                <div id="cont">

                                    <div className="title">
                                        <span className="rightCont-padding">
                                            <div className="view">
                                              <div className="plane main">
                                                <div className="circle"/>
                                                <div className="circle"/>
                                                <div className="circle"/>
                                                <div className="circle"/>
                                                <div className="circle"/>
                                                <div className="circle"/>
                                              </div>
                                            </div>
                                            <div className="type-writer">
                                                <span className="line-1 anim-typewriter">서비스  점검중. . .</span>
                                            </div>
                                        </span>
                                    </div>
                                    <h2>
                                        <span className="rightCont-padding">
                                        정기점검 안내
                                        </span>
                                    </h2>
                                    <h3>
                                        <span className="rightCont-padding">
                                            안녕하세요, <span className="color-bluegreen">알투비트</span>입니다.
                                        </span>
                                    </h3>
                                    <p className="ph greeting">
                                        <span className="rightCont-padding">
                                            알투비트를 사랑해주시고 관심가져 주시는 여러분께 진심으로 감사드립니다.
                                            <span className="hidden-br"><br/></span>
                                            <span className="show-nbsp">&nbsp;</span>
                                            2021년 11월 17일 (수) 정기 점검 관련 안내 말씀드립니다.
                                        </span>
                                    </p>
                                    {/*<div className="pbarContainer">
                                        <div className="bar hours">
                                            <span className="progressValue" />
                                            <span className="progress stripped animated" />
                                        </div>
                                    </div>*/}
                                    <div className="inspect-info">
                                         <span className="rightCont-padding">
                                            <h4 className="color-bluegreen">
                                                점검안내
                                            </h4>

                                            <table>
                                                <colgroup>
                                                    <col width="27px"/>
                                                    <col width="160px"/>
                                                    <col width="*"/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <td><span className="fa fa-calendar color-blue" /></td>
                                                    <td>점검일</td>
                                                    <td>2021년&nbsp;11월&nbsp;<span className="startDay" data-start-day="1" />17일
                                                    </td>
                                                        {/* <span className="endDay" data-end-day="30"/> 5日(木)  */}
                                                </tr>
                                                <tr>
                                                    <td><span className="fa fa-clock-o color-blue" /></td>
                                                    <td>점검시간</td>
                                                    <td>
                                                        <span className="startTime" data-start-time="0" data-start-min="00" />00：00 ~
                                                        &nbsp;<span data-end-time="24" data-end-min="00" className="endTime" />24 : 00</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="fa fa-server color-blue" /></td>
                                                    <td>대상</td>
                                                    <td>전체게임</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </span>
                                    </div>
                                    <span className="rightCont-padding has-message">
                                        <p className="ph message color-lightpurple">
                                            점검 시간은 작업 진행에 따라 단축 또는 연장될 수 있습니다.
                                            <span className="hidden-br"><br/></span>
                                            <span className="show-nbsp">&nbsp;</span>
                                            안정적인 데이터 저장을 위해서 점검 실시 이전에 게임 접속 종료를 부탁드립니다.

                                        </p>
                                    </span>
                                </div>
                            </article>
                        </section>
                    </div>
                    <Footer/>
                </div>
            </>
        )
    }
}

export default InspectionBgContents