import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import { IMAGE_DNS } from '../../../utils/Config'
import { redirectURL } from '../../../utils/CommonFunc'

import InfoContext from '../../../contexts/InfoContext'

import './PageHeader.scoped.scss'

import fishislandkoData from '../../../components/games/detail/data/fishislandko.json'
import vfunkoData from '../../../components/games/detail/data/vfunko.json'

class PageHeader extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        var jsonData = ''
        if (props.service_code === 'fishisland-ko') {
            jsonData = fishislandkoData.intro
        } else if (props.service_code === 'vfun-ko') {
            jsonData = vfunkoData.intro
        }

        this.state = {
            isLogin: context.state.is_login,
            ssoinfo:
                context.state.ssoinfo === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.user_id === false
                    ? 'none'
                    : context.state.user_id,
            jumin: context.state.jumin === false ? 'none' : context.state.jumin,
            service_code: this.props.service_code,
            articleUrl: '',
            articlePage: false,
            gamedata: jsonData,
            boardLikeCount: 0,
            userBoardLikeFlag: false,
        }
    }

    fnSeeMore(articleNum) {
        this.setState({
            articlePage: !this.state.articlePage,
            articleUrl: articleNum,
        })
    }

    putLikeBoard() {
        if (!this.state.isLogin) {
            alert('로그인이 필요한 서비스 입니다.')
            return false
        } else {
            let data = { service_code: this.state.service_code }
            axios
                .post(
                    process.env.REACT_APP_VFUN_API_DNS +
                        '/v1/Vfun/putCommunityBoardLike',
                    JSON.stringify(data),
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            ssoinfo: this.state.ssoinfo,
                            userid: this.state.userid,
                            jumin: this.state.jumin,
                        },
                    }
                )
                .then(res => {
                    var boardLikeCount = 0
                    if (res.data.result_code === 1) {
                        boardLikeCount = res.data.data
                    }
                    this.setState({
                        boardLikeCount: boardLikeCount,
                        userBoardLikeFlag: false,
                    })
                })
                .catch(function(error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        }
        //console.log(this.state.isLogin)
    }
    setLikeBoard() {
        if (!this.state.isLogin) {
            alert('로그인이 필요한 서비스 입니다.')
            return false
        } else {
            let data = { service_code: this.state.service_code }
            axios
                .post(
                    process.env.REACT_APP_VFUN_API_DNS +
                        '/v1/Vfun/setCommunityBoardLike',
                    JSON.stringify(data),
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            ssoinfo: this.state.ssoinfo,
                            userid: this.state.userid,
                            jumin: this.state.jumin,
                        },
                    }
                )
                .then(res => {
                    var boardLikeCount = 0
                    if (res.data.result_code === 1) {
                        boardLikeCount = res.data.data
                    }
                    this.setState({
                        boardLikeCount: boardLikeCount,
                        userBoardLikeFlag: true,
                    })
                })
                .catch(function(error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        }
        //console.log(this.state.isLogin)
    }

    componentDidMount() {
        this.getBoardLikeInfo()
        if (this.state.isLogin) {
            this.getUserBoardLikeInfo()
        }
    }

    getUserBoardLikeInfo() {
        let data = { service_code: this.state.service_code }
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS +
                    '/v1/Vfun/getCommunityBoardLike',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                var userBoardLikeFlag = false
                if (res.data.result_code === 1) {
                    userBoardLikeFlag = true
                }
                this.setState({ userBoardLikeFlag: userBoardLikeFlag })
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }

    getBoardLikeInfo() {
        let data = { service_code: this.state.service_code }
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS +
                    '/v1/Vfun/getCommunityBoardLikeAll',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                var boardLikeCount = 0
                if (res.data.result_code === 1) {
                    boardLikeCount = res.data.data
                }
                this.setState({ boardLikeCount: boardLikeCount })
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }
    render() {
        return (
            <>
                <div className="page-header">
                    <div className="row-w clearfix">
                        <img
                            className="thumb-img"
                            src={
                                IMAGE_DNS +
                                '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                this.state.service_code.replace('-', '') +
                                '-small-thumb-min.jpg'
                            }
                            alt={this.state.service_code}
                        />
                        <div className="header-cont">
                            <span className="gamename clearfix">
                                {this.state.gamedata.title}
                                <span className="btns-top f-right">
                                    <Link to="#" className="btns btn-aos" />
                                    <Link to="#" className="btns btn-ios" />
                                    <Link to="#" className="btns btn-gxy" />
                                    <Link to="#" className="btns btn-share" />
                                </span>
                            </span>
                            <span className="genre">
                                <span className="fas fa-desktop" />
                                &nbsp;&nbsp;{this.state.gamedata.genre}
                            </span>
                            <span className="age">
                                +&nbsp;&nbsp;{this.state.gamedata.age}
                            </span>
                            <span className="btns-bottom clearfix">


                                {(this.state.service_code === 'vfun-ko') ?
                                    null :
                                    <>
                                    <Link to={e =>
                                        redirectURL(
                                            this.state.gamedata.home_page_url,
                                            '_blank'
                                        )} className="btns">
                                        <span className="icon gamepad"/>
                                        사전예약
                                        <span className="angle-right"/>
                                    </Link>

                                    <Link
                                        to="#"
                                        className="btns"
                                        onClick={e =>
                                            redirectURL(
                                                this.state.gamedata.home_page_url,
                                                '_blank'
                                            )
                                        }
                                    >
                                    <span className="icon home" />
                                    홈페이지
                                    <span className="angle-right" />
                                </Link>
                                    </>
                                }
                                <Link
                                    to="#"
                                    className={
                                        'btns thumb f-right' +
                                        (this.state.userBoardLikeFlag
                                            ? ' on'
                                            : '')
                                    }
                                    onClick={e =>
                                        this.state.userBoardLikeFlag
                                            ? this.putLikeBoard()
                                            : this.setLikeBoard()
                                    }
                                >
                                    <span className="icon thumb" />
                                    {this.state.boardLikeCount}
                                </Link>
                                {/*  유저 라이크 + */}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PageHeader
