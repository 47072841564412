import React, { Component } from 'react'

class CrtThemeNormal extends Component {
    render() {
        return (
            <div className="wrap-canvas">
                <div className="crt-wanna-fun">
                    Want fun? VFUN!
                </div>
                <div className="crt-spaceman fadeDownUp">
                    <img
                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/crt/space/spaceman-min.png"
                        alt=""
                    />
                </div>
                <div className="crt-funny">
                    <img
                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/crt/space/funny-min.png"
                        alt=""
                    />
                </div>
                <div className="crt-bubble aniBalloon">
                    <img
                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/crt/space/bubble-min.png"
                        alt=""
                    />
                </div>
                <div className="crt-lights">
                    <img
                        className="light1 twinkle1"
                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/crt/space/light1-min.png"
                        alt=""
                    />
                    <img
                        className="light2 twinkle2"
                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/crt/space/light2-min.png"
                        alt=""
                    />
                    <img
                        className="light3 twinkle3"
                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/crt/space/light3-min.png"
                        alt=""
                    />
                    <img
                        className="light4 twinkle4"
                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/crt/space/light4-min.png"
                        alt=""
                    />
                </div>
                <div className="crt-gamepad fadeUpDown">
                    <img
                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/crt/space/gamepad-min.png"
                        alt=""
                    />
                </div>
            </div>
        )
    }
}

export default CrtThemeNormal