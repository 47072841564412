import React from 'react'
import { Link } from 'react-router-dom'


import { IMAGE_DNS } from '../../../utils/Config'
import FadeIn from 'react-fade-in/lib/FadeIn'
import './PageGameList.scoped.scss'
import {ReactComponent as IconGameBlue} from '../../../assets/images/icon-game-blue.svg'

class PageGameList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            isOpenGameMore: false,
        }
    }

/*    fnSeeMore(articleNum) {
        this.setState({
            articlePage: !this.state.articlePage,
            articleUrl: articleNum,
        })
    }*/

    handleGameMore(){
        this.setState ({isOpenGameMore: ! this.state.isOpenGameMore })
    }

    handleProfileEdit(){
        alert('준비 중입니다~!');
    }
    render() {
        return (
            <>

                <nav className="com-game-nav">
                        <h3 className="nav-tit"> <IconGameBlue /> <span>다른 커뮤니티로 이동</span></h3>

                        <ul className="default-list">
                            <li className="lnb-game">
                                <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                    <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/atko-icon-min.png" }  alt={"게임이름"}/>
                                    게임이름
                                    <i className="fas fa-desktop" />
                                </Link>
                            </li>
                            <li className="lnb-game">
                                <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                    <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/lostsaga-icon-min.png" }  alt={"게임이름"}/>
                                    게임이름
                                    <i className="fas fa-desktop" />
                                </Link>
                            </li>
                            <li className="lnb-game">
                                <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                    <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/r2beatko-icon-min.png" }  alt={"게임이름"}/>
                                    게임이름
                                    <i className="fas fa-desktop" />
                                </Link>
                            </li>
                            <li className="lnb-game">
                                <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                    <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/nageko-icon-min.png" }  alt={"게임이름"}/>
                                    게임이름
                                    <i className="fas fa-desktop" />
                                </Link>
                            </li>
                            <li className="lnb-game">
                                <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                    <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/icarusmko-icon-min.png" }  alt={"게임이름"}/>
                                    게임이름 5
                                    <i className="fa fa-mobile" />
                                </Link>
                            </li>
                            <FadeIn
                                visible={this.state.isOpenGameMore}
                                delay={10}
                                transitionDuration={400}
                            >
                                { this.state.isOpenGameMore ===true ?
                                    <>

                                    <li className="lnb-game">
                                        <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                            <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/mulegendko-icon-min.png" }  alt={"게임이름"}/>
                                            게임이름
                                            <i className="fas fa-desktop" />
                                        </Link>
                                    </li>
                                    <li className="lnb-game">
                                        <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                            <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/fishislandko-icon-min.png" }  alt={"게임이름"}/>
                                            게임이름
                                            <i className="fas fa-desktop" />
                                        </Link>
                                    </li>
                                    <li className="lnb-game">
                                        <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                            <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/blacksquadko-icon-min.png" }  alt={"게임이름"}/>
                                            게임이름
                                            <i className="fas fa-desktop" />
                                        </Link>
                                    </li>
                                    <li className="lnb-game">
                                        <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                            <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/ica-icon-min.png" }  alt={"게임이름"}/>
                                            게임이름
                                            <i className="fas fa-desktop" />
                                        </Link>
                                    </li>
                                    <li className="lnb-game">
                                        <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                            <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/gf-icon-min.png" }  alt={"게임이름"}/>
                                            게임이름
                                            <i className="fas fa-desktop" />
                                        </Link>
                                    </li>
                                    <li className="lnb-game">
                                        <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                            <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/goonzu-icon-min.png" }  alt={"게임이름"}/>
                                            게임이름
                                            <i className="fas fa-desktop" />
                                        </Link>
                                    </li>
                                    <li className="lnb-game">
                                        <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                            <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/yongbi-icon-min.png" }  alt={"게임이름"}/>
                                            게임이름
                                            <i className="fas fa-desktop" />
                                        </Link>
                                    </li>
                                    <li className="lnb-game">
                                        <Link to="각 게임 커뮤니티로 이동" className="each-game">
                                            <img className="icon-game" src={ IMAGE_DNS + "/Valofe_file/web/vfun-ko/images/game/enroll/hero-icon-min.png" }  alt={"게임이름"}/>
                                            게임이름
                                            <i className="fas fa-desktop" />
                                        </Link>
                                    </li>
                                    </>
                                    : null }

                            </FadeIn>
                        </ul>

                        <div className="more-games">
                            <Link to="#" className={ this.state.isOpenGameMore ===true ? 'btn-game-more on' : 'btn-game-more'}  onClick={e=>this.handleGameMore()} >
                                <i className={ this.state.isOpenGameMore ===true ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}/>
                                { this.state.isOpenGameMore ===true ? '커뮤니티 접기' : '커뮤티니 더보기'}
                            </Link>
                        </div>
                    </nav>
            </>
        )
    }
}

export default PageGameList
