import React from 'react'
import { Link } from 'react-router-dom'
import { IMAGE_DNS, BILLING_CHARGE_URL } from '../../utils/Config'
import { redirectURL } from '../../utils/CommonFunc'
import classNames from 'classnames/bind'
import fontA from '../../assets/css/fontawesome5_3_1.css'
import gCoinInfo from '../../assets/css/gcoin_info.scss'

const gcInfo = classNames.bind(gCoinInfo)
const fA = classNames.bind(fontA)
class VCoinInfo extends React.Component {
    render() {
        return (
            <section className={gcInfo('gcoin-info')} id="guideLink">
                <div className={gcInfo('row-w')}>
                    <div className="clearfix">
                        <img
                            className={gcInfo('img-gcoin')}
                            src={
                                IMAGE_DNS +
                                '/Valofe_file/web/vfun-ko/images/common/gcoin/phone-min.png'
                            }
                            alt="VCoin으로 원하는 게임 아이템을 쉽고 편하게 구매하세요."
                        />
                        <div className={gcInfo('desc')}>
                            <span className={gcInfo('tit')}>
                                VCoin으로 원하는 게임 아이템을
                                <br />
                                쉽고 편하게 구매하세요.
                            </span>
                            <Link
                                to="#"
                                className={gcInfo('btn', 'gcoin')}
                                onClick={e =>
                                    redirectURL(BILLING_CHARGE_URL, '_self')
                                }
                            >
                                <span
                                    className={fA(
                                        'fa',
                                        'fa-angle-double-right'
                                    )}
                                />
                                &nbsp;&nbsp;&nbsp; VCoin 구매
                            </Link>
                            <br />
                            <strong className={gcInfo('define')}>
                                VCoin이란?
                            </strong>
                            <p className={gcInfo('sentence')}>
                                VFUN에서 서비스하는 게임의 부분 유료 아이템만을
                                이용하기 위해 구매하는 인터넷상의 화폐입니다.
                                <br />
                                VCoin은 고객의 편의에 따라 다양하게 결제할 수
                                있습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default VCoinInfo
