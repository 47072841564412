import React from 'react'
import { Link as ScrollMove } from 'react-scroll'
import { withRouter } from 'react-router-dom'
class SwipeMenu extends React.Component {
    render() {
        const { location } = this.props
        return (
            <nav className="pn-ProductNav_Wrapper" id="swipeMenu">
                <div className="row-w">
                    <nav
                        id="pnProductNav"
                        className="pn-ProductNav"
                        data-overflowing="none"
                    >
                        <div
                            id="pnProductNavContents"
                            className="pn-ProductNav_Contents pn-ProductNav_Contents-no-transition"
                        >
                            {/*<ScrollMove smooth={true} to="board">
                                <span
                                    className="pn-ProductNav_Link"
                                    aria-selected="true"
                                    title="board"
                                >
                                    BOARD
                                </span>
        </ScrollMove>*/}
                            {
                                location.pathname === '/game/detail/raiddungeon' ? (
                                    <ScrollMove smooth={true} to="coupon">
                                        <span
                                            className="pn-ProductNav_Link"
                                            title="coupon"
                                        >
                                            쿠폰 코드
                                        </span>
                                    </ScrollMove>
                                ) : <></>
                            }
                            <ScrollMove smooth={true} to="introduction">
                                <span
                                    className="pn-ProductNav_Link"
                                    title="introduction"
                                >
                                    게임 소개
                                </span>
                            </ScrollMove>
                             {
                                location.pathname === '/game/detail/o2jam' ? (

                                    <ScrollMove smooth={true} to="world-view">
                                        <span
                                            className="pn-ProductNav_Link"
                                            title="Feature"
                                        >
                                            게임 특징
                                        </span>
                                    </ScrollMove>
                                ) : <></>
                            }
                            {
                               ( location.pathname ===  '/game/detail/icarusmko' ) ? (
                                <ScrollMove smooth={true} to="class">
                                    <span
                                        className="pn-ProductNav_Link"
                                        title="class"
                                    >
                                        클래스 소개
                                    </span>
                                </ScrollMove>
                                ) : <></>
                            }

                            <ScrollMove smooth={true} to="system">
                                <span
                                    className="pn-ProductNav_Link"
                                    title="system"
                                >
                                    게임 시스템
                                </span>
                            </ScrollMove>

                            {
                                location.pathname !== '/game/detail/o2jam' ? (
                            <ScrollMove smooth={true} to="gallery">
                                <span
                                    className="pn-ProductNav_Link"
                                    title="gallery"
                                >
                                    갤러리
                                </span>
                            </ScrollMove>
                                ) : <></>
                            }

                            {/*<!--<span className="pn-ProductNav_Link" title="download">Download</span>-->*/}
                            <ScrollMove smooth={true} to="recommend">
                                <span
                                    className="pn-ProductNav_Link"
                                    title="recommend"
                                >
                                    추천 게임
                                </span>
                            </ScrollMove>
                            <span
                                id="pnIndicator"
                                className="pn-ProductNav_Indicator"
                            />
                        </div>
                    </nav>
                    <button
                        id="pnAdvancerLeft"
                        className="pn-Advancer pn-Advancer_Left"
                        type="button"
                    >
                        <svg
                            className="pn-Advancer_Icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 551 1024"
                        >
                            <path d="M445.44 38.183L-2.53 512l447.97 473.817 85.857-81.173-409.6-433.23v81.172l409.6-433.23L445.44 38.18z" />
                        </svg>
                    </button>
                    <button
                        id="pnAdvancerRight"
                        className="pn-Advancer pn-Advancer_Right"
                        type="button"
                    >
                        <svg
                            className="pn-Advancer_Icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 551 1024"
                        >
                            <path d="M105.56 985.817L553.53 512 105.56 38.183l-85.857 81.173 409.6 433.23v-81.172l-409.6 433.23 85.856 81.174z" />
                        </svg>
                    </button>
                </div>
            </nav>
        )
    }
}

export default withRouter(SwipeMenu)
