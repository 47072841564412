import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import YoutubeEmbed from './YoutubeEmbed'

import { IMAGE_DNS } from '../../../utils/Config'
//import { viewSplitLine } from '../../../utils/CommonFunc'

import InfoContext from '../../../contexts/InfoContext'

import Social from './Social'
import Reply from './Reply'

import './Article.scoped.scss'
import emoji from '../data/emoji.json'

class Article extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        this.state = {
            // class_data: props.class_data,
            // openYoutubePopup: false,
            // youtubeURL: '',
            //articlePage: false,
            isOpenArticleEditor: [],
            isMyArticle: false,
            isLogin: context.state.is_login,
            ssoinfo:
                context.state.is_login === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.is_login === false
                    ? 'none'
                    : context.state.user_id,
            jumin:
                context.state.is_login === false ? 'none' : context.state.jumin,
            service_code: props.service_code,
            communuty_list: [],
            section: props.section,
            emoji: emoji,
            read_more: [],
            offset: 0,
            limit: 5,
            page: 1,
            fetching: true,
            hasYoutube: false,
        }
        this.targetId = props.targetId
    }

    componentDidMount() {
        this.getCommunityList(this.state.section)
        window.addEventListener('scroll', this.handleScroll.bind(this), true)
        //boardRefresh
    }
    handleScroll() {
        let scrollHeight = document.getElementById('page-right').scrollHeight
        let scrollTop = document.getElementById('page-right').scrollTop
        let clientHeight = document.getElementById('page-right').clientHeight
        //console.log('scrollTop + clientHeight: ' +parseInt(parseInt(scrollTop) + parseInt(clientHeight)))
        //console.log('scrollHeight: ' + scrollHeight)
        //console.log(self.state.offset)

        if (scrollTop + clientHeight >= scrollHeight) {
            this.setOffset()
            //this.getCommunityList(this.props.section)
            //this.setState({ offset: parseInt(parseInt(this.state.offset) + 1) })
        }

        //console.log('scrollTop: ' + scrollTop)
        //console.log('clientHeight: ' + clientHeight)
    }
    setOffset() {
        if (this.state.fetching !== false) {
            let page = this.state.page + 1
            this.setState({ page: page, offset: this.state.limit * (page - 1) })
            this.getCommunityList(this.props.section)
        }
    }
    reportBoard(boardIdx) {
        alert('report' + boardIdx)
    }

    deleteBoard(boardIdx) {
        if (window.confirm('해당 게시물을 삭제 하시겠습니까?')) {
            let data = { idx: boardIdx }
            axios
                .post(
                    process.env.REACT_APP_VFUN_API_DNS +
                        '/v1/Vfun/delCommunityBoardByIdx',
                    JSON.stringify(data),
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            ssoinfo: this.state.ssoinfo,
                            userid: this.state.userid,
                            jumin: this.state.jumin,
                        },
                    }
                )
                .then(res => {
                    if (parseInt(res.data.result_code) === 1) {
                        this.getCommunityList(this.state.section)
                        this.setState({ isOpenArticleEditor: [] })
                    }
                })
                .catch(function(error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.boardRefresh !== this.props.boardRefresh) {
            console.log(prevState)
            this.setState({ offset: 0, page: 1, fetch: true })
            console.log(this.state)
            this.getCommunityList(this.props.section)
        }

        if (parseInt(prevProps.section) !== parseInt(this.props.section)) {
            //console.log('prev' + prevProps.section)
            //console.log('current' + this.props.section)
            //this.setState({ section: this.props.section })
            this.getCommunityList(this.props.section)
        }
    }

    getCommunityList(section) {
        var communuty_list
        if (this.state.offset === 0) {
            communuty_list = []
        } else {
            communuty_list = this.state.communuty_list
        }
        //console.log(communuty_list)
        let data = {
            service_code: this.state.service_code,
            section: section,
            offset: this.state.offset,
            limit: this.state.limit,
        }
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS +
                    '/v1/Vfun/getCommunityBoard',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                if (parseInt(res.data.result_code) === 1) {
                    var real_content = ''
                    var re = new RegExp('<div>', 'ig')
                    for (var key in res.data.data) {
                        var div_count = 0
                        real_content = res.data.data[key].content
                        if (real_content.match(re) !== null) {
                            div_count = real_content.match(re).length
                        }

                        //real_content = viewSplitLine(real_content).props.children.props.dangerouslySetInnerHTML.__html
                        for (var emoji_key in this.state.emoji.emoticon) {
                            real_content = real_content.replace(
                                this.state.emoji.emoticon[emoji_key].keyword,
                                "<img src='" +
                                    this.state.emoji.emoticon[emoji_key]
                                        .image_url +
                                    "' />"
                            )
                        }
                        var data = {
                            idx: res.data.data[key].idx,
                            user_id: res.data.data[key].user_id,
                            content: real_content,
                            images: res.data.data[key].images,
                            created_at: res.data.data[key].created_at,
                            div_count: div_count,
                            comments: res.data.data[key].comments,
                        }
                        communuty_list.push(data)
                    }
                    this.setState({ communuty_list: communuty_list })
                    //console.log(communuty_list.comments.length)
                } else {
                    this.setState({ fetching: false })
                }
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }
    // 아티클 에디터
    handleArticleEditor(writerUser, info_name) {
        var isOpenArticleEditor = this.state.isOpenArticleEditor
        if (isOpenArticleEditor[info_name] === undefined) {
            isOpenArticleEditor[info_name] = false
        }
        isOpenArticleEditor[info_name] = !isOpenArticleEditor[info_name]
        if (this.state.isLogin === true) {
            // 로그인 + 내글 : 수정, 삭제만
            if (writerUser === this.state.userid) {
                this.setState({
                    isOpenArticleEditor: isOpenArticleEditor,
                    isMyArticle: true,
                })
                // 로그인 + 남의글 : 신고만
            } else {
                this.setState({
                    isOpenArticleEditor: isOpenArticleEditor,
                    isMyArticle: false,
                })
            }
            // 로그인 안함
        } else {
            //로그인 후 이용하실 수 있습니다.
            alert('로그인 후 이용해 주세요~!')
        }
    }

    handleMore(e, article_num) {
        console.log(e)
        document.getElementById(article_num).classList.add('on')
        let read_more = this.state.read_more
        read_more.push(article_num)

        console.log(read_more)
        this.setState({
            //isArticleOpen: !this.state.isArticleOpen,
            read_more: read_more,
        })
    }

    editBoardInfo(e) {
        this.setState({ isOpenArticleEditor: [] })
        this.props.editBoardInfo(e)
    }
    render() {
        return (
            <>
                <article className="wrap-article">
                    {/*
                    <div
                        className="article img _0 clearfix"
                    >
                        <div className="cont no-article clearfix">
                            <img  className="" src={IMAGE_DNS + '/Valofe_file/web/vfun-ko/images/community/page/funny-no-article-min.png'} alt='등록된 게시글이 없습니다.' />
                            <p>'OOOO'(으)로 검색된 결과가 없습니다.</p>
                            <p>검색어를 확인하여 주세요~!</p>
                        </div>
                    </div>
                    */}
                    {this.state.communuty_list.length === 0 ? (
                        <div className="article img _0 clearfix">
                            <div className="cont no-article clearfix">
                                <img
                                    className=""
                                    src={
                                        IMAGE_DNS +
                                        '/Valofe_file/web/vfun-ko/images/community/page/funny-no-article-min.png'
                                    }
                                    alt="등록된 게시글이 없습니다."
                                />
                                <p>등록된 게시글이 없습니다.</p>
                                <p>첫 게시글을 남겨주세요~!</p>
                            </div>
                        </div>
                    ) : (
                        <>
                            {this.state.communuty_list.map(
                                (community, index) => (
                                    <div
                                        id={'a_' + (index + 1)}
                                        className="article img _0 clearfix"
                                        key={index}
                                    >
                                        {/* 이미지가 0개이면 img _0  */}
                                        {/* 게시자 info */}
                                        <div className="info">
                                            <img
                                                className="user-pic"
                                                src={
                                                    IMAGE_DNS +
                                                    '/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png'
                                                }
                                                alt={community.user_id}
                                            />
                                            <div className="info-writing f-right">
                                                <span className="nickname clearfix">
                                                    {community.user_id}
                                                    <Link
                                                        to="#"
                                                        className="btns icon-dots"
                                                        onClick={e =>
                                                            this.handleArticleEditor(
                                                                community.user_id,
                                                                'info_' +
                                                                    (index + 1)
                                                            )
                                                        }
                                                    />
                                                </span>

                                                {this.state.isOpenArticleEditor[
                                                    'info_' + (index + 1)
                                                ] ? (
                                                    <ul
                                                        className="btns editing"
                                                        id={
                                                            'info_' +
                                                            (index + 1)
                                                        }
                                                    >
                                                        {this.state
                                                            .isMyArticle ? (
                                                            <>
                                                                <li className="edit">
                                                                    <Link
                                                                        to="#"
                                                                        onClick={e =>
                                                                            this.editBoardInfo(
                                                                                e
                                                                            )
                                                                        }
                                                                        data-idx={
                                                                            community.idx
                                                                        }
                                                                    >
                                                                        <span className="icon edit" />
                                                                        글 수정
                                                                    </Link>
                                                                </li>
                                                                <li className="delete">
                                                                    <Link
                                                                        to="#"
                                                                        onClick={e =>
                                                                            this.deleteBoard(
                                                                                community.idx
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="icon delete" />
                                                                        글 삭제
                                                                    </Link>
                                                                </li>
                                                            </>
                                                        ) : (
                                                            <li className="report">
                                                                <Link
                                                                    to="#"
                                                                    onClick={e =>
                                                                        this.reportBoard(
                                                                            community.idx
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="icon warning" />
                                                                    신고
                                                                </Link>
                                                            </li>
                                                        )}
                                                    </ul>
                                                ) : null}
                                            </div>
                                            <div className="info-writing f-right">
                                                <img
                                                    className="icon-clock"
                                                    src={
                                                        IMAGE_DNS +
                                                        '/Valofe_file/web/vfun-ko/images/common/icon/icon-clock-min.png'
                                                    }
                                                    alt="게시글 등록 시간"
                                                />
                                                {community.created_at}
                                            </div>
                                        </div>
                                        {/* 컨텐츠 이미지 0 */}
                                        <div className="cont clearfix">
                                            {community.div_count >= 2 ? (
                                                this.state.read_more.includes(
                                                    'a_' + (index + 1)
                                                ) ? (
                                                    <span
                                                        className="txt"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                community.content,
                                                        }}
                                                    />
                                                ) : (
                                                    <HTMLEllipsis
                                                        component="article"
                                                        className="ellipsis-html"
                                                        unsafeHTML={
                                                            community.content
                                                        }
                                                        maxLine={2}
                                                    />
                                                )
                                            ) : (
                                                <span
                                                    className="txt"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            community.content,
                                                    }}
                                                />
                                            )}
                                            <YoutubeEmbed embedId="rokGy0huYEA" />
                                            {community.div_count >= 2 ? (
                                                <Link
                                                    to="#"
                                                    className="see-more"
                                                    /*   ref={this.aNumRef}*/
                                                    onClick={e =>
                                                        this.handleMore(
                                                            e,
                                                            'a_' + (index + 1)
                                                        )
                                                    }
                                                >
                                                    더보기
                                                </Link>
                                            ) : null}
                                            <p
                                                className={
                                                    'img _' +
                                                    (community.images.length >=
                                                    3
                                                        ? '3'
                                                        : community.images
                                                              .length)
                                                }
                                                data-idx={community.idx}
                                                onClick={e =>
                                                    this.props.handlePopPageImg(
                                                        e
                                                    )
                                                }
                                            >
                                                {/* 이미지가 3개 이상이면 _3  */}
                                                {community.images.map(
                                                    (image, index) => (
                                                        <img
                                                            src={image}
                                                            data-idx={
                                                                community.idx
                                                            }
                                                            alt={
                                                                '유저 업로드 이미지' +
                                                                (index + 1)
                                                            }
                                                        />
                                                    )
                                                )}
                                                {community.images.length > 2 ? (
                                                    <span
                                                        className="img-over"
                                                        data-idx={community.idx}
                                                    >
                                                        <span className="num">
                                                            {'+' +
                                                                (community
                                                                    .images
                                                                    .length -
                                                                    2)}
                                                        </span>
                                                    </span>
                                                ) : null}
                                                {/* 이미지가 0개이면 img _0  */}
                                            </p>
                                        </div>
                                        {/* 소셜 요약 */}
                                        <Social
                                            comment_count={
                                                community.comments.length
                                            }
                                            handleReplyWrite={e =>
                                                this.props.handleReplyWrite(e)
                                            }
                                            board_idx={community.idx}
                                        />
                                        {/* 댓글 */}
                                        <Reply
                                            comments={community.comments}
                                            handleReplyWrite={e =>
                                                this.props.handleReplyWrite(e)
                                            }
                                            board_idx={community.idx}
                                            handleBoardRefresh={e =>
                                                this.props.handleBoardRefresh()
                                            }
                                            handleReplyEdit={e =>
                                                this.props.handleReplyEdit(e)
                                            }
                                        />
                                    </div>
                                )
                            )}
                        </>
                    )}
                </article>
            </>
        )
    }
}

export default Article
