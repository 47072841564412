import React from 'react'
import { Link } from 'react-router-dom'
import './PageProfile.scoped.scss'
import { IMAGE_DNS } from '../../../utils/Config'
import InfoContext from '../../../contexts/InfoContext'

class PageProfile extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        this.state = {
            isLogin: context.state.is_login,
            userid:
                context.state.is_login === false
                    ? 'none'
                    : context.state.user_id,
        }
    }

    handleProfileEdit() {
        alert('준비 중입니다~!')
    }
    render() {
        return (
            <>
                <div className="profile">
                    <img
                        className="user-pic"
                        src={
                            IMAGE_DNS +
                            '/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png'
                        }
                        alt="profile-default"
                    />
                    {this.state.isLogin !== false ? (
                        <span className="nickname">{this.state.userid}</span>
                    ) : (
                        <span className="nickname">로그인을 해주세요.</span>
                    )}
                    <Link
                        to="#"
                        className="btns edit"
                        onClick={e => this.handleProfileEdit()}
                    />
                </div>
            </>
        )
    }
}

export default PageProfile
