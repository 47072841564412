import React from 'react'

import MobileHeader from '../components/common/MobileHeader'
import CombineMenu from '../components/combine_menu/CombineMenu'
import MainBanner from '../components/main/MainBanner'
/*import ComingSoon3 from '../components/main/ComingSoon3'*/
/*import ComingSoon2 from '../components/main/ComingSoon2'*/
import ComingSoon from '../components/main/ComingSoon'
import MainAllGames from '../components/main/MainAllGames'
import VCoinInfo from '../components/main/VCoinInfo'
import Footer from '../components/combine_menu/Footer'

import { HTML_TITLE_PREFIX } from '../utils/Config'

import '../assets/css/Swiper.css'
import '../assets/css/main/Main.scoped.scss'
import BottomTheme from '../components/common/theme/BottomTheme'


class Main extends React.Component {
    componentDidMount() {
        document.title = HTML_TITLE_PREFIX + ' - Home'
    }
    render() {
        return (
            <div id="main">
                <MobileHeader current_menu={'home'} />
                <CombineMenu />
                <div id="content" className="clearfix main christmas">

                    <MainBanner />
                    {/*<ComingSoon3 />*/}
                     {/*<ComingSoon2 />*/}
                    <ComingSoon />
                    <MainAllGames />
                    <BottomTheme />
                    <VCoinInfo />
                    {/*  <GuideLink /> */}
                    {/*<Floating />*/}
                </div>
                <Footer />
            </div>
        )
    }
}

export default Main
