import React, { Component} from 'react'

import Eye from './Eye'
/* 훅을  클래스에서 콜 할 때는 최상위에서 콜해야 한다. */

import MobileHeader from '../common/MobileHeader'
import CombineMenu from '../combine_menu/CombineMenu'
import Footer from '../combine_menu/Footer'

import './NotfoundContents.scoped.scss'

class NotfoundContents  extends Component {
    render() {
        return (
            <>
                <div id="wrapper" class="errors">
                    <MobileHeader/>
                    <CombineMenu/>
                    <div id="content" className="clearfix no-height">
                        <section id="inspection" className="clearfix mb0 pb0">
                            <article>
                                <div id="cont">
                                    <div className="wrap">
                                        {/* <!-- funny --> */}
                                        <div className="box clearfix">
                                            <div className="funny-wrap box__ghost">
                                                <div className="deco">
                                                    <img src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/errors/inspection/neon-circle-min.png" alt="neon o image" className="neon1" />
                                                    <img src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/errors/inspection/neon-star-min.png" alt="neon star image" className="neon2" />
                                                    <img src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/errors/inspection/neon-star-min.png" alt="neon star image" className="neon3" />
                                                    <img src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/errors/inspection/neon-plus-min.png" alt="neon plus image" className="neon4" />
                                                </div>

                                                <div className="funny">
                                                    <img src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/errors/inspection/funny-min.gif" alt="funny" />
                                                    <span className="eye"></span>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <!-- neon --> */}
                                        <div className="neon">
                                            <b>
                                                <i>
                                                    <span id="t0">P</span>
                                                </i>
                                                <span id="t1">A</span>
                                                <span id="t2">G</span>
                                                <span id="t3">E</span>

                                                <br className="pc-hidden" />

                                                <span id="t4">N</span>
                                                <span id="t5">O</span>
                                                <span id="t6">T</span>

                                                <br className="pc-hidden"/>

                                                <i><span id="t7">F</span></i>
                                                <span id="t8">O</span>
                                                <i><span id="t9">U</span></i>
                                                <span id="t10">N</span>
                                                <span id="t11">D</span>
                                            </b>
                                        </div>
                                        <p>서비스 이용에 불편을 드려 대단히 죄송합니다.<br />
                                            찾으시려는 페이지가 <span className="noti-color">삭제 또는 변경</span>되었거나 잠시 사용하실 수 없는 상태입니다.</p>
                                        <p className="ask">동일한 문제가 계속 발생할 경우 <a href="/customer/inquiry">고객센터</a>로 문의해 주세요.<br />빠른 시간 내에 답변 드리겠습니다.</p>
                                        {/* <!-- ./ neon --> */}
                                        <div className="btn-wrap">
                                            <a href="/">홈으로</a>
                                        </div>
                                    </div>
                                   {/*  <!-- ./ cont -->*/}
                                </div>

                            </article>
                        </section>

    {/*document.addEventListener('DOMContentLoaded',*/}
                            {/*<script src="./notfound_resources.js" type="text/javascript"></script>*/}
                            {/*<script src="https://file.valofe.com/Valofe_file/web/vfun-ko/js/sub/errors/notfound_resources.js" type="text/javascript" />*/}
                    </div>
                    <Footer/>
                </div>
            </>
        )
    }
}

export default NotfoundContents