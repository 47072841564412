import React from 'react'
import axios from 'axios'
import SwiperCore, { EffectFade, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import '../../assets/css/swiper/pagination.scss'
import 'swiper/components/effect-fade/effect-fade.min.css'

import { IMAGE_DNS } from '../../utils/Config'
import './PopPageImg.scoped.scss'
import InfoContext from '../../contexts/InfoContext'

class PopPageImg extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        SwiperCore.use([EffectFade, Pagination, Autoplay])
        this.state = {
            isLoading: true,
            isOpenPageImg: props.isOpenPageImg,
            ssoinfo: context.state.ssoinfo,
            userid: context.state.user_id,
            jumin: context.state.jumin,
            popupImageBoardIdx: props.popupImageBoardIdx,
            popupImageList: [],
        }
    }

    getCommunityImageList(idx) {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isOpenPageImg !== this.props.isOpenPageImg) {
            if (this.state.isOpenPageImg === false) {
                let data = { idx: this.props.popupImageBoardIdx }
                axios
                    .post(
                        process.env.REACT_APP_VFUN_API_DNS +
                            '/v1/Vfun/getCommunityBoardImagesByIdx',
                        JSON.stringify(data),
                        {
                            headers: {
                                'Content-Type':
                                    'application/json;charset=UTF-8',
                                ssoinfo: this.state.ssoinfo,
                                userid: this.state.userid,
                                jumin: this.state.jumin,
                            },
                        }
                    )
                    .then(res => {
                        //this.setState({  })
                        console.log(res)
                        this.setState({
                            isOpenPageImg: this.props.isOpenPageImg,
                            isLoading: false,
                            popupImageList: res.data.data,
                        })

                        /*
                        this.setState({
                            isOpenPageImg: this.props.isOpenPageImg,
                            isLoading: false,
                            popupImageList: res.data.data,
                            popupImageBoardIdx: this.props.popupImageBoardIdx,
                        })*/
                        //console.log(res.data)
                    })
                    .catch(function(error) {
                        if (error.response.status >= 500) {
                            alert(
                                '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                            )
                        }
                    })
            } else {
                this.setState({
                    isOpenPageImg: this.props.isOpenPageImg,
                    isLoading: true,
                    popupImageBoardIdx: this.props.popupImageBoardIdx,
                })
            }
        }
    }

    render() {
        return (
            <>
                {/*<!-- 글쓰기 팝업 -->*/}
                {this.state.isLoading === false ? (
                    <FadeIn
                        visible={this.state.isOpenPageImg}
                        delay={10}
                        transitionDuration={400}
                    >
                        <div className="layer-wrap img-type">
                            <div
                                className="shadow"
                                onClick={e => this.props.handlePopPageImg(e)}
                            />
                            <div className="pop-layer">
                                <h1 className="clearfix">
                                    이미지 보기
                                    <Link
                                        className="btn-layer-close"
                                        to="#"
                                        onClick={e =>
                                            this.props.handlePopPageImg(e)
                                        }
                                    >
                                        <img
                                            src={
                                                IMAGE_DNS +
                                                '/Valofe_file/web/vfun-ko/images/common/icon/icon-page-pop-close-btn.png'
                                            }
                                            alt="글쓰기 팝업 창 닫기"
                                        />
                                    </Link>
                                </h1>
                                <div className="pop-container animationOn">
                                    <h2 className="skip">VFUN 메세지</h2>
                                    <div className="frame">
                                        <div className="alert">
                                            <div className="clearfix">
                                                <div className="info">
                                                    <img
                                                        className="user-pic"
                                                        src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/profile/bom_cropped.png"
                                                        alt="프로필 - 봄이"
                                                    />
                                                    <div className="info-writing f-right">
                                                        <span className="nickname clearfix">
                                                            봄이
                                                        </span>
                                                    </div>
                                                    <div className="info-writing f-right">
                                                        <img
                                                            className="icon-clock"
                                                            src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/icon/icon-clock-min.png"
                                                            alt="게시글 등록 시간"
                                                        />
                                                        2021년 07월 07일 6시간전
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sliderContainer">
                                                <Swiper
                                                    slidesPerView={1}
                                                    effect={'fade'}
                                                    pagination={{
                                                        type: 'fraction',
                                                    }}
                                                    navigation={true}
                                                >
                                                    {this.state.popupImageList.images.map(
                                                        (popupImage, index) => (
                                                            <SwiperSlide>
                                                                <img
                                                                    className="img"
                                                                    src={
                                                                        popupImage
                                                                    }
                                                                    alt="메롱메롱"
                                                                />
                                                            </SwiperSlide>
                                                        )
                                                    )}
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                ) : null}
            </>
        )
    }
}
export default PopPageImg
