import React, { Component } from 'react'
//import * as Snowing  from '../../../assets/js/theme/crt/christmas/Snowing'


class BottomXmas extends Component {

    componentDidMount() {
  //      Snowing.contSnow();
    }

    render() {


        return (
            <>
            {/*<canvas id="sky" />
            <div className="xmas-deco">
                <div className="top-sflake snow-flake1">
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-line.png" alt="" />
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-snow.png" alt="" />
                </div>
                <div className="top-sflake snow-flake2">
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-line.png" alt="" />
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-snow.png" alt="" />
                </div>
                <div className="top-sflake snow-flake3">
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-line.png" alt="" />
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-snow.png" alt="" />
                </div>
                <div className="top-sflake snow-flake4">
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-line.png" alt="" />
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-snow.png" alt="" />
                </div>
                <div className="top-sflake snow-flake5">
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-line.png" alt="" />
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-snow.png" alt="" />
                </div>
                <div className="top-sflake snow-flake6">
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-line.png" alt="" />
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-snow.png" alt="" />
                </div>
                <div className="top-sflake snow-flake7">
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-line.png" alt="" />
                    <img src="//file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/snow-snow.png" alt="" />
                </div>
                <div className="tree-star-wrap">
                    <img src="https://file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/tree-area.png" alt="" className="tree-area" />
                    <img src="https://file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/tree-star.png" alt="" className="tree-star" />
                    <img src="https://file.valofe.com/Valofe_file/web/vfun/images/crt/christmas/tree-star-on.png" alt="" className="tree-star aniOpa" />
                </div>
            </div>*/}

        </>

        )

    }


}

export default BottomXmas