import React from 'react'
//import BottomBlackfriday from './BottomBlackfriday'
import BottomXmas from './BottomXmas'



class BottomTheme extends React.Component {

    constructor(props) {
        super(props)

        this.state= {

        }
    }

    render() {
        return (
            <BottomXmas />
        )
    }
}

export default BottomTheme