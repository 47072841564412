import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Link as ScrollMove } from 'react-scroll'

import Floating from '../../components/common/Floating'

import FadeIn from 'react-fade-in/lib/FadeIn'

import {
    BILLING_CHARGE_URL,
    SERVICE_CODE,
    CHANNELING_TYPE,
    TERMS_URL,
    PRIVACY_URL,
    MY_INFO_URL,
    VALOFE_COMPANY_URL,
    SIGN_IN_URL,
} from '../../utils/Config'
import { redirectURL, checkLogin } from '../../utils/CommonFunc'
import InfoContext from '../../contexts/InfoContext'

class Footer extends React.Component {
    static contextType = InfoContext
    constructor(props, context) {
        super(props)
        this.state = {
            isLogin: context.state.is_login,
            is_combine_menu_open: context.state.is_combine_menu_open,
            is_wallet_open: false,
            ssoinfo:
                context.state.ssoinfo === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.user_id === false
                    ? 'none'
                    : context.state.user_id,
            jumin: context.state.jumin === false ? 'none' : context.state.jumin,
            vcoin: 0,
            isLoading: true,
            user_profile_key: context.state.user_profile_key || '',
        }
    }
    handlefWalletCont() {
        if (this.state.is_wallet_open !== true) {
            if (!checkLogin(this.state.isLogin)) {
                return false
            }
            let data = {
                service_code: SERVICE_CODE,
                channeling_type: CHANNELING_TYPE,
            }
            axios
                .post(
                    process.env.REACT_APP_VFUN_API_DNS +
                        '/v1/Vfun/getCoinBalance',
                    JSON.stringify(data),
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            ssoinfo: this.state.ssoinfo,
                            userid: this.state.userid,
                            jumin: this.state.jumin,
                        },
                    }
                )
                .then((res) => {
                    //console.log(res.data.data.total_balance)
                    this.setState({
                        is_wallet_open: !this.state.is_wallet_open,
                        vcoin: res.data.data.total_balance,
                        isLoading: false,
                    })
                })
                .catch(function (error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        } else {
            this.setState({
                is_wallet_open: !this.state.is_wallet_open,
            })
        }
    }
    handleFooterMenu() {
        this.context.actions.setIsCombineMenuOpen(
            !this.state.is_combine_menu_open
        )
    }
    render() {
        return (
            <>
                <Floating />
                <footer id="valofe-footer" className="clearfix">
                    {' '}
                    {/*this.state.service_code*/}
                    <div className="row-w clearfix">
                        <div className="line clearfix" />
                    </div>
                    <div className="row-w clearfix">
                        <div className="copyright pull-left">
                            <div className="footer-logo" id="">
                                {/*id="<?=$service_code?>"*/}
                                <span className="logo" />
                            </div>
                            <p>
                                <span
                                    className="entity_c"
                                    style={{ fontFamily: 'Arial,sans-serif' }}
                                >
                                    &copy;
                                </span>
                                &nbsp;VALOFE CO., LTD. All rights reserved.
                            </p>
                        </div>
                        <nav className="nav pull-right">
                            <ul>
                                <li>
                                    <Link
                                        to="#"
                                        onClick={(e) =>
                                            redirectURL(
                                                VALOFE_COMPANY_URL,
                                                '_blank'
                                            )
                                        }
                                    >
                                        About VALOFE
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="#"
                                        onClick={(e) =>
                                            redirectURL(PRIVACY_URL, '_self')
                                        }
                                    >
                                        개인정보처리방침
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="#"
                                        onClick={(e) =>
                                            redirectURL(TERMS_URL, '_self')
                                        }
                                    >
                                        이용약관
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </footer>
                <footer id="vfun-fixed-footer">
                    <div className="row-w clearfix">
                        <ul className="menu">
                            <li>
                                <Link to="/">
                                    <span className="fa fa-home" />
                                    <br />
                                    홈으로
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    onClick={(e) => this.handleFooterMenu()}
                                >
                                    <span className="fa fa-th-large" />
                                    <br />
                                    메뉴
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    id="fWallet"
                                    onClick={(e) => this.handlefWalletCont()}
                                >
                                    <span className="fwallet-click" />
                                    <br />V Wallet
                                </Link>
                            </li>
                            {this.state.isLogin === true ? (
                                <li className="my-space-btn">
                                    <Link
                                        to="#"
                                        onClick={(e) =>
                                            redirectURL(
                                                `${process.env.REACT_APP_LOUNGE_DNS}/space/${this.state.user_profile_key}`,
                                                '_self'
                                            )
                                        }
                                    >
                                        <span className="fa fa-user-astronaut" />
                                        <br />
                                        My space
                                    </Link>
                                    <span className="fa fa-star fa-bounce" />
                                </li>
                            ) : (
                                <li>
                                    <Link
                                        to="#"
                                        onClick={(e) =>
                                            redirectURL(SIGN_IN_URL, '_self')
                                        }
                                    >
                                        <span className="fa fa-sign-in-alt" />
                                        <br />
                                        로그인
                                    </Link>
                                </li>
                            )}
                        </ul>
                        <ul className="go-to">
                            <li>
                                <ScrollMove
                                    smooth={true}
                                    to="content"
                                    title="위로"
                                >
                                    <span className="fa fa fa-angle-double-up" />
                                </ScrollMove>
                            </li>
                            <li>
                                <ScrollMove
                                    smooth={true}
                                    to="valofe-footer"
                                    title="아래로"
                                >
                                    <span className="fa fa-angle-double-down" />
                                </ScrollMove>
                            </li>
                        </ul>
                    </div>
                    <FadeIn
                        visible={this.state.is_wallet_open}
                        delay={1}
                        transitionDuration={10}
                    >
                        <ul
                            id="fVwallet"
                            style={{
                                display:
                                    this.state.is_wallet_open === true
                                        ? 'block'
                                        : 'none',
                            }}
                        >
                            <li className="coin clearfix">
                                <div className="clearfix">
                                    <span>V</span>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>V Coin</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {this.state.isLoading ===
                                                    false
                                                        ? this.state.vcoin.toLocaleString()
                                                        : 0}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Link
                                    to="#"
                                    className="btn-charge"
                                    target="_self"
                                    title="충전"
                                    onClick={(e) =>
                                        redirectURL(BILLING_CHARGE_URL, '_self')
                                    }
                                >
                                    충전
                                </Link>
                                <Link
                                    to="#"
                                    target="_self"
                                    title="포인트 교환"
                                    className="btn-point-exchange"
                                    onClick={(e) =>
                                        redirectURL(
                                            'https://bill.valofe.com/mycash/charge_info.asp#Center2_lbCharge',
                                            '_self'
                                        )
                                    }
                                >
                                    포인트 교환
                                </Link>
                            </li>
                        </ul>
                    </FadeIn>
                </footer>
            </>
        )
    }
}

export default Footer
