import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn'

import { redirectURL } from '../../utils/CommonFunc'
import { IMAGE_DNS } from '../../utils/Config'

import InfoContext from '../../contexts/InfoContext'
import Spinner from '../../components/common/Spinner'
import '../../assets/css/game/AllgamesContents.scoped.scss'
//import TopTheme from '../common/theme/TopTheme'

class AllGamesContents extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)

        this.state = {
            isLoading: true,
            ssoinfo:
                context.state.ssoinfo === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.user_id === false
                    ? 'none'
                    : context.state.user_id,
            jumin: context.state.jumin === false ? 'none' : context.state.jumin,
            AllGameList: '',
            PCGameList: '',
            AndroidGameList: '',
            IosGameList: '',
            showType: 'all',
            GameList: '',
            showMobileDownLoadPopup: '',
            MobileStoreDownLoadURL: [],
        }
    }

    closeDownloadPopup(service_code) {
        var showMobileDownLoadPopup = this.state.showMobileDownLoadPopup
        showMobileDownLoadPopup[service_code] =
            !showMobileDownLoadPopup[service_code]
        this.setState({
            showMobileDownLoadPopup: showMobileDownLoadPopup,
        })
    }
    redirectDownURL(platform, service_code) {
        let data = { service_code: service_code }
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS +
                    '/v1/Vfun/getDownloadByService',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then((res) => {
                if (res.data.result_code === -95) {
                    alert('다운로드 URL이 없습니다.')
                } else {
                    var MobileStoreDownLoadURL = []
                    if (platform !== 'PC') {
                        if (
                            this.state.showMobileDownLoadPopup[service_code] !==
                            undefined
                        ) {
                            var showMobileDownLoadPopup =
                                this.state.showMobileDownLoadPopup
                            showMobileDownLoadPopup[service_code] =
                                !showMobileDownLoadPopup[service_code]
                            this.setState({
                                showMobileDownLoadPopup:
                                    showMobileDownLoadPopup,
                            })
                        }
                        /*
                        1: PC
                        2: Google Play
                        3: App Store
                        4: One Store
                        5: Galaxy Store
                        */
                        MobileStoreDownLoadURL = []
                        MobileStoreDownLoadURL['google_play'] = ''
                        MobileStoreDownLoadURL['app_store'] = ''
                        MobileStoreDownLoadURL['one_store'] = ''
                        MobileStoreDownLoadURL['galaxy_store'] = ''
                        if (res.data.data[2] !== undefined) {
                            MobileStoreDownLoadURL['google_play'] =
                                res.data.data[2]
                        }
                        if (res.data.data[3] !== undefined) {
                            MobileStoreDownLoadURL['app_store'] =
                                res.data.data[3]
                        }
                        if (res.data.data[4] !== undefined) {
                            MobileStoreDownLoadURL['one_store'] =
                                res.data.data[4]
                        }
                        if (res.data.data[5] !== undefined) {
                            MobileStoreDownLoadURL['galaxy_store'] =
                                res.data.data[5]
                        }
                        var prevMobileStoreDownLoadURL =
                            this.state.MobileStoreDownLoadURL
                        prevMobileStoreDownLoadURL[service_code] =
                            MobileStoreDownLoadURL
                        this.setState({
                            MobileStoreDownLoadURL: prevMobileStoreDownLoadURL,
                        })
                        //console.log(this.state.MobileStoreDownLoadURL)
                    } else {
                        redirectURL(res.data.data[1], '_blank')
                    }
                }
            })
            .catch(function (error) {
                //console.log(error)
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }
    changeShowtype(type) {
        var GameList = ''
        if (type === 'all') {
            GameList = this.state.AllGameList
        } else if (type === 'pc') {
            GameList = this.state.PCGameList
        } else if (type === 'aos') {
            GameList = this.state.AndroidGameList
        } else if (type === 'ios') {
            GameList = this.state.IosGameList
        }
        this.setState({ showType: type, GameList: GameList })
    }
    componentDidMount() {
        this.getDownloadList()
    }

    getDownloadList() {
        let data = {}
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS + '/v1/Vfun/getAllGameInfo',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then((res) => {
                if (parseInt(res.data.result_code) === 1) {
                    var PCGameList = []
                    var AndroidGameList = []
                    var IosGameList = []
                    var pc_key = 0
                    var android_key = 0
                    var ios_key = 0
                    var showMobileDownLoadPopup = []
                    res.data.data.forEach((game) => {
                        if (game.platform === 'PC') {
                            //showMobileDownLoadPopup[game.service_code] = false
                            if (game.platform_detail !== undefined) {
                                PCGameList[pc_key] = game
                                ++pc_key
                            }
                        }

                        if (game.platform === 'MOBILE') {
                            if (game.platform_detail !== undefined) {
                                showMobileDownLoadPopup[
                                    game.service_code
                                ] = false
                                if (
                                    game.platform_detail.includes('2') ||
                                    game.platform_detail.includes('4') ||
                                    game.platform_detail.includes('5')
                                ) {
                                    AndroidGameList[android_key] = game
                                    ++android_key
                                }

                                if (game.platform_detail.includes('3')) {
                                    IosGameList[ios_key] = game
                                    ++ios_key
                                }
                            }
                        }
                    })
                }
                //console.log(showMobileDownLoadPopup)
                this.setState({
                    AllGameList: res.data.data,
                    GameList: res.data.data,
                    PCGameList: PCGameList,
                    AndroidGameList: AndroidGameList,
                    IosGameList: IosGameList,
                    showMobileDownLoadPopup: showMobileDownLoadPopup,
                    isLoading: false,
                })
            })
            .catch(function (error) {
                console.log(error)
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }

    render() {
        return (
            <>
                {/*<TopTheme />*/}
                <section className="sorting-nav">
                    <div className="row-w clearfix">
                        <h1>전체 게임 </h1>
                        <ul>
                            <li
                                className={
                                    this.state.showType === 'all' ? 'on' : ''
                                }
                                id="all_list"
                            >
                                <Link
                                    to="#"
                                    title="All Games"
                                    onClick={(e) => this.changeShowtype('all')}
                                >
                                    <span className="fas fa-list-ul" />
                                    &nbsp;All
                                </Link>
                            </li>
                            <li
                                className={
                                    this.state.showType === 'pc' ? 'on' : ''
                                }
                                id="pc_list"
                            >
                                <Link
                                    to="#"
                                    title="PC Game"
                                    onClick={(e) => this.changeShowtype('pc')}
                                >
                                    <span className="fas fa-desktop" />
                                </Link>
                            </li>
                            <li
                                className={
                                    this.state.showType === 'aos' ? 'on' : ''
                                }
                                id="aos_list"
                            >
                                <Link
                                    to="#"
                                    title="Android Game"
                                    onClick={(e) => this.changeShowtype('aos')}
                                >
                                    <span className="fab fa-android" />
                                </Link>
                            </li>
                            <li
                                className={
                                    this.state.showType === 'ios' ? 'on' : ''
                                }
                                id="ios_list"
                            >
                                <Link
                                    to="#"
                                    title="iOS&nbsp;Game"
                                    onClick={(e) => this.changeShowtype('ios')}
                                >
                                    <span className="fab fa-apple" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="game-line">
                    <div className="row-w">
                        <h1 className="text-left" id="list_title">
                            {this.state.showType === 'all'
                                ? '전체 게임'
                                : this.state.showType === 'pc'
                                ? 'PC 게임'
                                : this.state.showType === 'aos'
                                ? 'Android 게임'
                                : 'iOS 게임'}
                        </h1>
                        {this.state.isLoading === true ? <Spinner /> : null}
                        <article className="mt20">
                            <ul id="listView">
                                {this.state.isLoading === false
                                    ? this.state.GameList.map((game, index) => (
                                          <li className="game-line-cont clearfix">
                                              <div
                                                  className="game-image pull-left "
                                                  onClick="window.open('/game/more?idx=8', '_self');"
                                              >
                                                  <img
                                                      src={
                                                          IMAGE_DNS +
                                                          '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                          game.service_code.replace(
                                                              '-',
                                                              ''
                                                          ) +
                                                          '-small-thumb-min.jpg'
                                                      }
                                                      alt={game.title}
                                                      onClick={(e) =>
                                                          redirectURL(
                                                              game.platform ===
                                                                  'PC'
                                                                  ? game.url
                                                                  : game.service_code ===
                                                                    'fwtr'
                                                                  ? game.url
                                                                  : '/game/detail/' +
                                                                    game.service_code.replace(
                                                                        '-',
                                                                        ''
                                                                    ),
                                                              game.service_code ===
                                                                  'fwtr'
                                                                  ? '_blank'
                                                                  : '_self'
                                                          )
                                                      }
                                                  />
                                              </div>
                                              <div className="game-txt pull-right aniInLeft">
                                                  <div className="game-desc clearfix">
                                                      <div className="pull-left">
                                                          <strong className="game-tit">
                                                              <Link
                                                                  to="#"
                                                                  onClick={(
                                                                      e
                                                                  ) => {
                                                                      redirectURL(
                                                                          game.platform ===
                                                                              'PC'
                                                                              ? game.url
                                                                              : '/game/detail/' +
                                                                                    game.service_code.replace(
                                                                                        '-',
                                                                                        ''
                                                                                    ),
                                                                          '_self'
                                                                      )
                                                                  }}
                                                              >
                                                                  {game.title}
                                                              </Link>
                                                          </strong>
                                                          <div className="game-info">
                                                              <span className="genre">
                                                                  {game.genre}
                                                              </span>
                                                              <div className="wrap-device-all">
                                                                  {game.platform ===
                                                                  'PC' ? (
                                                                      <div className="wrap-device desktop">
                                                                          <span className="fas fa-desktop" />
                                                                          <span className="device-txt">
                                                                              PC
                                                                          </span>
                                                                      </div>
                                                                  ) : null}
                                                                  {game.platform ===
                                                                      'MOBILE' &&
                                                                  game.platform_detail !==
                                                                      undefined &&
                                                                  (game.platform_detail.includes(
                                                                      '2'
                                                                  ) ||
                                                                      game.platform_detail.includes(
                                                                          '4'
                                                                      ) ||
                                                                      game.platform_detail.includes(
                                                                          '5'
                                                                      )) ? (
                                                                      <div className="wrap-device">
                                                                          <span className="fab fa-android" />
                                                                          <span className="device-txt">
                                                                              Android
                                                                          </span>
                                                                      </div>
                                                                  ) : null}
                                                                  {game.platform ===
                                                                      'MOBILE' &&
                                                                  game.platform_detail !==
                                                                      undefined &&
                                                                  game.platform_detail.includes(
                                                                      '3'
                                                                  ) ? (
                                                                      <div className="wrap-device">
                                                                          <span className="fab fa-apple" />
                                                                          <span className="device-txt">
                                                                              iOS
                                                                          </span>
                                                                      </div>
                                                                  ) : null}
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="pull-right">
                                                          {game.platform ===
                                                          'MOBILE' ? (
                                                              <FadeIn
                                                                  visible={
                                                                      this.state
                                                                          .showMobileDownLoadPopup[
                                                                          game
                                                                              .service_code
                                                                      ]
                                                                  }
                                                                  delay={1}
                                                                  transitionDuration={
                                                                      10
                                                                  }
                                                              >
                                                                  <div
                                                                      className="download-multi"
                                                                      id="down_8"
                                                                      style={{
                                                                          display:
                                                                              this
                                                                                  .state
                                                                                  .showMobileDownLoadPopup[
                                                                                  game
                                                                                      .service_code
                                                                              ] ===
                                                                              true
                                                                                  ? 'block'
                                                                                  : 'none',
                                                                      }}
                                                                  >
                                                                      <div className="wrap-download">
                                                                          <ul className="mb-down">
                                                                              {this
                                                                                  .state
                                                                                  .showMobileDownLoadPopup[
                                                                                  game
                                                                                      .service_code
                                                                              ] ===
                                                                                  true &&
                                                                              this
                                                                                  .state
                                                                                  .MobileStoreDownLoadURL[
                                                                                  game
                                                                                      .service_code
                                                                              ] !==
                                                                                  undefined ? (
                                                                                  this
                                                                                      .state
                                                                                      .MobileStoreDownLoadURL[
                                                                                      game
                                                                                          .service_code
                                                                                  ][
                                                                                      'google_play'
                                                                                  ] !==
                                                                                  '' ? (
                                                                                      <li>
                                                                                          <Link
                                                                                              to="#"
                                                                                              className="mb-icon"
                                                                                              onClick={(
                                                                                                  e
                                                                                              ) =>
                                                                                                  redirectURL(
                                                                                                      this
                                                                                                          .state
                                                                                                          .MobileStoreDownLoadURL[
                                                                                                          game
                                                                                                              .service_code
                                                                                                      ][
                                                                                                          'google_play'
                                                                                                      ],
                                                                                                      '_blank'
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              <img
                                                                                                  src={
                                                                                                      IMAGE_DNS +
                                                                                                      '/Valofe_file/web/vfun-ko/images/common/icon-aos.png'
                                                                                                  }
                                                                                                  alt="Android"
                                                                                              />
                                                                                          </Link>
                                                                                      </li>
                                                                                  ) : null
                                                                              ) : null}
                                                                              {this
                                                                                  .state
                                                                                  .showMobileDownLoadPopup[
                                                                                  game
                                                                                      .service_code
                                                                              ] ===
                                                                                  true &&
                                                                              this
                                                                                  .state
                                                                                  .MobileStoreDownLoadURL[
                                                                                  game
                                                                                      .service_code
                                                                              ] !==
                                                                                  undefined ? (
                                                                                  this
                                                                                      .state
                                                                                      .MobileStoreDownLoadURL[
                                                                                      game
                                                                                          .service_code
                                                                                  ][
                                                                                      'app_store'
                                                                                  ] !==
                                                                                  '' ? (
                                                                                      <li>
                                                                                          <Link
                                                                                              to="#"
                                                                                              className="mb-icon"
                                                                                              onClick={(
                                                                                                  e
                                                                                              ) =>
                                                                                                  redirectURL(
                                                                                                      this
                                                                                                          .state
                                                                                                          .MobileStoreDownLoadURL[
                                                                                                          game
                                                                                                              .service_code
                                                                                                      ][
                                                                                                          'app_store'
                                                                                                      ],
                                                                                                      '_blank'
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              <img
                                                                                                  src={
                                                                                                      IMAGE_DNS +
                                                                                                      '/Valofe_file/web/vfun-ko/images/common/icon-ios.png'
                                                                                                  }
                                                                                                  alt="Ios"
                                                                                              />
                                                                                          </Link>
                                                                                      </li>
                                                                                  ) : null
                                                                              ) : null}
                                                                              {this
                                                                                  .state
                                                                                  .showMobileDownLoadPopup[
                                                                                  game
                                                                                      .service_code
                                                                              ] ===
                                                                                  true &&
                                                                              this
                                                                                  .state
                                                                                  .MobileStoreDownLoadURL[
                                                                                  game
                                                                                      .service_code
                                                                              ] !==
                                                                                  undefined ? (
                                                                                  this
                                                                                      .state
                                                                                      .MobileStoreDownLoadURL[
                                                                                      game
                                                                                          .service_code
                                                                                  ][
                                                                                      'one_store'
                                                                                  ] !==
                                                                                  '' ? (
                                                                                      <li>
                                                                                          <Link
                                                                                              to="#"
                                                                                              className="mb-icon"
                                                                                              onClick={(
                                                                                                  e
                                                                                              ) =>
                                                                                                  redirectURL(
                                                                                                      this
                                                                                                          .state
                                                                                                          .MobileStoreDownLoadURL[
                                                                                                          game
                                                                                                              .service_code
                                                                                                      ][
                                                                                                          'one_store'
                                                                                                      ],
                                                                                                      '_blank'
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              <img
                                                                                                  src={
                                                                                                      IMAGE_DNS +
                                                                                                      '/Valofe_file/web/vfun-ko/images/common/icon-one.png'
                                                                                                  }
                                                                                                  alt="One Store"
                                                                                              />
                                                                                          </Link>
                                                                                      </li>
                                                                                  ) : null
                                                                              ) : null}
                                                                              {this
                                                                                  .state
                                                                                  .showMobileDownLoadPopup[
                                                                                  game
                                                                                      .service_code
                                                                              ] ===
                                                                                  true &&
                                                                              this
                                                                                  .state
                                                                                  .MobileStoreDownLoadURL[
                                                                                  game
                                                                                      .service_code
                                                                              ] !==
                                                                                  undefined ? (
                                                                                  this
                                                                                      .state
                                                                                      .MobileStoreDownLoadURL[
                                                                                      game
                                                                                          .service_code
                                                                                  ][
                                                                                      'galaxy_store'
                                                                                  ] !==
                                                                                  '' ? (
                                                                                      <li>
                                                                                          <Link
                                                                                              to="#"
                                                                                              className="mb-icon"
                                                                                              onClick={(
                                                                                                  e
                                                                                              ) =>
                                                                                                  redirectURL(
                                                                                                      this
                                                                                                          .state
                                                                                                          .MobileStoreDownLoadURL[
                                                                                                          game
                                                                                                              .service_code
                                                                                                      ][
                                                                                                          'galaxy_store'
                                                                                                      ],
                                                                                                      '_blank'
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              <img
                                                                                                  src={
                                                                                                      IMAGE_DNS +
                                                                                                      '/Valofe_file/web/vfun-ko/images/common/icon-gxy.png'
                                                                                                  }
                                                                                                  alt="One Store"
                                                                                              />
                                                                                          </Link>
                                                                                      </li>
                                                                                  ) : null
                                                                              ) : null}

                                                                              <li className="close-multi">
                                                                                  <Link
                                                                                      to="#"
                                                                                      onClick={(
                                                                                          e
                                                                                      ) =>
                                                                                          this.closeDownloadPopup(
                                                                                              game.service_code
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                      <span className="fa fa-times-circle-o" />
                                                                                  </Link>
                                                                              </li>
                                                                          </ul>
                                                                      </div>
                                                                  </div>
                                                              </FadeIn>
                                                          ) : null}

                                                          <ul className="desc-right">
                                                              <li>
                                                                  <Link
                                                                      className="btn-download"
                                                                      to="#"
                                                                      onClick={(
                                                                          e
                                                                      ) =>
                                                                          this.redirectDownURL(
                                                                              game.platform,
                                                                              game.service_code
                                                                          )
                                                                      }
                                                                  >
                                                                      <span className="fa fa-download" />
                                                                      &nbsp;&nbsp;Download
                                                                  </Link>
                                                              </li>
                                                              <li>
                                                                  <Link
                                                                      className="btn-like"
                                                                      to="#"
                                                                      id="like8"
                                                                      onClick="COMMON.like('8');"
                                                                  >
                                                                      &nbsp;&nbsp;
                                                                      <span className="fa fa-heart" />
                                                                      &nbsp;&nbsp;Like
                                                                  </Link>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                              </div>
                                          </li>
                                      ))
                                    : null}
                            </ul>
                        </article>
                    </div>
                    {/*  <div className="row-w padding-none">
                        <div className="wrap-btn">
                            <Link
                                to="#"
                                className="more"
                                onClick="readMore('ALL')"
                                id="more_load_btn"
                            >
                                <span className="fa fa-plus-circle" />
                                &nbsp;more
                            </Link>
                        </div>
                    </div>*/}

                    {/*<BottomTheme />*/}
                </section>
            </>
        )
    }
}

export default AllGamesContents
