import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Spinner from '../../components/common/Spinner'

import InfoContext from '../../contexts/InfoContext'
import { redirectURL } from '../../utils/CommonFunc'
import { IMAGE_DNS } from '../../utils/Config'

import '../../assets/css/main/ComingSoon.scoped.scss'
import { Event } from '../Tracking'

class ComingSoon extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        SwiperCore.use([Navigation])
        this.handleResize = this.handleResize.bind(this)

        this.state = {
            isLoading: true,
            innerWidth: window.innerWidth,
            ssoinfo:
                context.state.ssoinfo === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.user_id === false
                    ? 'none'
                    : context.state.user_id,
            jumin: context.state.jumin === false ? 'none' : context.state.jumin,
            comingsooncount: 0,
            comingsoonlist: '',
        }
        window.addEventListener('resize', e => this.handleResize())
    }

    //componentDidMount() { DidMounut는 언마운트 된 수 더이상 state 를 업데이트 할 수 없어, 메모리 릭이 발생한다는 오류 메시지가 발생하여 willMount 로 변경항
    componentWillMount() {
        this.getCommingSoonList();
        //추가 2022-04-28
    }

    getCommingSoonList() {
        let data = {}
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS + '/v1/Vfun/getComeSoonInfo',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                if (parseInt(res.data.result_code) === 1) {
                    this.setState({
                        comingsooncount: res.data.data.length,
                        //comingsooncount: 0,
                        comingsoonlist: res.data.data,
                        isLoading: false,
                    })
                }
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }

    handleResize() {
        this.setState({ innerWidth: window.innerWidth })
    }

    render() {
        return (
            <>
                {this.state.comingsooncount === 0 ? null : (
                    <section className="main-cont all-game" id="comingGame">
                        <div className="row-w clearfix">
                            <h1>추천 신작</h1> {/*출시 예정작*/}
                            {this.state.isLoading === true ? <Spinner /> : null}
                            <div className="clearfix" id="coming_soon">
                                <Swiper
                                   /* slidesPerView={
                                        window.innerWidth < 768 ? 1
                                            : (window.innerWidth < 1280 ?
                                                (this.state.comingsooncount > 2
                                                    ? 2
                                                    : this.state.comingsooncount
                                            )
                                    }*/
                                    slidesPerView={
                                        this.state.comingsooncount >= 3 ? ( (window.innerWidth >= 1280) ? 3 : ( (window.innerWidth <= 1280 && window.innerWidth)  > 768 ) ? 2  : 1)  :
                                            ( this.state.comingsooncount === 2 ? (  (window.innerWidth > 768) ? 2 : 1 )
                                                : this.state.comingsooncount
                                            )
                                    }

                                    navigation={
                                        window.innerWidth < 768 &&
                                        this.state.comingsooncount >= 2
                                            ? true
                                            : false
                                    }
                                >
                                    {this.state.isLoading === true
                                        ? null
                                        : this.state.comingsoonlist.map(
                                              (comingsoon, index) => (
                                                  <SwiperSlide key={index}>
                                                      <Link
                                                          to="#"
                                                          onClick={e =>
                                                              /*redirectURL(
                                                                  comingsoon.link_url, comingsoon.target
                                                                  //'_self'
                                                              )*/

                                                              [redirectURL( comingsoon.link_url, comingsoon.target ),
                                                                Event("커밍순 ", "Click", "R2Beat")
                                                              ]

                                                          }
                                                      >
                                                          <div className="hot item">
                                                              <div className="default clearfix">
                                                                  <div className="img-area">
                                                                      <img
                                                                          src={
                                                                              IMAGE_DNS +
                                                                              '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                                              comingsoon.service_code +
                                                                              '-small-thumb-min.jpg'
                                                                          }
                                                                          alt={
                                                                              comingsoon.title
                                                                          }
                                                                      />
                                                                  </div>
                                                                  <div className="desc">
                                                                      <h3>
                                                                          {
                                                                              comingsoon.title
                                                                          }
                                                                      </h3>
                                                                      <p
                                                                          className="short-desc ellipsis-multi line-2"
                                                                          dangerouslySetInnerHTML={{
                                                                              __html:
                                                                                  comingsoon.desc,
                                                                          }}
                                                                      />
                                                                      <p className="platform">
                                                                          {comingsoon.platform ===
                                                                          'PC' ? (
                                                                              <span className="fas fa-desktop" />
                                                                          ) : (
                                                                              <span className="fa fa-mobile" />
                                                                          )}
                                                                          &nbsp;
                                                                          {
                                                                              comingsoon.genre
                                                                          }
                                                                      </p>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </Link>
                                                  </SwiperSlide>
                                              )
                                          )}
                                </Swiper>
                            </div>
                        </div>
                    </section>
                )}
            </>
        )
    }
}

export default ComingSoon
