import React, { Component } from 'react'
import InspectionBgContents from '../../components/errors/InspectionBgContents'
import { HTML_TITLE_PREFIX } from '../../utils/Config'

class InspectionBg extends Component {
    componentDidMount() {
        document.title = HTML_TITLE_PREFIX + ' - 점검페이지';
    }
    render() {
        return (
            <InspectionBgContents />
        )
    }
}

export default InspectionBg