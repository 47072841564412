import React from 'react'
import axios from 'axios'
/*import { Link } from 'react-router-dom'*/
import Article from '../pageRight/Article'
/*import PopPageWrite from '../PopPageWrite'*/
/*import FadeIn from 'react-fade-in'*/
import { IMAGE_DNS } from '../../../utils/Config'

import InfoContext from '../../../contexts/InfoContext'

import './PageRight.scoped.scss'

class PageRight extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)

        this.state = {
            service_code: props.service_code,
            isLoading: true,
            isLogin: context.state.is_login,
            user_id: context.state.user_id,
            articleUrl: '',
            articlePage: false,
            //isOpenPageWrite: false,
        }
    }

    getGameInfo() {
        let data = { service_code: this.state.service_code }
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS + '/v1/Vfun/getAllGameInfo',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                if (parseInt(res.data.result_code) === 1) {
                    var PCGameList = []
                    var MobileGameList = []
                    var pc_key = 0
                    var mobile_key = 0
                    res.data.data.map((game, index) => {
                        if (game.platform.includes('PC')) {
                            PCGameList[pc_key] = game
                            ++pc_key
                        }
                        if (game.platform.includes('MOBILE')) {
                            MobileGameList[mobile_key] = game
                            ++mobile_key
                        }
                    })
                }
                //console.log(PCGameList)
                //console.log(MobileGameList)
                this.setState({
                    PCGameList: PCGameList,
                    MobileGameList: MobileGameList,
                    isLoading: false,
                })
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }

    fnSeeMore(articleNum) {
        this.setState({
            articlePage: !this.state.articlePage,
            articleUrl: articleNum,
        })
    }
    render() {
        return (
            <>
                <div className="page-right" id="page-right">
                    {this.state.isLogin === false ? (
                        <div className="top-comment">
                            {/*  1. 로그인 전 디폴트 유저 이미지   */}
                            <img
                                className="user-pic"
                                src={
                                    IMAGE_DNS +
                                    '/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png'
                                }
                                alt="로그인 전- 아이콘"
                            />
                            <input
                                onClick={e => this.props.handlePopPageWrite()}
                                className="input-comment"
                                type="text"
                                placeholder="글을 남기시려면 로그인해 주세요."
                                readOnly={true}
                                maxLength="500"
                            />
                        </div>
                    ) : (
                        <div className="top-comment">
                            {/*  로그인 후 : 유저 이미지 이미지 --> */}
                            <img
                                className="user-pic"
                                src={
                                    IMAGE_DNS +
                                    '/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png'
                                }
                                alt={'프로필 - ' + this.state.user_id}
                            />
                            <input
                                onClick={this.props.handlePopPageWrite}
                                className="input-comment"
                                type="text"
                                readOnly={true}
                                placeholder={
                                    this.state.user_id +
                                    '님, 소식을 알려주세요.'
                                }
                                maxLength="500"
                            />
                            {/*<!-- 로그인 시 : 플레이스 홀더 변경 "OOO님, 생각이나 의견을 남겨주세요." -->*/}
                        </div>
                    )}
                    {/*  로그인 후 : GM 이미지 --> */}
                    {/*
                    <div className="top-comment">
                        <img
                            className="user-pic"
                            src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/img-gm-min.png"
                            alt="프로빌 - GM"
                        />
                        <input
                            className="input-comment"
                            type="text"
                            placeholder="GM님, 생각이나 의견을 남겨주세요."
                            maxLength="500"
                        />
                    </div>
                    */}

                    <Article
                        handlePopPageImg={e => this.props.handlePopPageImg(e)}
                        isOpenPopImg={this.state.isOpenPopImg}
                        service_code={this.state.service_code}
                        boardRefresh={this.props.boardRefresh}
                        editBoardInfo={e => this.props.editBoardInfo(e)}
                        section={this.props.section}
                        handleReplyWrite={e => this.props.handleReplyWrite(e)}
                        handleBoardRefresh={e =>
                            this.props.handleBoardRefresh()
                        }
                        handleReplyEdit={e => this.props.handleReplyEdit(e)}
                    />
                </div>
            </>
        )
    }
}

export default PageRight
