import React from 'react'
import axios from 'axios'
import { Link, withRouter } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { withCookies } from 'react-cookie'
import moment from 'moment'
//import LnbPromo from './LnbPromo'

import InfoContext from '../../contexts/InfoContext'
import {
    PLATFORM_DNS,
    IMAGE_DNS,
    BILLING_CHARGE_URL,
    SIGN_IN_URL,
    SIGN_UP_URL,
    FIND_ID_URL,
    MY_INFO_URL,
    TERMS_URL,
    PRIVACY_URL,
    SERVICE_CODE,
    CHANNELING_TYPE,
} from '../../utils/Config'
import { redirectURL, checkLogin } from '../../utils/CommonFunc'
import '../../assets/css/fontawesome5_3_1.css'

class CombineMenu extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        this.goBack = this.goBack.bind(this)
        const { cookies } = props

        this.state = {
            is_hire_open: cookies.get('public_incruit') ? false : true,
            isLogin: context.state.is_login,
            user_nickname: context.state.user_nickname,
            is_combine_menu_open: context.state.is_combine_menu_open,
            accordion_menu_open_focus: [false, false, false, false],
            accordion_menu_open: [false, false, false, false],
            isLoading: true,
            is_wallet_open: false,
            ssoinfo:
                context.state.ssoinfo === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.user_id === false
                    ? 'none'
                    : context.state.user_id,
            jumin: context.state.jumin === false ? 'none' : context.state.jumin,
            vcoin: 0,
            isLoaing: true,
            is_login_history_open: false,
            LoginHistory: '',
            isLoadingLoginHistory: true,
            user_profile_key: context.state.user_profile_key || '',
        }
    }

    Logout() {
        const { cookies } = this.props
        /*
        var cookie_list = JSON.stringify(cookies.getAll())
        var cookie_list_prase = JSON.parse(cookie_list)
        console.log(cookie_list_prase)
        cookie_list_prase.forEach(element => {
            console.log(element)
        })
*/
        //console.log(process.env.REACT_APP_SSO_INFO_COOKIE_NAME)
        cookies.remove(process.env.REACT_APP_SSO_INFO_COOKIE_NAME, {
            path: '/',
            domain: '.valofe.com',
        })
        cookies.remove('goonzu', {
            path: '/',
            domain: '.valofe.com',
        })
        cookies.remove('jumin', {
            path: '/',
            domain: '.valofe.com',
        })
        redirectURL('/', '_self')
    }

    goMenu(url) {
        this.context.actions.setIsCombineMenuOpen(false)
        this.setState({
            is_combine_menu_open: false,
        })
        redirectURL(url, '_self')
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.context.state.is_combine_menu_open !==
            this.state.is_combine_menu_open
        ) {
            this.setState({
                is_combine_menu_open: this.context.state.is_combine_menu_open,
            })
        }
        if (prevState.is_combine_menu_open === false) {
            this.context.actions.setIsCombineMenuOpen(
                this.context.state.is_combine_menu_open
            )
        }
    }

    /*    checkNotToday() {
        var cookiedata = document.cookie;
        if ( cookiedata.indexOf("todayCookie=done") < 0 ){
            this.setState(this.is_hire_open === true)
        }
        else {
            this.setState(this.is_hire_open === false)
        }
    }*/

    isHire() {
        this.setState({
            is_hire_open: !this.state.is_hire_open,
        })
    }

    setPopupShowTerm(cookieName, days) {
        const { cookies } = this.props

        //const expires: Date = moment().add('days', days).toDate()
        const expires = moment().add('days', days).toDate()
        //console.log(expires)
        cookies.set(cookieName, true, { path: '/', expires })
        this.isHire()
    }

    recentlyLoginHistory() {
        if (this.state.is_login_history_open !== true) {
            if (!checkLogin(this.state.isLogin)) {
                return false
            }
            let data = {}
            axios
                .post(
                    process.env.REACT_APP_VFUN_API_DNS +
                        '/v1/Vfun/getLoginHistory',
                    JSON.stringify(data),
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            ssoinfo: this.state.ssoinfo,
                            userid: this.state.userid,
                            jumin: this.state.jumin,
                        },
                    }
                )
                .then((res) => {
                    if (parseInt(res.data.result_code) === 1) {
                        this.setState({
                            is_login_history_open:
                                !this.state.is_login_history_open,
                            LoginHistory: res.data.data,
                            isLoadingLoginHistory: false,
                        })
                    } else {
                        this.setState({
                            is_login_history_open:
                                !this.state.is_login_history_open,
                            LoginHistory: '',
                            isLoadingLoginHistory: false,
                        })
                    }
                })
                .catch(function (error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        } else {
            this.setState({
                is_login_history_open: !this.state.is_login_history_open,
            })
        }
    }

    handlefWalletCont() {
        if (this.state.is_wallet_open !== true) {
            if (!checkLogin(this.state.isLogin)) {
                return false
            }
            let data = {
                service_code: SERVICE_CODE,
                channeling_type: CHANNELING_TYPE,
            }
            axios
                .post(
                    process.env.REACT_APP_VFUN_API_DNS +
                        '/v1/Vfun/getCoinBalance',
                    JSON.stringify(data),
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            ssoinfo: this.state.ssoinfo,
                            userid: this.state.userid,
                            jumin: this.state.jumin,
                        },
                    }
                )
                .then((res) => {
                    //console.log(res.data.data.total_balance)
                    this.setState({
                        is_wallet_open: !this.state.is_wallet_open,
                        vcoin: res.data.data.total_balance,
                        isLoading: false,
                    })
                })
                .catch(function (error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        } else {
            this.setState({
                is_wallet_open: !this.state.is_wallet_open,
            })
        }
        //this.setState({ is_wallet_open: !this.state.is_wallet_open })
    }

    handelSideMenu(e) {
        if (this.context.state.is_mobile_header_menu_open === true) {
            this.context.actions.setIsMobileHeaderMenuOpen(false)
        }
        this.context.actions.setIsCombineMenuOpen(
            !this.state.is_combine_menu_open
        )
        this.setState({
            is_combine_menu_open: !this.state.is_combine_menu_open,
        })
    }

    handelAccordionMenu(menuNum) {
        this.state.accordion_menu_open_focus.map(
            (menu, index) =>
                (this.state.accordion_menu_open_focus[index] = false)
        )
        this.state.accordion_menu_open[menuNum] =
            !this.state.accordion_menu_open[menuNum]
        this.state.accordion_menu_open_focus[menuNum] =
            !this.state.accordion_menu_open_focus[menuNum]
    }

    goBack(e) {
        e.preventDefault()
        console.log(this.props.history)
        this.props.history.goBack()
    }

    render() {
        var styles = {
            bmMenuWrap: {
                top: 0,
                zIndex: 9000,
            },
        }

        return (
            <>
                <div id="combineMenu" className="clearfix">
                    <div className="wrap-cbm clearfix">
                        <ul className="cb-menu pull-left">
                            <li className="cbm-logo-li">
                                <Link to="/" className="cbm-logo" />
                            </li>
                            <li className="cbm-back">
                                <Link to="#" onClick={this.goBack}>
                                    <span className="fa fa-chevron-left" />
                                </Link>
                            </li>
                            <li className="cbm-bar">
                                <Link
                                    to="#"
                                    className="cbm-game"
                                    onClick={(e) => this.handelSideMenu()}
                                >
                                    <span className="fa fa-bars" />
                                </Link>
                            </li>
                        </ul>
                        <fieldset>
                            <legend className="hidden">LOGIN FORMULAR</legend>
                            {this.state.isLogin === true ? (
                                <ul className="login-area pull-right">
                                    <li className="text user-id">
                                        <Link
                                            to="#"
                                            onClick={(e) =>
                                                redirectURL(
                                                    MY_INFO_URL,
                                                    '_self'
                                                )
                                            }
                                            title="내 정보"
                                        >
                                            {/* <img
                                                src={
                                                    IMAGE_DNS +
                                                    '/Valofe_file/web/vfun-ko/images/common/icon/icon-user.png'
                                                }
                                                alt=""
                                            /> */}
                                            <span className="text-id">
                                                {this.state.user_nickname}
                                            </span>
                                        </Link>
                                    </li>
                                    {/* <li className="text latest-login">
                                        <Link
                                            to="#"
                                            title="최근 로그인"
                                            onClick={(e) =>
                                                this.recentlyLoginHistory()
                                            }
                                        >
                                            <img
                                                src={
                                                    IMAGE_DNS +
                                                    '/Valofe_file/web/vfun-ko/images/common/icon/icon-clock.png'
                                                }
                                                alt=""
                                            />
                                            <span className="latest-time">
                                                최근 로그인
                                            </span>
                                        </Link>
                                    </li> */}
                                    <li
                                        className="text"
                                        onClick={(e) =>
                                            redirectURL(
                                                `${process.env.REACT_APP_LOUNGE_DNS}/space/${this.state.user_profile_key}`,
                                                '_self'
                                            )
                                        }
                                    >
                                        <Link to="#">
                                            <span className="my-space-btn">
                                                My Space
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="text user-coin">
                                        <span
                                            className="wallet-click"
                                            onClick={(e) =>
                                                this.handlefWalletCont()
                                            }
                                        >
                                            <img
                                                src={
                                                    IMAGE_DNS +
                                                    '/Valofe_file/web/vfun-ko/images/common/icon/icon-wallet.png'
                                                }
                                                alt=""
                                            />
                                            &nbsp; &nbsp;V
                                            Wallet&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="fa fa-angle-down" />
                                        </span>
                                        <FadeIn
                                            visible={this.state.is_wallet_open}
                                            delay={1}
                                            transitionDuration={10}
                                        >
                                            <ul
                                                id="vwallet"
                                                style={{
                                                    display:
                                                        this.state
                                                            .is_wallet_open ===
                                                        true
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                            >
                                                <li className="coin clearfix">
                                                    <div className="clearfix">
                                                        <span>V</span>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <th>
                                                                        V Coin
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        {this
                                                                            .state
                                                                            .isLoading ===
                                                                        false
                                                                            ? this.state.vcoin.toLocaleString()
                                                                            : 0}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <Link
                                                        to="#"
                                                        className="btn-charge"
                                                        target="_self"
                                                        title="충전"
                                                        onClick={(e) =>
                                                            redirectURL(
                                                                BILLING_CHARGE_URL,
                                                                '_self'
                                                            )
                                                        }
                                                    >
                                                        충전
                                                    </Link>
                                                    <Link
                                                        to="#"
                                                        target="_self"
                                                        title="포인트 교환"
                                                        onClick={(e) =>
                                                            redirectURL(
                                                                'https://bill.valofe.com/mycash/charge_info.asp#Center2_lbCharge',
                                                                '_self'
                                                            )
                                                        }
                                                        className="btn-point-exchange"
                                                    >
                                                        포인트 교환
                                                    </Link>
                                                </li>
                                            </ul>
                                        </FadeIn>
                                    </li>
                                    <li className="login">
                                        <Link
                                            to="#"
                                            onClick={(e) => this.Logout()}
                                        >
                                            로그아웃
                                        </Link>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="login-area pull-right">
                                    <li className="login">
                                        <Link
                                            to="#"
                                            onClick={(e) =>
                                                redirectURL(
                                                    SIGN_IN_URL,
                                                    '_self'
                                                )
                                            }
                                        >
                                            로그인
                                        </Link>
                                    </li>
                                    <li className="signup">
                                        <Link
                                            to="#"
                                            onClick={(e) =>
                                                redirectURL(
                                                    SIGN_UP_URL,
                                                    '_self'
                                                )
                                            }
                                        >
                                            회원가입
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </fieldset>
                    </div>
                </div>
                <Menu
                    isOpen={this.state.is_combine_menu_open}
                    onClose={(e) => this.handelSideMenu()}
                    customBurgerIcon={false}
                    styles={styles}
                >
                    <div id="cbm-aside">
                        <div className="side-menu">
                            {/*<!-- 통합메뉴 타이틀 -->*/}
                            <h1 className="tit-service">
                                <Link to="/">
                                    <img
                                        src={
                                            IMAGE_DNS +
                                            '/Valofe_file/web/vfun-ko/images/logo/logo-vfun.png'
                                        }
                                        alt="VFUN KR Logo"
                                    />
                                </Link>
                                <Link
                                    to="#"
                                    className="btn-cbm-close"
                                    onClick={(e) => this.handelSideMenu()}
                                />
                            </h1>

                            {this.state.isLogin === true ? (
                                <div className="user-info login clearfix">
                                    <span className="user-name">
                                        환영합니다!&nbsp;
                                        <Link
                                            to={
                                                PLATFORM_DNS +
                                                '/membership/myinfo?service_code=<?=$service_code?>&ret=<?=$ret?>'
                                            }
                                            className="info"
                                            title="내 정보"
                                            onClick={(e) =>
                                                redirectURL(
                                                    MY_INFO_URL,
                                                    '_self'
                                                )
                                            }
                                        >
                                            <span className="color-theme pull-right">
                                                {this.state.user_nickname}
                                            </span>
                                        </Link>
                                        {/*<Link
                                            to="#"
                                            title="최근 로그인"
                                            className="latest-clock"
                                        >
                                            <img
                                                src={
                                                    IMAGE_DNS +
                                                    '/Valofe_file/web/vfun-ko/images/common/icon/icon-clock.png'
                                                }
                                                alt="clock"
                                            />
                                        </Link>*/}
                                    </span>
                                    <ul className="pull-right clearfix">
                                        <li
                                            onClick={(e) =>
                                                redirectURL(
                                                    `${process.env.REACT_APP_LOUNGE_DNS}/space/${this.state.user_profile_key}`,
                                                    '_self'
                                                )
                                            }
                                        >
                                            <Link to="#">
                                                <span className="my-space-btn">
                                                    My Space
                                                </span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="#"
                                                onClick={(e) => this.Logout()}
                                            >
                                                로그아웃
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            ) : (
                                <div className="user-info not-login clearfix">
                                    <ul className="pull-right clearfix">
                                        <li>
                                            <Link
                                                to="#"
                                                onClick={(e) =>
                                                    redirectURL(
                                                        SIGN_IN_URL,
                                                        '_self'
                                                    )
                                                }
                                            >
                                                로그인
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="#"
                                                onClick={(e) =>
                                                    redirectURL(
                                                        SIGN_UP_URL,
                                                        '_self'
                                                    )
                                                }
                                            >
                                                회원가입
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )}

                            <div className="nav-list">
                                <ul>
                                    {/*<!-- vfun game -->*/}
                                    <li
                                        className={
                                            this.state
                                                .accordion_menu_open_focus[0] ===
                                            true
                                                ? 'nav-li on'
                                                : 'nav-li'
                                        }
                                    >
                                        <Link
                                            to="#"
                                            className="has-ul"
                                            onClick={(e) =>
                                                this.handelAccordionMenu(0)
                                            }
                                        >
                                            <span className="fa fa-play-circle icon color-theme" />
                                            게임
                                            <span className="size">1</span>
                                            <span
                                                className={
                                                    this.state
                                                        .accordion_menu_open_focus[0] ===
                                                    true
                                                        ? 'blit-arrow on'
                                                        : 'blit-arrow'
                                                }
                                            />
                                        </Link>
                                        <ul
                                            className={
                                                this.state
                                                    .accordion_menu_open[0] ===
                                                true
                                                    ? 'nav-list2 on'
                                                    : 'nav-list2'
                                            }
                                        >
                                            <li className="">
                                                <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                        this.goMenu(
                                                            '/game/all_games'
                                                        )
                                                    }
                                                >
                                                    전체 게임
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    {/*<!-- ./ vfun game -->*/}
                                    {/*<!-- vfun lounge -->*/}
                                    <li
                                        className={
                                            this.state
                                                .accordion_menu_open_focus[1] ===
                                            true
                                                ? 'nav-li on'
                                                : 'nav-li'
                                        }
                                    >
                                        <Link
                                            to="#"
                                            className="has-ul"
                                            onClick={(e) =>
                                                this.handelAccordionMenu(1)
                                            }
                                        >
                                            <span className="fa fa-comments icon color-theme" />
                                            VFUN 라운지
                                            <span className="size">3</span>
                                            <span
                                                className={
                                                    this.state
                                                        .accordion_menu_open_focus[1] ===
                                                    true
                                                        ? 'blit-arrow on'
                                                        : 'blit-arrow'
                                                }
                                            />
                                        </Link>
                                        <ul
                                            className={
                                                this.state
                                                    .accordion_menu_open[1] ===
                                                true
                                                    ? 'nav-list2 on'
                                                    : 'nav-list2'
                                            }
                                        >
                                            <li className="">
                                                <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                        this.goMenu(
                                                            'https://vfun-lounge-kr.valofe.com/page/club-mstar'
                                                        )
                                                    }
                                                >
                                                    클럽 엠스타
                                                </Link>
                                            </li>
                                            <li className="">
                                                <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                        this.goMenu(
                                                            'https://vfun-lounge-kr.valofe.com/page/soulworker'
                                                        )
                                                    }
                                                >
                                                    소울워커
                                                </Link>
                                            </li>

                                            <li className="">
                                                <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                        this.goMenu(
                                                            'https://vfun-lounge-kr.valofe.com/page/kritika'
                                                        )
                                                    }
                                                >
                                                    크리티카: 제로
                                                </Link>
                                            </li>
                                            <li className="">
                                                <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                        this.goMenu(
                                                            'https://vfun-lounge-kr.valofe.com/page/luniaz-ko'
                                                        )
                                                    }
                                                >
                                                    루니아Z
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    {/*<!-- ./ vfun lounge -->*/}
                                    {/*<!-- membership -->*/}
                                    <li
                                        className={
                                            this.state
                                                .accordion_menu_open_focus[2] ===
                                            true
                                                ? 'nav-li on'
                                                : 'nav-li'
                                        }
                                    >
                                        <Link
                                            to="#"
                                            className="has-ul"
                                            onClick={(e) =>
                                                this.handelAccordionMenu(2)
                                            }
                                        >
                                            <span className="fa fa-star icon  color-theme" />
                                            멤버십
                                            {this.state.isLogin === true ? (
                                                <span className="size">2</span>
                                            ) : (
                                                <span className="size">4</span>
                                            )}
                                            <span
                                                className={
                                                    this.state
                                                        .accordion_menu_open_focus[2] ===
                                                    true
                                                        ? 'blit-arrow on'
                                                        : 'blit-arrow'
                                                }
                                            />
                                        </Link>
                                        <ul
                                            className={
                                                this.state
                                                    .accordion_menu_open[2] ===
                                                true
                                                    ? 'nav-list2 on'
                                                    : 'nav-list2'
                                            }
                                        >
                                            {this.state.isLogin === true ? (
                                                <li>
                                                    <Link
                                                        to="#"
                                                        onClick={(e) =>
                                                            redirectURL(
                                                                MY_INFO_URL,
                                                                '_self'
                                                            )
                                                        }
                                                    >
                                                        내 정보
                                                    </Link>
                                                </li>
                                            ) : (
                                                <>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            onClick={(e) =>
                                                                redirectURL(
                                                                    SIGN_UP_URL,
                                                                    '_self'
                                                                )
                                                            }
                                                        >
                                                            회원가입
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            onClick={(e) =>
                                                                redirectURL(
                                                                    SIGN_IN_URL,
                                                                    '_self'
                                                                )
                                                            }
                                                        >
                                                            로그인
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            onClick={(e) =>
                                                                redirectURL(
                                                                    FIND_ID_URL,
                                                                    '_self'
                                                                )
                                                            }
                                                        >
                                                            아이디 / 비밀번호
                                                            찾기
                                                        </Link>
                                                    </li>
                                                </>
                                            )}
                                            <>
                                                <li className="">
                                                    <Link
                                                        to="#"
                                                        onClick={(e) =>
                                                            redirectURL(
                                                                BILLING_CHARGE_URL,
                                                                '_blank'
                                                            )
                                                        }
                                                    >
                                                        VCoin
                                                    </Link>
                                                </li>
                                            </>
                                            <>
                                                <li className="">
                                                    <Link
                                                        to="#"
                                                        target="_self"
                                                        onClick={(e) =>
                                                            redirectURL(
                                                                'https://bill.valofe.com/mycash/charge_info.asp#Center2_lbCharge',
                                                                '_self'
                                                            )
                                                        }
                                                    >
                                                        포인트 교환
                                                    </Link>
                                                </li>
                                            </>
                                        </ul>
                                    </li>
                                    <li
                                        className={
                                            this.state
                                                .accordion_menu_open_focus[3] ===
                                            true
                                                ? 'nav-li on'
                                                : 'nav-li'
                                        }
                                    >
                                        <Link
                                            to="#"
                                            className="has-ul"
                                            onClick={(e) =>
                                                this.handelAccordionMenu(3)
                                            }
                                        >
                                            <span className="fa fa-info-circle icon color-theme" />
                                            고객센터
                                            {this.state.isLogin === true ? (
                                                <span className="size">4</span>
                                            ) : (
                                                <span className="size">2</span>
                                            )}
                                            <span
                                                className={
                                                    this.state
                                                        .accordion_menu_open_focus[3] ===
                                                    true
                                                        ? 'blit-arrow on'
                                                        : 'blit-arrow'
                                                }
                                            />
                                        </Link>
                                        <ul
                                            className={
                                                this.state
                                                    .accordion_menu_open[3] ===
                                                true
                                                    ? 'nav-list2 on'
                                                    : 'nav-list2'
                                            }
                                        >
                                            <li className="">
                                                <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                        redirectURL(
                                                            TERMS_URL,
                                                            '_self'
                                                        )
                                                    }
                                                >
                                                    이용약관
                                                </Link>
                                            </li>
                                            <li className="">
                                                <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                        redirectURL(
                                                            PRIVACY_URL,
                                                            '_self'
                                                        )
                                                    }
                                                >
                                                    개인정보처리방침
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                            {/*<LnbPromo />*/}
                        </div>
                    </div>
                </Menu>

                {/*<!-- 로그인 히스토리 팝업 -->*/}
                <div
                    className="layer-wrap-loginHistory"
                    id="loginHistory"
                    style={{
                        display:
                            this.state.is_login_history_open === true &&
                            this.state.isLoadingLoginHistory === false
                                ? 'block'
                                : 'none',
                    }}
                >
                    <div
                        className="login-history-shadow"
                        onClick={(e) => this.recentlyLoginHistory()}
                    />
                    <div className="pop-layer">
                        <Link
                            className="btn-layer-close"
                            to="#"
                            onClick={(e) => this.recentlyLoginHistory()}
                        >
                            x
                        </Link>
                        <div className="pop-container animationOn">
                            <h2 className="skip">VFUN 메세지</h2>
                            <div className="frame">
                                <h1>
                                    <img
                                        src={
                                            IMAGE_DNS +
                                            '/Valofe_file/web/vfun-ko/images/vfun-16.png'
                                        }
                                        alt=""
                                    />
                                    VFUN 로그인 기록
                                </h1>

                                <div className="alert">
                                    <p className="p-desc">
                                        최근 3개월간 로그인 성공 내역을 확인할
                                        수 있습니다.
                                    </p>
                                    <div className="tbl-wrapper">
                                        <table className="tbl-purchase">
                                            <tbody id="login_history">
                                                <tr>
                                                    <th>접속일자</th>
                                                </tr>
                                                {this.state.LoginHistory ===
                                                '' ? (
                                                    <tr>
                                                        <td className="td-md">
                                                            최근 3개월간 접속
                                                            기록이 없습니다.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    this.state.LoginHistory.map(
                                                        (
                                                            login_history,
                                                            index
                                                        ) => (
                                                            <tr>
                                                                <td className="td-md">
                                                                    {
                                                                        login_history.login_date
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        className="paging"
                                        id="login_history_paging"
                                    >
                                        <ul>
                                            <li className="on">
                                                <Link to="#" className="num">
                                                    1
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="btn-wrap clearfix alert-btn text-center">
                                    <Link
                                        to="#"
                                        onClick={(e) =>
                                            this.recentlyLoginHistory()
                                        }
                                    >
                                        확인
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<!-- 공개채용 팝업 -->*/}
                {/*<div
                    className="layer-wrap"
                    id="hiring"
                    style={{
                        display:
                            this.state.is_hire_open === true ? 'block' : 'none',
                    }}
                >
                    <div className="shadow" onClick={e => this.isHire()} />
                    <div className="pop-layer clearfix">
                        <div className="pop-container animationOn">
                            <h2 className="skip">VFUN 메세지</h2>
                            <div className="frame">
                                <Link
                                    className="btn-layer-close"
                                    to="#"
                                    onClick={e => this.isHire()}
                                >
                                    x
                                </Link>
                                <h1>
                                    <img
                                        src={
                                            IMAGE_DNS +
                                            '/Valofe_file/web/vfun-ko/images/vfun-16.png'
                                        }
                                        alt=""
                                    />
                                    VALOFE 신입/경력 사원 공개 채용
                                </h1>

                                <div className="alert">
                                    <img
                                        className="clickable"
                                        onClick={() =>
                                            window.open(
                                                'https://www.gamejob.co.kr/List_GI/GIB_Read.asp?GI_No=207584',
                                                '_blank'
                                            )
                                        }
                                        src={
                                            IMAGE_DNS +
                                            '/Valofe_file/web/www/images/popup/incruit-min.jpg'
                                        }
                                        alt="밸로프 신입/경력사원 공개채용"
                                    />

                                    <div className="todayopen clearfix">
                                        <label
                                            htmlFor="mpopupnottoday1"
                                            className="closetxt"
                                        >
                                            <input
                                                type="checkbox"
                                                className="mpopupnottoday"
                                                name="mpopupnottoday1"
                                                id="mpopupnottoday1"
                                                value="checked"
                                                onClick={() =>
                                                    this.setPopupShowTerm(
                                                        'public_incruit',
                                                        1
                                                    )
                                                }
                                            />
                                            <span>오늘 하루 안 보기</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </>
        )
    }
}

export default withRouter(withCookies(CombineMenu))
