import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn'
import Photogrid from 'react-facebook-photo-grid'
import imageCompression from 'browser-image-compression'

import emoji from './data/emoji.json'
import './PopPageWrite.scoped.scss'

import { IMAGE_DNS } from '../../utils/Config'
import { needLogin } from '../../utils/CommonFunc'

import InfoContext from '../../contexts/InfoContext'

/*import { Thumbs } from 'swiper'Thumbs*/

class PopPageReplyWrite extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        this.state = {
            editMode: false,
            contents: '',
            real_contents: '',
            isOpenEmoji: false,
            emoji: emoji.emoticon,
            category: 1,
            isOpenReplyWrite: props.isOpenReplyWrite,
            service_code: props.service_code,
            ssoinfo: context.state.ssoinfo,
            userid: context.state.user_id,
            jumin: context.state.jumin,
            isLogin: context.state.is_login,
            files: [],
            preview_files: [],
            upload_file_urls: [],
            title: '댓글쓰기',
            button: '게시하기',
            mode: 'write',
            edit_idx: 0,
            replyBoardIdx: props.replyBoardIdx,
            replyIdx: props.replyIdx,
        }
    }

    changeMode() {
        if (this.state.editMode === false) {
            this.setState({ contents: '', editMode: true })
        }
    }

    inputContent(e) {
        //입력하는 부분에 대해선 실시간으로 적용하지 않음.
        this.setState.contents = e.target.innerHTML

        if (this.state.contents.length === 0) {
            this.setState({
                contents: '',
                real_contents: '',
                editMode: false,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isOpenReplyWrite !== this.props.isOpenReplyWrite) {
            this.setState({ isOpenReplyWrite: this.props.isOpenReplyWrite })
        }
        if (prevProps.replyBoardIdx !== this.props.replyBoardIdx) {
            this.setState({ replyBoardIdx: this.props.replyBoardIdx })
        }
        if (prevProps.replyIdx !== this.props.replyIdx) {
            this.setState({ replyIdx: this.props.replyIdx, mode: 'write' })
        }
        if (
            prevProps.replyIdx !== this.props.replyIdx &&
            this.props.replyBoardIdx === undefined
        ) {
            this.editReply(this.props.replyIdx)
        }
    }

    editReply(replyIdx) {
        console.log('edit')

        let data = {
            idx: replyIdx,
        }
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS +
                    '/v1/Vfun/getCommunityBoardCommentByIdx',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                if (parseInt(res.data.result_code) === 1) {
                    this.setState({
                        edit_idx: replyIdx,
                        mode: 'edit',
                        editMode: true,
                        contents: res.data.data.comment,
                        real_contents: res.data.data.comment,
                        title: '수정하기',
                        button: '수정하기',
                    })
                }
                //console.log(res.data)
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }
    changeCategory(category) {
        this.setState({ category: category })
    }

    openEmoji() {
        this.setState({
            isOpenEmoji: !this.state.isOpenEmoji,
        })
    }

    selEmoji(selEmoji) {
        let contents = this.state.contents
        contents =
            this.state.contents +
            "<img src='" +
            this.state.emoji[selEmoji].image_url +
            "' />"

        this.setState({
            isOpenEmoji: !this.state.isOpenEmoji,
            contents: contents,
        })
    }

    handleChange(event) {
        this.setState({ contents: event.target.value })
    }

    handleSubmit = async () => {
        var confirm_message = '댓글을 작성하시겠어요?'
        if (this.state.mode === 'edit') {
            confirm_message = '댓글을 수정하시겠어요?'
        }
        if (window.confirm(confirm_message)) {
            let real_contents = this.state.contents
            for (const key in this.state.emoji) {
                if (
                    parseInt(
                        real_contents.indexOf(this.state.emoji[key].image_url)
                    ) !== -1
                ) {
                    //console.log(image_url_tag)
                    real_contents = real_contents.replace(
                        this.state.emoji[key].image_url,
                        this.state.emoji[key].keyword
                    )
                    real_contents = real_contents.replace('<img src="', '')
                    real_contents = real_contents.replace('">', '')
                    real_contents = real_contents.replace("<img src='", '')
                    real_contents = real_contents.replace("' />", '')
                }
            }
            this.state.real_contents = real_contents
            if (!this.state.isLogin) {
                needLogin()
            } else {
                this.uploadImage()
            }
        }

        return false
    }

    cancleWrite() {
        this.setState({
            files: [],
            preview_files: [],
            contents: '',
            real_contents: '',
            editMode: false,
            upload_file_urls: [],
            title: '댓글쓰기',
            button: '게시하기',
        })
        this.props.handleReplyPopup(0, 0, 0)
    }

    writeReply(upload_urls) {
        if (upload_urls !== false) {
            this.setState({
                upload_file_urls: this.state.upload_file_urls.concat(
                    upload_urls
                ),
            })
        } else {
            this.setState({
                upload_file_urls: [],
            })
        }

        if (this.state.files.length !== this.state.upload_file_urls.length) {
            return false
        }
        if (this.state.mode === 'edit') {
            let data = {
                idx: this.state.edit_idx,
                service_code: this.state.service_code,
                comment: this.state.real_contents,
                images: this.state.upload_file_urls,
            }
            axios
                .post(
                    process.env.REACT_APP_VFUN_API_DNS +
                        '/v1/Vfun/putCommunityBoardCommentByIdx',
                    JSON.stringify(data),
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            ssoinfo: this.state.ssoinfo,
                            userid: this.state.userid,
                            jumin: this.state.jumin,
                        },
                    }
                )
                .then(res => {
                    if (parseInt(res.data.result_code) === 1) {
                        //window.location.reload()
                        this.cancleWrite()
                        this.props.handleBoardRefresh()
                        //handleReplyEdit
                    }
                })
                .catch(function(error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        } else {
            let data = {
                comment: this.state.real_contents,
                images: this.state.upload_file_urls,
                board_idx: this.state.replyBoardIdx,
                comment_idx: this.state.replyIdx,
            }
            console.log(data)
            axios
                .post(
                    process.env.REACT_APP_VFUN_API_DNS +
                        '/v1/Vfun/setCommunityBoardComment',
                    JSON.stringify(data),
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            ssoinfo: this.state.ssoinfo,
                            userid: this.state.userid,
                            jumin: this.state.jumin,
                        },
                    }
                )
                .then(res => {
                    if (parseInt(res.data.result_code) === 1) {
                        //window.location.reload()
                        //this.cancleWrite()
                        //this.handleReplyEdit()
                        this.cancleWrite()
                        this.props.handleBoardRefresh()
                    }
                })
                .catch(function(error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        }
    }

    readFile(compressedFile) {
        const reader = new FileReader()
        console.log('compress')
        reader.readAsDataURL(compressedFile)
        console.log('upload start')
        reader.onloadend = () => {
            const base64Image = reader.result

            const formData = new FormData()
            formData.append('type', 'cdn')
            formData.append('folder_name', 'web/vfun-ko/images/user/community')
            formData.append(
                'file_extension',
                base64Image.split(';')[0].split('/')[1]
            )
            formData.append('attach_file', base64Image.split(',')[1])

            axios
                .post(
                    process.env.REACT_APP_FILE_UPLOAD_API_DNS + '/ftpupload',
                    formData
                )
                .then(res => this.writeReply(res.data.upload_url))
                .catch(function(error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        }
    }
    uploadImage = async () => {
        if (this.state.files.length > 0) {
            var compressedFile
            var options
            try {
                for (var i = 0; i < this.state.files.length; i++) {
                    options = {
                        maxSizeMB: 0.2,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true,
                    }
                    compressedFile = await imageCompression(
                        this.state.files[i],
                        options
                    )
                    this.readFile(compressedFile)
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            this.writeReply()
        }
    }

    handleReplyEdit(e) {
        this.setState({
            files: [],
            preview_files: [],
            contents: '',
            real_contents: '',
            editMode: false,
            upload_file_urls: [],
        })
        this.props.handleReplyEdit(e)
        //this.props.handlePopPageWrite()
    }

    cancelImage() {
        this.setState({ files: [], preview_files: [] })
    }

    uploadSingleFile(e) {
        if (e.target.files[0] !== undefined) {
            const preview_files = this.state.preview_files
            const files = this.state.files
            for (var i = 0; i < e.target.files.length; i++) {
                preview_files.push(URL.createObjectURL(e.target.files[i]))
                files.push(e.target.files[i])
            }
            this.setState({
                files: files,
                preview_files: preview_files,
            })
        }
    }

    render() {
        return (
            <>
                {/*<!-- 글쓰기 팝업 -->*/}
                <FadeIn
                    visible={this.state.isOpenReplyWrite}
                    delay={10}
                    transitionDuration={400}
                >
                    <div className="layer-wrap article-type">
                        <div
                            className="shadow"
                            onClick={e => this.cancleWrite()}
                        />
                        <div className="pop-layer">
                            <h1 className="clearfix">
                                {this.state.title}
                                <Link
                                    className="btn-layer-close"
                                    to="#"
                                    onClick={e => this.cancleWrite()}
                                >
                                    <img
                                        src={
                                            IMAGE_DNS +
                                            '/Valofe_file/web/vfun-ko/images/common/icon/icon-page-pop-close-btn.png'
                                        }
                                        alt="댓글쓰기 팝업 창 닫기"
                                    />
                                </Link>
                            </h1>

                            <div className="pop-container animationOn">
                                <h2 className="skip">VFUN 메세지</h2>
                                <div className="frame">
                                    <div className="alert">
                                        <div className="info clearfix">
                                            <img
                                                className="user-pic"
                                                src={
                                                    IMAGE_DNS +
                                                    '/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png'
                                                }
                                                alt="프로필 - 디폴트"
                                            />
                                            <div className="info-writing f-right">
                                                <span className="nickname">
                                                    {this.state.userid}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="txt"
                                            id="textArea"
                                            ref={this.myRef}
                                            contentEditable="true"
                                            placeholder="댓글을 입력해주세요."
                                            onChange={e => this.handleChange(e)}
                                            onKeyUp={e => this.inputContent(e)}
                                            onFocus={e => this.changeMode()}
                                            dangerouslySetInnerHTML={{
                                                __html: this.state.contents,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="btn-wrap images">
                                    <ul className="f-right re-reply-activity clearfix">
                                        <li className="li-camera">
                                            <input
                                                type="file"
                                                className="icon camera"
                                                multiple
                                                onChange={e =>
                                                    this.uploadSingleFile(e)
                                                }
                                            />
                                        </li>
                                        <li className="li-emoji clearix">
                                            <Link
                                                to="#"
                                                className="icon emoji"
                                                onClick={e => this.openEmoji()}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className="btn-wrap photogrid">
                                    {this.state.preview_files.length !== 0 ? (
                                        <div className="wrap-photogrid">
                                            <Photogrid
                                                images={
                                                    this.state.preview_files
                                                }
                                                container="true"
                                            />
                                            <img
                                                className="btn-layer-close"
                                                src={
                                                    IMAGE_DNS +
                                                    '/Valofe_file/web/vfun-ko/images/common/icon/icon-page-pop-close-btn.png'
                                                }
                                                onClick={e =>
                                                    this.cancelImage()
                                                }
                                                alt="포토 이미지 닫기"
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="btn-wrap long">
                                <Link
                                    to="#"
                                    className="btn-post"
                                    value="Submit"
                                    onClick={e => this.handleSubmit()}
                                >
                                    {this.state.button}
                                </Link>
                            </div>

                            {/* 이모지 리스트 */}
                            {this.state.isOpenEmoji ? (
                                <ul className="emoji-list">
                                    {this.state.emoji.map((emoji, index) => (
                                        <li
                                            className="emojis"
                                            onClick={e => this.selEmoji(index)}
                                        >
                                            <img
                                                src={emoji.image_url}
                                                alt={emoji.title}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            ) : null}
                        </div>
                    </div>
                </FadeIn>
            </>
        )
    }
}
export default PopPageReplyWrite
