import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import InfoContext from '../../../contexts/InfoContext'

import './Reply.scoped.scss'
import { IMAGE_DNS } from '../../../utils/Config'
import emoji from '../data/emoji'
//import { Thumbs } from 'swiper'

class Reply extends React.Component {
    static contextType = InfoContext
    constructor(props, context) {
        super(props)
        this.state = {
            ssoinfo:
                context.state.is_login === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.is_login === false
                    ? 'none'
                    : context.state.user_id,
            jumin:
                context.state.is_login === false ? 'none' : context.state.jumin,
            isMyLike: false,
            isMyReply: false,
            isLogin: context.state.is_login,
            isReplyOpen: false,
            is_popup_open: false,
            articlePage: false,
            pictures: [],
            isOpenEmoji: false,
            emoji: emoji.emoticon,
            comments: props.comments,
            board_idx: props.board_idx,
            isOpenReplyEditor: [],
        }
        /*        this.targetId = props.targetId*/
    }

    // 댓글 에디터
    handleReplyEditor(writerUserId, comment_info) {
        // 왜 하나만 선택되지 않고...다 선택되지 ;;;
        var isOpenReplyEditor = this.state.isOpenReplyEditor
        if (isOpenReplyEditor[comment_info] === undefined) {
            isOpenReplyEditor[comment_info] = false
        }
        isOpenReplyEditor[comment_info] = !isOpenReplyEditor[comment_info]
        if (this.state.isLogin === true) {
            // 로그인 + 내댓글 : 수정, 삭제만
            if (writerUserId === this.state.userid) {
                this.setState({
                    isOpenReplyEditor: isOpenReplyEditor,
                    isMyReply: true,
                })

                // 로그인 + 남의글 : 신고만
            } else {
                this.setState({
                    isOpenReplyEditor: isOpenReplyEditor,
                    isMyReply: false,
                })
            }
            // 로그인 안함
        } else {
            //로그인 후 이용하실 수 있습니다.
            alert('로그인 후 이용해 주세요~!')
        }
    }

    handleReplyMore(idx) {
        // 예) 댓글 더보기를 1번 누르면 12개(기존 2개 + 10개)  보여짐
        this.setState({
            isReplyOpen: !this.state.isReplyOpen,
        })
        // 어떻게 10개씩 보여주지...
    }

    handleReplyEdit(e) {
        // 댓글 편집 창이 열립니다.
        // 자신의 글일 때만 가능합니다.
        this.props.handleReplyEdit(e)
        this.setState({ isOpenReplyEditor: [] })
    }
    handleDeleteReply(comment_idx) {
        // 댓글을 삭제합니다.
        // 자신의 글일 때만 가능합니다. & 대댓글이 없을 때만 가능합니다.
        if (window.confirm('해당 댓글을 삭제 하시겠습니까?')) {
            let data = { idx: comment_idx }
            axios
                .post(
                    process.env.REACT_APP_VFUN_API_DNS +
                        '/v1/Vfun/delCommunityBoardCommentByIdx',
                    JSON.stringify(data),
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            ssoinfo: this.state.ssoinfo,
                            userid: this.state.userid,
                            jumin: this.state.jumin,
                        },
                    }
                )
                .then(res => {
                    if (parseInt(res.data.result_code) === 1) {
                        //this.getCommunityList(this.state.section)
                        this.setState({ isOpenReplyEditor: [] })
                        this.props.handleBoardRefresh()
                        //this.setState({ isOpenArticleEditor: [] })
                    }
                })
                .catch(function(error) {
                    if (error.response.status >= 500) {
                        alert(
                            '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                        )
                    }
                })
        }
    }

    handleReportReply() {
        //게시글을 신고합니다.
        // 신고한 게시글은 어드민에 기록됩니다.
        // 관리자의 관리에 따라 삭제됩니다.
    }
    handleReplyLike() {
        this.setState({
            isMyLike: !this.state.isMyLike,
        })

        // 내가 누른 상태값을 기억합니다.
        // 누른 적이 없다면 좋아요 수가 추가되고 on  상태가 됩니다.
        // 누른 적이 있다면 좋아요 수가 내려가고 off 상태가 됩니다.
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.comments !== this.props.comments) {
            this.setState({ comments: this.props.comments })
        }
    }

    render() {
        return (
            <>
                {/*// 댓글이 있는 경우, 기본 2개를 보여줍니다.
                // 댓글 더보기를 누르면 추가로 10개씩 더 보여줍니다.*/}
                <div className="replys">
                    {/* 댓글 1 리스트 */}
                    {this.state.comments.map((comment, index) => (
                        <div className="reply">
                            {/* 댓글 1 */}
                            <div className="info clearfix">
                                <img
                                    className="user-pic"
                                    src={
                                        IMAGE_DNS +
                                        '/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png'
                                    }
                                    alt="profile-default"
                                />
                                {/*<img className="user-pic"  src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png" alt="프로필 - 디폴트" />*/}
                                {/*<img className="user-pic"  src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/img-gm-min.png" alt="프로필 - GM" /> */}
                                <div className="info-writing f-right">
                                    <span className="nickname">
                                        {comment.user_id}
                                    </span>
                                    <span className="sum-activity">
                                        <i className="icon like" /> 175
                                    </span>
                                    <Link
                                        to="#"
                                        className="btns icon-dots"
                                        onClick={e =>
                                            this.handleReplyEditor(
                                                comment.user_id,
                                                'commnet_info_' +
                                                    (comment.idx + 1)
                                            )
                                        }
                                    />
                                    {this.state.isLogin ? (
                                        <ul
                                            className={
                                                this.state.isOpenReplyEditor[
                                                    'commnet_info_' +
                                                        (comment.idx + 1)
                                                ]
                                                    ? 'btns editing on'
                                                    : 'btns editing'
                                            }
                                        >
                                            {this.state.isMyReply ? (
                                                <>
                                                    <li className="edit">
                                                        <Link
                                                            to="#"
                                                            onClick={e =>
                                                                this.handleReplyEdit(
                                                                    e
                                                                )
                                                            }
                                                            data-idx={
                                                                comment.idx
                                                            }
                                                        >
                                                            <span className="icon edit" />
                                                            글 수정
                                                        </Link>
                                                    </li>
                                                    <li className="delete">
                                                        <Link
                                                            to="#"
                                                            onClick={e =>
                                                                this.handleDeleteReply(
                                                                    comment.idx
                                                                )
                                                            }
                                                        >
                                                            <span className="icon delete" />
                                                            글 삭제
                                                        </Link>
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    <li className="report">
                                                        <Link
                                                            to="#"
                                                            onClick={e =>
                                                                this.handleReportReply()
                                                            }
                                                            data-idx={
                                                                comment.idx
                                                            }
                                                        >
                                                            <span className="icon warning" />
                                                            신고
                                                        </Link>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    ) : null}
                                    <br />
                                    <p
                                        className="txt"
                                        dangerouslySetInnerHTML={{
                                            __html: comment.comment,
                                        }}
                                    />
                                </div>

                                <ul className="info-writing f-right re-reply-activity clearfix">
                                    <li className="re-reply">
                                        <Link
                                            to="#"
                                            onClick={e =>
                                                this.props.handleReplyWrite(e)
                                            }
                                            data-boardidx={this.state.board_idx}
                                            data-commentidx={comment.idx}
                                        >
                                            댓글 달기
                                        </Link>
                                    </li>
                                    <li className="like">
                                        <Link
                                            to="#"
                                            onClick={e =>
                                                this.handleReplyLike()
                                            }
                                        >
                                            좋아요
                                        </Link>
                                    </li>
                                    <li className="time">
                                        {comment.created_at}
                                    </li>
                                </ul>
                            </div>
                            {comment.sub_comments.map((sub_comment, index) => (
                                <div className="re-reply">
                                    <div className="info clearfix">
                                        <img
                                            className="user-pic"
                                            src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png"
                                            alt="프로필 - 오징어 게임"
                                        />
                                        <div className="info-writing f-right">
                                            <span className="nickname">
                                                {sub_comment.user_id}
                                            </span>

                                            <Link
                                                to="#"
                                                className="btns icon-dots"
                                                onClick={e =>
                                                    this.handleReplyEditor(
                                                        sub_comment.user_id,
                                                        'commnet_info_' +
                                                            (sub_comment.idx +
                                                                1)
                                                    )
                                                }
                                            />
                                            {this.state.isLogin ? (
                                                <ul
                                                    className={
                                                        this.state
                                                            .isOpenReplyEditor[
                                                            'commnet_info_' +
                                                                (sub_comment.idx +
                                                                    1)
                                                        ]
                                                            ? 'btns editing on'
                                                            : 'btns editing'
                                                    }
                                                >
                                                    {this.state.isMyReply ? (
                                                        <>
                                                            <li className="edit">
                                                                <Link
                                                                    to="#"
                                                                    onClick={e =>
                                                                        this.handleReplyEditor(
                                                                            comment.user_id,
                                                                            'commnet_info_' +
                                                                                (sub_comment.idx +
                                                                                    1)
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="icon edit" />
                                                                    글 수정
                                                                </Link>
                                                            </li>
                                                            <li className="delete">
                                                                <Link
                                                                    to="#"
                                                                    onClick={e =>
                                                                        this.handleDeleteReply(
                                                                            sub_comment.idx
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="icon delete" />
                                                                    글 삭제
                                                                </Link>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <li className="report">
                                                                <Link
                                                                    to="#"
                                                                    onClick={e =>
                                                                        this.handleReportReply()
                                                                    }
                                                                >
                                                                    <span className="icon warning" />
                                                                    신고
                                                                </Link>
                                                            </li>
                                                        </>
                                                    )}
                                                </ul>
                                            ) : null}

                                            <br />
                                            <p className="txt">
                                                {sub_comment.comment}
                                            </p>
                                        </div>
                                        <ul className="info-writing f-right re-reply-activity clearfix">
                                            <li className="re-reply">
                                                <Link
                                                    to="#"
                                                    onClick={
                                                        this.props
                                                            .handleWriteReply
                                                    }
                                                >
                                                    댓글 달기
                                                </Link>
                                            </li>
                                            <li className="like">
                                                <Link
                                                    to="#"
                                                    onClick={e =>
                                                        this.handleReplyLike()
                                                    }
                                                >
                                                    좋아요
                                                </Link>
                                            </li>
                                            <li className="time">5분 전</li>
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    {/* ./ 댓글 리스트 */}

                    <Link
                        className="reply-more"
                        to="#"
                        onClick={e => this.handleReplyMore()}
                    >
                        댓글 더보기
                    </Link>

                    {/*  하단 댓글 남기기 CASE  1 ~ 3 */}
                    {/*  CASE 1. 로그인 전 디폴트 유저 이미지   */}
                    {this.state.isLogin === false ? (
                        <div className="bottom-comment">
                            {/* 유저 프로필은 gif, jpg, png 모두 가능합니다 */}
                            <img
                                className="user-pic"
                                src={
                                    IMAGE_DNS +
                                    '/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png'
                                }
                                alt="로그인 전- 아이콘"
                            />
                            <input
                                className="input-comment"
                                type="text"
                                readOnly={true}
                                placeholder="댓글을 남기시려면 로그인해 주세요."
                                maxLength="500"
                                onClick={e => this.props.handleReplyWrite(e)}
                                data-boardidx={this.state.board_idx}
                            />
                            {/*   <Link to="#" className="icon camera" />
                            <Link to="#" className="icon emoji" />*/}
                        </div>
                    ) : (
                        <div className="bottom-comment">
                            {/* CASE 3. 로그인 후 : 유저 이미지 --> */}
                            {/* 유저 프로필은 gif, jpg, png 모두 가능합니다 */}
                            <img
                                className="user-pic"
                                src={
                                    IMAGE_DNS +
                                    '/Valofe_file/web/vfun-ko/images/profile/bom_cropped.png'
                                }
                                alt="프로필 - 봄이"
                            />
                            <input
                                className="input-comment"
                                type="text"
                                readOnly={true}
                                placeholder={
                                    this.state.userid + '님, 댓글을 남겨주세요.'
                                }
                                maxLength="500"
                                onClick={e => this.props.handleReplyWrite(e)}
                                data-boardidx={this.state.board_idx}
                                /*                                placeholder="댓글을 입력하세요."
                                maxLength="500"*/
                            />
                        </div>
                    )}

                    {/* CASE 2. 로그인 후 : GM 이미지 --> */}
                    {/*
                        <div className="bottom-comment">
                            <img
                                className="user-pic"
                                src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/img-gm-min.png"
                                alt="GM: 퍼니이미지"
                            />
                            <input
                                className="input-comment"
                                type="text"
                                placeholder="댓글을 입력하세요."
                                maxLength="500"
                            />
                            <Link to="#" className="icon camera" />
                            <Link to="#" className="icon emoji" />
                        </div>
                        */}
                    {/*  ./  댓글 남기기 */}
                </div>

                {/*<!-- 공통 팝업 -->*/}
                <div className="layer-wrap">
                    <div className="pop-layer need-login">
                        <Link className="btn-layer-close" to="#">
                            x
                        </Link>
                        <div className="pop-container animationOn">
                            <h2 className="skip">VFUN 메세지</h2>
                            <div className="frame">
                                <h1>
                                    <img
                                        src={
                                            IMAGE_DNS +
                                            '/Valofe_file/web/vfun-ko/images/vfun-16.png'
                                        }
                                        alt=""
                                    />
                                    VFUN 메시지
                                </h1>

                                <div className="alert">
                                    <p className="p-desc">
                                        로그인 후 이용해 주세요~!
                                    </p>
                                </div>
                                <div className="btn-wrap clearfix alert-btn text-center">
                                    <Link to="#">확인</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Reply
