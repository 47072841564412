import React from 'react'
import './PageSearch.scoped.scss'

class PageSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            matches: window.matchMedia('(min-width: 768px)').matches,
            isOpenSearch: false,
        }
    }

    handleSearch(){
        this.setState({isOpenSearch : !this.state.isOpenSearch })
        console.log(this.state.isOpenSearch)
    }

    render() {
        return (
            <>
                <div className="search">

                    {this.state.matches &&

                    <div className="open-search-bg">
                        <label htmlFor="searchPage" className="hidden"/>
                        <input
                            className="page-search"
                            id="searchPage"
                            type="text"
                            maxLength="20"
                            placeholder="제목 또는 내용 입력"
                        />
                    </div>
                    }

                    {!this.state.matches &&
                    <>
                    {this.state.isOpenSearch ?
                    <div className="open-search">

                        <div className="open-search-bg">
                            <label htmlFor="searchPage" className="hidden"/>
                            <input
                                className="page-search"
                                id="searchPage"
                                type="text"
                                maxLength="20"
                                placeholder="제목 또는 내용 입력"
                            />
                        </div>
                    </div>
                    : null}
                    </>
                    }

                    <button className= {this.state.isOpenSearch ? "page-search-btn on" : "page-search-btn"} onClick={e => this.handleSearch()}  />
                </div>
            </>
        )
    }
}

export default PageSearch
