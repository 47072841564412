import React from 'react'

import MobileHeader from '../components/common/MobileHeader'
import CombineMenu from '../components/combine_menu/CombineMenu'
import AllGamesContents from '../components/games/AllgamesContents'
import Footer from '../components/combine_menu/Footer'
import { HTML_TITLE_PREFIX } from '../utils/Config'

class AllGames extends React.Component {
    componentDidMount() {
        document.title = HTML_TITLE_PREFIX + ' - 전체 게임'
    }
    render() {
        return (
            <>
                <MobileHeader current_menu={'all_games'} />
                <CombineMenu />
                <div id="content" className="clearfix christmas">
                    <AllGamesContents />
                </div>
                <Footer />
            </>
        )
    }
}

export default AllGames
