import { toHaveAccessibleDescription } from '@testing-library/jest-dom/dist/matchers'
import React from 'react'
import { Link } from 'react-router-dom'
import { Thumbs } from 'swiper'

import '../../assets/css/Floating.scoped.scss'
import { Event } from '../Tracking'

class Floating extends React.Component {
    constructor(props) {
        super(props)
        var floating_open =
            localStorage.getItem('floating_open') === undefined ||
            localStorage.getItem('floating_open') == null
                ? false
                : localStorage.getItem('floating_open')
        floating_open =
            floating_open === 'false' || floating_open === false ? false : true

        this.state = {
            isOn: floating_open,
        }
        this.currentDate = new Date()
        this.billingEventStartDate = new Date('2022-11-25 00:00:00')
        this.billingEventEndDate = new Date('2022-11-25 23:59:59')
        this.luniaEventEndDate = new Date('2022-10-24 00:00:00')
        //this.billingEventStartDate = new Date('2022-09-30 00:00:00')
        //this.billingEventEndDate = new Date('2022-09-30 23:59:59')
    }

    floatingHandle() {
        localStorage.setItem('floating_open', !this.state.isOn)
        this.setState({ isOn: !this.state.isOn })
    }

    render() {
        return (
            <>
                {/* 플로팅 삭제: 플로팅 운영 정책이 생길 때까지 사용 중지함 */}
            </>
        )
    }
}

export default Floating
