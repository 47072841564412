import React from 'react'
/*import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper'
import Iframe from 'react-iframe'*/

import '../../../../assets/css/game/detail/HeroClass.scoped.scss'

/*SwiperCore.use([EffectFade, Navigation, Pagination])*/

class HeroClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            class_data: props.class_data,
            openYoutubePopup: false,
            youtubeURL: '',
        }
    }

    fnYtPlayClick(selUrl) {
        this.setState({
            openYoutubePopup: !this.state.openYoutubePopup,
            youtubeURL: selUrl,
        })
    }

    render() {
        return (
            <>
            </>
        )
    }
}

export default HeroClass
