import React from 'react'

import MobileHeader from '../components/common/MobileHeader'
import CombineMenu from '../components/combine_menu/CombineMenu'
import Footer from '../components/combine_menu/Footer'
import Page from '../components/community/Page'
import PopPageWrite from '../components/community/PopPageWrite'
import PopPageImg from '../components/community/PopPageImg'
import PopPageReplyWrite from '../components/community/PopPageReplyWrite'

import InfoContext from '../contexts/InfoContext'

import { needLogin } from '../utils/CommonFunc'

class Community extends React.Component {
    static contextType = InfoContext
    constructor(props, context) {
        super(props)

        this.state = {
            isLogin: context.state.is_login,
            service_code: this.props.match.params.service_code,
            isOpenPageWrite: false,
            isOpenPageImg: false,
            isOpenReplyWrite: false,
            popupImageBoardIdx: '',
            boardRefresh: false,
            matches: window.matchMedia('(min-width: 768px)').matches,
            editBoardIdx: 0,
            section: -99,
            replyBoardIdx: 0,
            replyIdx: 0,
        }
    }

    handler768(e) {
        this.setState({ matches: e.matches })
    }

    componentDidMount() {
        const handler768 = e => {
            this.setState({ matches: e.matches })
        }
        window
            .matchMedia('(min-width: 768px)')
            .addEventListener('change', e => handler768(e))
    }

    handleBoardRefresh() {
        this.setState({ boardRefresh: !this.state.boardRefresh })
    }

    handlePopPageWrite() {
        if (!this.state.isLogin) {
            needLogin()
        } else {
            this.setState({ isOpenPageWrite: !this.state.isOpenPageWrite })
        }
    }

    handlePopPageImg(e) {
        var popupImageBoardIdx = ''
        if (e.target.dataset.idx !== undefined) {
            popupImageBoardIdx = e.target.dataset.idx
            //popupImageLists = this.getCommunityImageList(e.target.dataset.idx)
        }

        this.setState({
            isOpenPageImg: !this.state.isOpenPageImg,
            popupImageBoardIdx: popupImageBoardIdx,
        })
    }

    editBoardInfo(e) {
        this.setState({
            isOpenPageWrite: !this.state.isOpenPageWrite,
            editBoardIdx: e.target.dataset.idx,
        })
    }

    cancelBoardEdit() {
        if (!this.state.isLogin) {
            needLogin()
        } else {
            this.setState({
                isOpenPageWrite: !this.state.isOpenPageWrite,
                editBoardIdx: 0,
            })
        }
    }

    handleReplyPopup(editReplyIdx, replyBoardIdx, replyIdx) {
        this.setState({
            isOpenReplyWrite: !this.state.isOpenReplyWrite,
            editReplyIdx: editReplyIdx,
            replyBoardIdx: replyBoardIdx,
            replyIdx: replyIdx,
        })
    }

    handleReplyWrite(e) {
        console.log(e.target.dataset.boardidx)
        console.log(e.target.dataset.commentidx)
        console.log(e.target.dataset.reply)
        if (!this.state.isLogin) {
            needLogin()
        } else {
            this.handleReplyPopup(
                0,
                e.target.dataset.boardidx !== undefined
                    ? e.target.dataset.boardidx
                    : 0,
                e.target.dataset.commentidx !== undefined
                    ? e.target.dataset.commentidx
                    : 0
            )
        }
    }

    handleReplyEdit(e) {
        this.setState({
            isOpenReplyWrite: !this.state.isOpenReplyWrite,
            replyIdx: e.target.dataset.idx,
        })
    }

    selectBoardSection(e) {
        this.setState({ section: e.target.dataset.section })
    }

    render() {
        return (
            <>
                <div id="community">
                    <MobileHeader />
                    <CombineMenu />
                    <div id="content" className="clearfix">
                        <Page
                            handlePopPageWrite={e => this.handlePopPageWrite()}
                            handlePopPageImg={e => this.handlePopPageImg(e)}
                            service_code={this.state.service_code}
                            boardRefresh={this.state.boardRefresh}
                            editBoardInfo={e => this.editBoardInfo(e)}
                            selectBoardSection={e => this.selectBoardSection(e)}
                            section={this.state.section}
                            handleReplyWrite={e => this.handleReplyWrite(e)}
                            handleBoardRefresh={e => this.handleBoardRefresh()}
                            handleReplyEdit={e => this.handleReplyEdit(e)}
                        />
                    </div>
                    <Footer />
                </div>
                <PopPageWrite
                    handlePopPageWrite={e => this.handlePopPageWrite()}
                    isOpenPageWrite={this.state.isOpenPageWrite}
                    service_code={this.state.service_code}
                    handleBoardRefresh={e => this.handleBoardRefresh()}
                    editBoardIdx={this.state.editBoardIdx}
                    cancelBoardEdit={e => this.cancelBoardEdit()}
                    section={this.state.section}
                />
                <PopPageImg
                    isOpenPageImg={this.state.isOpenPageImg}
                    handlePopPageImg={e => this.handlePopPageImg(e)}
                    popupImageBoardIdx={this.state.popupImageBoardIdx}
                />
                <PopPageReplyWrite
                    isOpenReplyWrite={this.state.isOpenReplyWrite}
                    handleReplyWrite={e => this.handleReplyWrite(e)}
                    replyBoardIdx={this.state.replyBoardIdx}
                    handleBoardRefresh={e => this.handleBoardRefresh()}
                    handleReplyPopup={e => this.handleReplyPopup()}
                    replyIdx={this.state.replyIdx}
                />
            </>
        )
    }
}
export default Community
