export const IMAGE_DNS = 'https://file.valofe.com'
export const PLATFORM_DNS = 'https://vfun.valofe.com'
export const VERIFY_KEY = 'ehG.NSdz.qoffhvm!@'
export const BILLING_CHARGE_URL =
    process.env.REACT_APP_BILLING_DNS + '/mycash/charge_info.asp'
/*export const SIGN_IN_URL =
    process.env.REACT_APP_MEMBER_DNS +
    '/login/login.asp?ret=' +
    window.location.href*/
export const SIGN_IN_URL = process.env.REACT_APP_MEMBER_DNS ? process.env.REACT_APP_MEMBER_DNS + '/login/login.asp?ret=' + (typeof window !== 'undefined' ? window.location.href : '') : '';

export const SIGN_UP_URL =
    process.env.REACT_APP_MEMBER_DNS + '/join/member_join_pre.asp'
export const FIND_ID_URL =
    process.env.REACT_APP_MEMBER_DNS + '/find/find_id.asp'
export const MY_INFO_URL =
    process.env.REACT_APP_MEMBER_DNS + '/mypage/mypage_auth.asp'
export const TERMS_URL =
    process.env.REACT_APP_MEMBER_DNS + '/common/center/useterms.html'
export const PRIVACY_URL =
    process.env.REACT_APP_MEMBER_DNS + '/common/center/unitedprivacy.html'
export const SERVICE_CODE = 'vfun-ko'
export const CHANNELING_TYPE = 0
export const HTML_TITLE_PREFIX = 'VFUN'
export const VALOFE_COMPANY_URL = 'http://www.valofe.com/company/intro'
