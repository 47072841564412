import React from 'react'
import '../../../../assets/css/game/detail/FishislandkoClass.scoped.scss'

class FishislandkoClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            class_data: props.class_data,
            openYoutubePopup: false,
            youtubeURL: '',
        }
    }

    fnYtPlayClick(selUrl) {
        this.setState({
            openYoutubePopup: !this.state.openYoutubePopup,
            youtubeURL: selUrl,
        })
    }

    render() {
        return (
            <>
            </>
        )
    }
}

export default FishislandkoClass
