import { createContext, useState } from 'react'

const InfoContext = createContext({
    state: {
        is_login: false,
        user_nickname: '',
        user_id: 'none',
        is_mobile_header_menu_open: false,
        is_combine_menu_open: false,
        ssoinfo: 'none',
        jumin: 'none',
        user_profile_key: '',
    },
    actions: {
        setIsLogin: () => {},
        setUserNickName: () => {},
        setUserID: () => {},
        setIsMobileHeaderMenuOpen: () => {},
        setIsCombineMenuOpen: () => {},
        setSSOInfo: () => {},
        setJuminInfo: () => {},
        setUserProfileKey: () => {},
    },
})

const InfoProvider = ({ children }) => {
    const [is_login, setIsLogin] = useState(false)
    const [user_nickname, setUserNickName] = useState(false)
    const [user_id, setUserID] = useState(false)
    const [is_mobile_header_menu_open, setIsMobileHeaderMenuOpen] =
        useState(false)
    const [is_combine_menu_open, setIsCombineMenuOpen] = useState(false)
    const [ssoinfo, setSSOInfo] = useState(false)
    const [jumin, setJuminInfo] = useState(false)
    const [user_profile_key, setUserProfileKey] = useState(false)

    const value = {
        state: {
            is_login,
            user_nickname,
            user_id,
            is_mobile_header_menu_open,
            is_combine_menu_open,
            ssoinfo,
            jumin,
            user_profile_key,
        },
        actions: {
            setIsLogin,
            setUserNickName,
            setUserID,
            setIsMobileHeaderMenuOpen,
            setIsCombineMenuOpen,
            setSSOInfo,
            setJuminInfo,
            setUserProfileKey,
        },
    }

    return <InfoContext.Provider value={value}>{children}</InfoContext.Provider>
}

const { Consumer: InfoConsumer } = InfoContext
export { InfoProvider, InfoConsumer }
export default InfoContext
