import React, { Component } from 'react'

import NotfoundContents from '../../components/errors/NotfoundContents'
import { HTML_TITLE_PREFIX } from '../../utils/Config'


class Notfound extends Component {
    componentDidMount() {
        document.title = HTML_TITLE_PREFIX + ' - 404 Not Found';

    }
    render() {
        return (
            <NotfoundContents />
        )
    }
}

export default Notfound