import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import './Gallery.scoped.scss'

SwiperCore.use([Autoplay, Navigation])



class Gallery extends React.Component {
    constructor(props) {
        super(props)
        this.handleResize = this.handleResize.bind(this)
        this.state = {
            gallery_data: props.gallery_data,
            openGalleryPopup: false,
            service_code: props.service_code,
            slideNum : 0,
            gallery_slider: [],
            innerWidth: window.innerWidth
        }
        window.addEventListener('resize', e => this.handleResize())
    }

    handleResize() {
        this.setState({ innerWidth: window.innerWidth })
    }
    changeGalleryPopup(e) {

        var gallery_slider = [];
        var start = parseInt(e.target.dataset.slidenum)+1
        var end = start + 1
        console.log('gallery start : '+start)
        console.log('gallery end: ' + end)
        if(end > this.state.gallery_data.length){
            start = 0;
            end = 1;

        }
        for(var i=0;i<this.state.gallery_data.length;i++){
            gallery_slider.push(this.state.gallery_data.slice(start, end)[0])
            start = start + 1;
            end = start + 1;
            if(end > this.state.gallery_data.length){
                start = 0;
                end = 1;
            }
        }

        this.setState({
            openGalleryPopup: !this.state.openGalleryPopup,
            slideNum: e.target.dataset.slidenum !== undefined ? e.target.dataset.slidenum : 0,
            gallery_slider: e.target.dataset.slidenum !== undefined ? gallery_slider : []
        })

    }

    render() {
        return (
            <>
                <section id="gallery">
                    <div className="row-w">

                        <h3>갤러리</h3>
                        <div className="gallery">
                            <Swiper

                                slidesPerGroup={1}
                                spaceBetween={30}
                                loop={true}
                                navigation={true}
                                slidesPerView={
                                    this.state.innerWidth > 640
                                        ? 2
                                        : 1
                                }

                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}

                                id="gallerySlider"
                            >
                                {this.state.gallery_data.map(
                                    (gallery, index) => (
                                        <SwiperSlide  key={ index } >
                                            <Link
                                                to="#"
                                                onClick={e => this.changeGalleryPopup(e)}
                                            >
                                                <div className="item image">
                                                    <img
                                                        src={gallery.image_url}
                                                        alt={gallery.title}
                                                        data-slidenum={index}
                                                    />
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    )
                                )}
                            </Swiper>
                        </div>
                    </div>
                </section>
                {/*<!-- 팝업 3. 갤러리 팝업 -->*/}
                <div className="layer-wrap"
                     id="galleryList"
                    style={{ display: this.state.openGalleryPopup ? 'block' : 'none'}}
                >
                    <div className="pop-layer">
                        <div className="pop-container">
                            <Link
                                to="#"
                                className="close"
                                id="closeGalleryList"
                                onClick={e=>this.changeGalleryPopup(e)}
                            >
                                close
                            </Link>
                            <h2 className="skip">Gallery</h2>
                            <div className="frame">
                                <div className="wrap-galleryList card">
                                    <Swiper
                                        id="galleryBigSlide"
                                        slidesPerView={1}
                                        loop={true}
                                        navigation={true}
                                    >
                                        {this.state.gallery_slider.map(
                                            (gallery, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className="wrap-media type2 card item image">
                                                        <div className="media">
                                                            <div className="bg-gradient" />
                                                            <img className="art-img"
                                                                 src={gallery.image_url}
                                                                 alt={gallery.alt}
                                                            />
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        )}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow galleryList" />
                </div>
                {/*<!-- ./ LAYER-WRAP -->*/}
            </>
        )
    }
}

export default Gallery
