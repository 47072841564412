import React from 'react'
import { Link } from 'react-router-dom'
import { redirectURL } from '../../../utils/CommonFunc'

class Download extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            download_data: props.download_data,
        }
    }
    render() {
        return (
            <>
                <section id="download">
                    <div className="row-w clearfix">
                        <div className="pull-left">
                            <h3>SNS 채널</h3>
                            <div className="list-wrapper">
                                <div className="list sns clearfix">
                                    {this.state.download_data.sns.map(
                                        (sns, index) => (
                                            <Link
                                                key={index}
                                                className="icon-a"
                                                to="#"
                                                onClick={e =>
                                                    redirectURL(
                                                        sns.url,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                <span
                                                    className={
                                                        'icon ' + sns.name
                                                    }
                                                />
                                            </Link>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="pull-right">
                            <h3>게임 다운로드</h3>
                            <div className="list-wrapper open">
                                {/*<!-- add class 'comingsoon'-->*/}
                                <div className="list download clearfix">
                                    {this.state.download_data.store_url.map(
                                        (store, index) => (
                                            <Link
                                                key={index}
                                                className="icon-a"
                                                to="#"
                                                onClick={e =>
                                                    redirectURL(
                                                        store.url,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                <span
                                                    className={
                                                        'icon ' + store.name
                                                    }
                                                />
                                            </Link>
                                        )
                                    )}

                                    {/*  <Link to="javascript:void(0);" target="_blank"><span className="icon one" /></Link >
                            <Link to="javascript:void(0);" target="_blank"><span className="icon kakao" /></Link >
                            <Link to="javascript:void(0);" target="_blank"><span className="icon band" /></Link >
                            <Link to="javascript:void(0);" target="_blank"><span className="icon pc" /></Link >
                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Download
