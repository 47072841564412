import React from 'react'

import { Link } from 'react-router-dom'

import AllGamesTable from './AllGameTable'
import AllGamesList from './AllGamesList'

class MainAllGames extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            view_type: 'table',
        }
    }

    handleAllgameViewType(view_type) {
        this.setState({ view_type: view_type })
    }

    render() {
        return (
            <>
            <section className="main-cont all-game" id="allGame">
                <div className="row-w">
                    <h1>
                        전체 게임
                        <Link to="#"
                              className={
                                'btn-type _1 ' +
                                (this.state.view_type === 'table' ? 'on' : '')
                                }
                                onClick={(e) => this.handleAllgameViewType('table')}
                        />
                        <Link
                            to="#"
                            className={
                                'btn-type _2 ' +
                                (this.state.view_type === 'list' ? 'on' : '')
                            }
                            onClick={(e) => this.handleAllgameViewType('list')}
                        />
                    </h1>
                    {this.state.view_type === 'table' ? (
                        <AllGamesTable />
                    ) : (
                        <AllGamesList />
                    )}

                </div>
            </section>
            </>
        )
    }
}

export default MainAllGames
