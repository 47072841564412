import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { redirectURL } from '../../utils/CommonFunc'
import { IMAGE_DNS } from '../../utils/Config'


import InfoContext from '../../contexts/InfoContext'

import Spinner from '../../components/common/Spinner'

import '../../assets/css/download/DownloadContents.scoped.scss'
import BottomTheme from '../common/theme/BottomTheme'

class DownloadContents extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        this.state = {
            isLoading: true,
            ssoinfo:
                context.state.ssoinfo === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.user_id === false
                    ? 'none'
                    : context.state.user_id,
            jumin: context.state.jumin === false ? 'none' : context.state.jumin,
            PCGameList: '',
            MobileGameList: '',
        }
    }

    componentDidMount() {
        this.getDownloadList()
    }

    getDownloadList() {
        let data = {}
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS + '/v1/Vfun/getAllGameInfo',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                if (parseInt(res.data.result_code) === 1) {
                    var PCGameList = []
                    var MobileGameList = []
                    var pc_key = 0
                    var mobile_key = 0
                    res.data.data.forEach(game => {
                        if (game.platform.includes('PC')) {
                            PCGameList[pc_key] = game
                            ++pc_key
                        }
                        if (game.platform.includes('모바일')) {
                            MobileGameList[mobile_key] = game
                            ++mobile_key
                        }
                    })
                }
                this.setState({
                    PCGameList: PCGameList,
                    MobileGameList: MobileGameList,
                    isLoading: false,
                })
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }

    render() {
        return (
            <>
                <div className="downloadContents">
                    <section className="list-cont " id="downloadContents">
                        <div className="row-w">
                            <h1>VFUN 게임&nbsp;</h1>
                            {this.state.isLoading === true ? <Spinner /> : null}
                            <div className="all-list clearfix">
                                <div className="pull-left half pc-list">
                                    <h2>PC 게임</h2>
                                    <ul>
                                        {this.state.isLoading === true
                                            ? null
                                            : this.state.PCGameList.map(
                                                  (download, index) => (
                                                      <li key={index}>
                                                          <div className="wrap-game">
                                                              <img
                                                                  src={
                                                                      IMAGE_DNS +
                                                                      '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                                      download.service_code +
                                                                      '-icon-min.png'
                                                                  }
                                                                  alt={
                                                                      download.title
                                                                  }
                                                              />
                                                              &nbsp;{download.title}
                                                          </div>
                                                          <div className="wrap-download clearfix">
                                                              <div
                                                                  className="version ellipsis"
                                                                  title="[5.01GB] ver.46.08.60 2019-01-01"
                                                              >
                                                                  [5.01GB]
                                                                  ver.46.08.60
                                                                  2019-01-01
                                                              </div>
                                                              <div className="pc-down">
                                                                  <span className="txt">
                                                                      <Link
                                                                          to="#"
                                                                          onClick={e =>
                                                                              redirectURL(
                                                                                  download.download_url ===
                                                                                      undefined
                                                                                      ? ''
                                                                                      : download
                                                                                            .download_url[1] ===
                                                                                        undefined
                                                                                      ? ''
                                                                                      : download
                                                                                            .download_url[1],
                                                                                  '_blank'
                                                                              )
                                                                          }
                                                                      >
                                                                          <span className="fas fa-desktop" />
                                                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                                                          <span className="fa fa-download" />
                                                                      </Link>
                                                                  </span>
                                                              </div>
                                                              <div />
                                                          </div>
                                                      </li>
                                                  )
                                              )}
                                    </ul>
                                </div>
                                <div className="pull-right half mb-list">
                                    <h2>모바일&nbsp;게임</h2>
                                    <ul>
                                        {this.state.isLoading === true
                                            ? null
                                            : this.state.MobileGameList.map(
                                                  (download, index) => (
                                                      <li>
                                                          <div className="wrap-game">
                                                              <img
                                                                  src={
                                                                      IMAGE_DNS +
                                                                      '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                                      download.service_code +
                                                                      '-icon-min.png'
                                                                  }
                                                                  alt={
                                                                      download.title
                                                                  }
                                                              />
                                                              &nbsp;{download.title}
                                                          </div>
                                                          <div className="wrap-download clearfix">
                                                              <span className="txt" />
                                                              &nbsp;
                                                              <ul className="mb-down">
                                                                  {download
                                                                      .download_url[2] ===
                                                                  undefined ? null : (
                                                                      <li>
                                                                          <Link
                                                                              to="#"
                                                                              onClick={e =>
                                                                                  redirectURL(
                                                                                      download
                                                                                          .download_url[2],
                                                                                      '_blank'
                                                                                  )
                                                                              }
                                                                              className="mb-icon"
                                                                          >
                                                                              <img
                                                                                  src={
                                                                                      IMAGE_DNS +
                                                                                      '/Valofe_file/web/vfun-ko/images/common/icon/icon-aos-min.png'
                                                                                  }
                                                                                  title="Android "
                                                                                  alt=""
                                                                              />
                                                                          </Link>
                                                                      </li>
                                                                  )}
                                                                  {download
                                                                      .download_url[3] ===
                                                                  undefined ? null : (
                                                                      <li>
                                                                          <Link
                                                                              to="#"
                                                                              onClick={e =>
                                                                                  redirectURL(
                                                                                      download
                                                                                          .download_url[3],
                                                                                      '_blank'
                                                                                  )
                                                                              }
                                                                              className="mb-icon"
                                                                          >
                                                                              <img
                                                                                  src={
                                                                                      IMAGE_DNS +
                                                                                      '/Valofe_file/web/vfun-ko/images/common/icon/icon-ios-min.png'
                                                                                  }
                                                                                  title="iOS "
                                                                                  alt=""
                                                                              />
                                                                          </Link>
                                                                      </li>
                                                                  )}
                                                                  {download
                                                                      .download_url[4] ===
                                                                  undefined ? null : (
                                                                      <li>
                                                                          <Link
                                                                              to="#"
                                                                              onClick={e =>
                                                                                  redirectURL(
                                                                                      download
                                                                                          .download_url[4],
                                                                                      '_blank'
                                                                                  )
                                                                              }
                                                                              className="mb-icon"
                                                                          >
                                                                              <img
                                                                                  src={
                                                                                      IMAGE_DNS +
                                                                                      '/Valofe_file/web/vfun-ko/images/common/icon/icon-one-min.png'
                                                                                  }
                                                                                  title="Onestore "
                                                                                  alt=""
                                                                              />
                                                                          </Link>
                                                                      </li>
                                                                  )}

                                                                  {download
                                                                      .download_url[5] ===
                                                                  undefined ? null : (
                                                                      <li>
                                                                          <Link
                                                                              to="#"
                                                                              onClick={e =>
                                                                                  redirectURL(
                                                                                      download
                                                                                          .download_url[4],
                                                                                      '_blank'
                                                                                  )
                                                                              }
                                                                              className="mb-icon"
                                                                          >
                                                                              <img
                                                                                  src={
                                                                                      IMAGE_DNS +
                                                                                      '/Valofe_file/web/vfun-ko/images/common/icon/icon-gxy-min.png'
                                                                                  }
                                                                                  title="Galaxy Store "
                                                                                  alt=""
                                                                              />
                                                                          </Link>
                                                                      </li>
                                                                  )}

                                                                  {download
                                                                      .download_url[6] ===
                                                                  undefined ? null : (
                                                                      <li>
                                                                          <Link
                                                                              to="#"
                                                                              onClick={e =>
                                                                                  redirectURL(
                                                                                      download
                                                                                          .download_url[6],
                                                                                      '_blank'
                                                                                  )
                                                                              }
                                                                              className="mb-icon"
                                                                          >
                                                                              <img
                                                                                  src={
                                                                                      IMAGE_DNS +
                                                                                      '/Valofe_file/web/vfun-ko/images/common/icon/icon-kko-min.png'
                                                                                  }
                                                                                  title="Google Kakao"
                                                                                  alt=""
                                                                              />
                                                                          </Link>
                                                                      </li>
                                                                  )}
                                                              </ul>
                                                          </div>
                                                      </li>
                                                  )
                                              )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <BottomTheme />
                    </section>
                </div>
            </>
        )
    }
}

export default DownloadContents
