import React from 'react'
import { Link } from 'react-router-dom'

import './PageMobileActivity.scoped.scss'
import { IMAGE_DNS } from '../../../utils/Config'

class PageMobileActivity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpenSubMenu: false,
            service_code: props.service_code,
        }
    }



    render() {
        return (
            <>
                <div className="pageMobileActivity">
                    <ul>
                        <li>
                            <Link to="#">

                                <span className="icon white article">
                                    <img
                                        src={
                                        IMAGE_DNS +
                                        '/Valofe_file/web/vfun-ko/images/common/icon/icon-article.svg'
                                        }
                                        alt="아이콘 - 게시글 수"
                                    />
                                </span>
                                <span className="txt">10000</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <span className="icon white like">
                                    <img
                                        src={
                                        IMAGE_DNS +
                                        '/Valofe_file/web/vfun-ko/images/common/icon/icon-thumb.svg'
                                        }
                                        alt="아이콘 - 좋아요 수"
                                    />
                                </span>
                                <span className="txt">10245</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <span className="icon purple-gradient event">
                                    <img
                                        src={
                                        IMAGE_DNS +
                                        '/Valofe_file/web/vfun-ko/images/common/icon/icon-gamepad.svg'
                                        }
                                        alt="아이콘 - 사전예약"
                                    />
                                </span>
                                <span className="txt">사전예약</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <span className="icon purple-gradient home">
                                    <img
                                        src={
                                            IMAGE_DNS +
                                            '/Valofe_file/web/vfun-ko/images/common/icon/icon-home.svg'
                                        }
                                        alt="아이콘 - 홈페이지"
                                    />
                                </span>
                                <span className="txt">홈페이지</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </>
        )
    }
}

export default PageMobileActivity
