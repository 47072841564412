import React from 'react'
import axios from 'axios'

import { Link } from 'react-router-dom'
import './PageCategory.scoped.scss'
import PageSearch from './PageSearch'
import InfoContext from '../../../contexts/InfoContext'

class PageCategory extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        this.state = {
            ssoinfo:
                context.state.is_login === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.is_login === false
                    ? 'none'
                    : context.state.user_id,
            jumin:
                context.state.is_login === false ? 'none' : context.state.jumin,
            matches: window.matchMedia('(min-width: 768px)').matches,
            service_code: props.service_code,
            section: -99,
            isLoading: true,
            sectionList: [],
        }
    }

    componentDidMount() {
        let data = {
            service_code: this.state.service_code,
        }
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS +
                    '/v1/Vfun/getCommunityBoardSection',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                if (parseInt(res.data.result_code) === 1) {
                    this.setState({
                        sectionList: res.data.data,
                        isLoading: false,
                    })
                }
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }

    handleMenu(e, menuNum) {
        this.setState({ section: menuNum })
        this.props.selectBoardSection(e)
    }

    render() {
        return (
            <>
                <nav className="nav clearfix">
                    <ul className="clearfix">
                        <li
                            id={'cate_1'}
                            className={
                                this.state.section === -99
                                    ? 'lnb_cate on'
                                    : 'lnb_cate'
                            }
                        >
                            <Link
                                to="#"
                                onClick={e => this.handleMenu(e, -99)}
                                data-section={-99}
                            >
                                <i className="icon board" />
                                &nbsp;전체
                            </Link>
                        </li>
                        {this.state.isLoading === false
                            ? this.state.sectionList.map((section, index) => (
                                  <li
                                      id={'cate_' + (index + 2)}
                                      className={
                                          this.state.section === section.section
                                              ? 'lnb_cate on'
                                              : 'lnb_cate'
                                      }
                                      key={index}
                                  >
                                      <Link
                                          to="#"
                                          onClick={e =>
                                              this.handleMenu(
                                                  e,
                                                  section.section
                                              )
                                          }
                                          data-section={section.section}
                                      >
                                          <i className="icon board" />
                                          &nbsp;{section.title}
                                      </Link>
                                  </li>
                              ))
                            : null}
                    </ul>
                    {!this.state.matches && <PageSearch />}
                </nav>
            </>
        )
    }
}

export default PageCategory
