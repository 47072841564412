import React from 'react'
import { Link } from 'react-router-dom'

class WorldView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            service_code: props.service_code,
            world_view: props.world_view,
            more_flag: false,
        }
        window.addEventListener('resize', e => this.handleResize())
    }

    worldViewMore() {
        /* window.document.getElementById('worldViewContainer').style.overflow =
            'visible'*/
        window.document.getElementById('worldViewContainer').style.maxHeight =
            'none'
        this.setState({ more_flag: false })
    }

    handleResize() {
        var scrollHeight = window.document.getElementById('worldViewContainer')
            .scrollHeight
        //console.log(scrollHeight)
        if (scrollHeight >= 172) {
            this.setState({ more_flag: true })
        }
    }

    componentDidMount() {
        this.handleResize()
    }

    render() {
        return (
            <>
                <div className="world-view">
                    <h4>{this.state.service_code === 'icarusmko' ? '세계관' : '상세 설명'} </h4>
                    <p
                        id="worldViewContainer"
                        dangerouslySetInnerHTML={{
                            __html: this.state.world_view,
                        }}
                    />

                    {/*<!--                    <div className="wrap-btn">
                        <Link to="javascript:void(0);" className="btn-more" onClick="fnShowWorldView();"></Link >
                    </div>-->*/}
                    {this.state.more_flag === true ? (
                        <div className="row-w">
                            <div className="wrap-btn">
                                <Link
                                    to="#"
                                    className="more"
                                    id="more_load_btn"
                                    onClick={e => this.worldViewMore()}
                                >
                                    <span className="fa fa-plus-circle" />
                                    &nbsp;더보기
                                </Link>
                            </div>
                        </div>
                    ) : null}
                </div>
                {/*<!--// 게임 특징 -->*/}
            </>
        )
    }
}

export default WorldView
