export function redirectURL(url, target) {
    window.open(url, target)
}

export function checkLogin(is_login) {
    if (!is_login) {
        return this.needLogin()
    }
    return true
}

export function needLogin() {
    alert('로그인이 필요한 서비스 입니다.')
    return false
}

export function viewSplitLine(content) {
    const urlRegex = /(https?:\/\/[^\s]+)/g

    // 링크를 감지하여 a 태그로 감싸기
    const replace = content => {
        const convertContent = content.replace(urlRegex, function(url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>'
        })

        const htmlArr = []
        convertContent.split('\n').forEach(function(text) {
            const textHtml = '<p>' + text + '</p>'
            htmlArr.push(textHtml)
        })

        return { __html: htmlArr.join('') }
    }

    return (
        <div>
            <div dangerouslySetInnerHTML={replace(content)} />
        </div>
    )
}
