import React from 'react'

class O2jamClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            class_data: props.class_data,
        }
    }
    render() {
        return ( false )
    }
}

export default O2jamClass
