import React from 'react'


class System extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            system_data: props.system_data,
        }
    }
    render() {
        return (
            <>
                {/*<!-- 시스템 -->*/}
                <section id="system" className="">
                    <div className="row-w">
                        <h3>게임 시스템</h3>
                        <div className="grid-wrapper">
                            <div className="box a">
                                <h4>{this.state.system_data[0].title}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.system_data[0]
                                            .sub_title,
                                    }}
                                />
                            </div>
                            <div className="box b">
                                <h4>{this.state.system_data[1].title}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.system_data[1]
                                            .sub_title,
                                    }}
                                />
                            </div>
                            <div className="box c">
                                <h4>{this.state.system_data[2].title}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.system_data[2]
                                            .sub_title,
                                    }}
                                />
                            </div>
                            <div className="box d">
                                <h4>{this.state.system_data[3].title}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.system_data[3]
                                            .sub_title,
                                    }}
                                />
                            </div>

                            {this.state.system_data[4] ?
                                <div className="box e">
                                    <h4>{this.state.system_data[4].title}</h4>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.system_data[4]
                                                .sub_title,
                                        }}
                                    />
                                </div>
                            : null }

                        </div>
                    </div>
                </section>
                {/*<!-- ./ 시스템 -->*/}
            </>
        )
    }
}

export default System
