import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../detail/Coupon.scoped.scss'
import { redirectURL } from '../../../utils/CommonFunc'
//import { BILLING_CHARGE_URL } from '../../../utils/Config'
import { Link } from 'react-router-dom'


class Coupon extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            coupon_data: props.coupon_data,
        }
    }
    onClick = ({target: {innerHTML}}) => {
        const copoun_text = `${innerHTML}`;
        const textarea = document.createElement("textarea");
        textarea.value = `${innerHTML}`;
        textarea.style.top = 0;
        textarea.style.left = 0;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        // focus() -> 사파리 브라우저 서포팅
        textarea.focus();
        // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
        textarea.select();
        // 흐름 4.
        document.execCommand("copy");
        // 흐름 5.
        document.body.removeChild(textarea);
        const code_copied = '쿠폰코드가 복사되었습니다. ';
        alert(`${code_copied }` `${copoun_text}`);

    };

    render() {
        return (
            <>
            <section className="mt100 clearfix" id="coupon">
                <div className="row-w">
                    <div>
                        <h2>쿠폰 코드</h2>
                        <h3>
                            <img src="https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/coupon-min.png" alt="COUPON" />
                            <img src="https://file.valofe.com/Valofe_file/web/vfun/images/event/icarusm_gl/pre-register/grand_open/twinkle.png" alt="" />
                            <img src="https://file.valofe.com/Valofe_file/web/vfun/images/event/icarusm_gl/pre-register/grand_open/twinkle.png" alt="" />
                        </h3>
                        <div className="features">
                            <h4><span className="txtGrad">쿠폰 코드를 클릭</span> 하시면 코드가 복사됩니다.<br/> 쿠폰 복사 후, <span className="txtGrad">쿠폰 입력하기</span>를 클릭하여 쿠폰 코드를 입력하시고 보상을 받으세요~!</h4>
                            <div className="coupon-list clearfix">
                                {this.state.coupon_data.map(
                                    (coupon, index) => (
                                        <div className="coupon1" key={index}>
                                            <p className="c-type">{index+1}</p>
                                            <p className="c-name" >{coupon.coupon_message}</p>
                                            <CopyToClipboard
                                                onCopy={this.onCopy}
                                                options={{message: '쿠폰 코드가 복사되었습니다.'}}
                                                text={this.state.value}>
                                                 <div className="c-num"  title="클릭하시면 복사가 완료됩니다." onMouseOver={this.onMouseOver} onClick={this.onClick}>{coupon.coupon_num}</div>
                                                {/*ref={textCoupon}*/}
                                            </CopyToClipboard>
                                        </div>
                                    )
                                )}
                            </div>
                            <Link
                                className="btn-download enter-coupon bgAniGrad"
                                to="#"
                                onClick={e =>
                                    redirectURL(
                                        "https://kr-druwa-coupon.valofe.com/coupon/index.html",
                                        '_blank'
                                    )
                                  }
                               >
                                쿠폰 입력하기
                            </Link>
                        </div>
                     </div>
                </div>
            </section>
            </>
        )
    }
}

export default Coupon
