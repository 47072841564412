import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Spinner from '../common/Spinner'

import { IMAGE_DNS } from '../../utils/Config'
import { redirectURL } from '../../utils/CommonFunc'
import InfoContext from '../../contexts/InfoContext'

/*import classNames from 'classnames/bind'
import fontA from '../../assets/css/fontawesome5_3_1.css'
const fA = classNames.bind(fontA)*/

class AllGamesTable extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        this.state = {
            isLoading: true,
            ssoinfo:
                context.state.ssoinfo === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.user_id === false
                    ? 'none'
                    : context.state.user_id,
            jumin: context.state.jumin === false ? 'none' : context.state.jumin,
            PCGameList: '',
            MobileGameList: '',
        }
    }

    componentDidMount() {
        this.getAllGameList()
    }

    getAllGameList() {
        let data = {}
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS + '/v1/Vfun/getAllGameInfo',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                if (parseInt(res.data.result_code) === 1) {
                    var PCGameList = []
                    var MobileGameList = []
                    var pc_key = 0
                    var mobile_key = 0
                    res.data.data.map((game, index) => {
                        if (game.platform.includes('PC')) {
                            PCGameList[pc_key] = game
                            ++pc_key
                        }
                        if (game.platform.includes('MOBILE')) {
                            MobileGameList[mobile_key] = game
                            ++mobile_key
                        }
                    })
                }
                //console.log(PCGameList)
                //console.log(MobileGameList)
                this.setState({
                    PCGameList: PCGameList,
                    MobileGameList: MobileGameList,
                    isLoading: false,
                })
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }

    render() {
        return (
            <div className="all-list _1 on clearfix">
                {this.state.isLoading === true ? <Spinner /> : null}
                <div className="pc-list">
                    <h2>PC 게임</h2>
                    <ul className="clearfix">
                        {this.state.isLoading === true
                            ? null
                            : this.state.PCGameList.map((game, index) => (
                                  <li key={index} className="">
                                      <div className="normal clearfix">
                                          <div className="img-area">
                                              <img
                                                  src={
                                                      IMAGE_DNS +
                                                      '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                      game.service_code.replace(
                                                          '-',
                                                          ''
                                                      ) +
                                                      '-big-thumb' +
                                                      '-min.jpg'
                                                  }
                                                  alt={game.title}
                                              />
                                              <span className="logo">
                                                  <img
                                                      src={
                                                          IMAGE_DNS +
                                                          '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                          game.service_code.replace(
                                                              '-',
                                                              ''
                                                          ) +
                                                          '-logo' +
                                                          '-min.png'
                                                      }
                                                      alt={game.title}
                                                  />
                                              </span>
                                          </div>
                                          <div className="tit">
                                              <dl>
                                                  <dt>{game.title}</dt>
                                                  <dd>
                                                      <span className="fas fa-desktop" />
                                                      &nbsp;&nbsp;{game.genre}
                                                  </dd>
                                              </dl>
                                          </div>
                                      </div>
                                      <div className="hover clearfix">
                                          <h3>{game.title}</h3>
                                          <p><span className="fas fa-desktop" /> {game.genre}</p>
                                          <Link
                                              to="#"
                                              className="event"
                                              onClick={() =>
                                                  redirectURL(game.url, '_self')
                                              }
                                          >
                                              Go to play
                                          </Link>
                                      </div>
                                  </li>
                              ))}
                    </ul>
                </div>

                <div className="mobile-list">
                    <h2>모바일 게임</h2>
                    <ul className="clearfix">
                        {this.state.isLoading === true
                            ? null
                            : this.state.MobileGameList.map((game, index) => (
                                  <li key={index} className="update">
                                      <div className="default clearfix">
                                          <div className="img-area">
                                              <img
                                                  src={
                                                      IMAGE_DNS +
                                                      '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                      game.service_code.replace(
                                                          '-',
                                                          ''
                                                      ) +
                                                      '-small-thumb' +
                                                      '-min.jpg'
                                                  }
                                                  alt={game.title}
                                              />
                                          </div>
                                          <div className="desc">
                                              <h3>{game.title}</h3>
                                              <p>
                                                  <span className="fab fa-android" />
                                                  &nbsp;
                                                  <span className="fab fa-apple" />
                                                  &nbsp; {game.genre}
                                              </p>
                                          </div>
                                      </div>
                                      <div className="hover-desc clearfix">
                                          <h3>{game.title}</h3>
                                          <p>
                                              <span className="fab fa-android" />
                                              &nbsp;
                                              <span className="fab fa-apple" />
                                              &nbsp; {game.genre}
                                          </p>
                                          <div className="btn-mb-download download-multi one">
                                              <div className="a-wrap clearfix">
                                                  {game.platform_detail.indexOf(
                                                      '2'
                                                  ) !== -1 &&
                                                  game.platform_detail !==
                                                      undefined ? (
                                                      <Link
                                                          to="#"
                                                          className="mb-icon"
                                                          onClick={e =>
                                                              redirectURL(
                                                                  game
                                                                      .download[2],
                                                                  '_blank'
                                                              )
                                                          }
                                                      >
                                                          <img
                                                              src={
                                                                  IMAGE_DNS +
                                                                  '/Valofe_file/web/vfun-ko/images/common/icon/icon-aos-min.png'
                                                              }
                                                              alt="Android Download"
                                                              name="icon-aos"
                                                          />
                                                      </Link>
                                                  ) : null}
                                                  {game.platform_detail.indexOf(
                                                      '3'
                                                  ) !== -1 &&
                                                  game.platform_detail !==
                                                      undefined ? (
                                                      <Link
                                                          to="#"
                                                          className="mb-icon"
                                                          onClick={e =>
                                                              redirectURL(
                                                                  game
                                                                      .download[3],
                                                                  '_blank'
                                                              )
                                                          }
                                                      >
                                                          <img
                                                              src={
                                                                  IMAGE_DNS +
                                                                  '/Valofe_file/web/vfun-ko/images/common/icon/icon-ios-min.png'
                                                              }
                                                              alt="iOS Download"
                                                              name="icon-ios"
                                                          />
                                                      </Link>
                                                  ) : null}
                                                  {game.platform_detail.indexOf(
                                                      '4'
                                                  ) !== -1 &&
                                                  game.platform_detail !==
                                                      undefined ? (
                                                      <Link
                                                          to="#"
                                                          className="mb-icon"
                                                          onClick={e =>
                                                              redirectURL(
                                                                  game
                                                                      .download[4],
                                                                  '_blank'
                                                              )
                                                          }
                                                      >
                                                          <img
                                                              src={
                                                                  IMAGE_DNS +
                                                                  '/Valofe_file/web/vfun-ko/images/common/icon/icon-one-min.png'
                                                              }
                                                              alt="Onestore Downalod"
                                                              name="icon-one"
                                                          />
                                                      </Link>
                                                  ) : null}
                                                  {game.platform_detail.indexOf(
                                                      '5'
                                                  ) !== -1 &&
                                                  game.platform_detail !==
                                                      undefined ? (
                                                      <Link
                                                          to="#"
                                                          className="mb-icon"
                                                          onClick={e =>
                                                              redirectURL(
                                                                  game
                                                                      .download[5],
                                                                  '_blank'
                                                              )
                                                          }
                                                      >
                                                          <img
                                                              src={
                                                                  IMAGE_DNS +
                                                                  '/Valofe_file/web/vfun-ko/images/common/icon/icon-gxy-min.png'
                                                              }
                                                              alt="Galaxy Downalod"
                                                              name="icon-gxy"
                                                          />
                                                      </Link>
                                                  ) : null}
                                                  {game.platform_detail.indexOf(
                                                      '6'
                                                  ) !== -1 &&
                                                  game.platform_detail !==
                                                      undefined ? (
                                                      <Link
                                                          to="#"
                                                          className="mb-icon"
                                                          onClick={e =>
                                                              redirectURL(
                                                                  game
                                                                      .download[6],
                                                                  '_blank'
                                                              )
                                                          }
                                                      >
                                                          <img
                                                              src={
                                                                  IMAGE_DNS +
                                                                  '/Valofe_file/web/vfun-ko/images/common/icon/icon-kakao-min.png'
                                                              }
                                                              alt="Kakao Downalod"
                                                              name="icon-kakao"
                                                          />
                                                      </Link>
                                                  ) : null}
                                              </div>
                                          </div>

                                          <Link
                                              to="#"
                                              onClick={e =>
                                                  redirectURL(
                                                      game.service_code ===
                                                          'fwtr'
                                                          ? game.url
                                                          : '/game/detail/' +
                                                                game.service_code.replace(
                                                                    '-',
                                                                    ''
                                                                ),
                                                      game.service_code ===
                                                          'fwtr'
                                                          ? '_blank'
                                                          : '_self'
                                                  )
                                              }
                                              className="btn-mb-download"
                                          >
                                              Details
                                          </Link>
                                      </div>
                                  </li>
                              ))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default AllGamesTable
