import React from 'react'
import { Link } from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn'
import classNames from 'classnames/bind'
import InfoContext from '../../contexts/InfoContext'
import CrtThemeNormal from './theme/CrtThemeNormal'

//import CrtThemeXmas from './theme/CrtThemeXmas'
//import CrtThemeBlackfriday from './theme/CrtThemeBlackfriday'
//import'./theme/blackfriday.scss'
//import'./theme/xmas.scss'

/* VFUN-커뮤니티일 때만 필요함 */
//import CommunityMenuMobile from '../community/CommunityMenuMobile'
/* ./ VFUN-커뮤니티일 때만 필요함 */
import {
    BILLING_CHARGE_URL,
    SIGN_IN_URL,
    SIGN_UP_URL,
    FIND_ID_URL,
    TERMS_URL,
    PRIVACY_URL,
    MY_INFO_URL,
} from '../../utils/Config'
import { redirectURL } from '../../utils/CommonFunc'


class MobileHeader extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        this.state = {
            isLogin: context.state.is_login,
            is_mobile_header_menu_open:
            context.state.is_mobile_header_menu_open,
            current_menu: props.current_menu,
            matches: window.matchMedia('(min-width:1024px)').matches,
        }
    }

    goMenu(url) {
        this.context.actions.setIsMobileHeaderMenuOpen(false)
        this.setState({
            is_combine_menu_open: false,
        })
        redirectURL(url, '_self')
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.is_mobile_header_menu_open === true) {
            this.context.actions.setIsCombineMenuOpen(false)
            this.setState({ is_mobile_header_menu_open: false })
        }
    }

    handelMobileHeader() {
        this.context.actions.setIsMobileHeaderMenuOpen(
            !this.state.is_mobile_header_menu_open
        )
        this.setState({
            is_mobile_header_menu_open: !this.state.is_mobile_header_menu_open,
        })
    }


    render() {
        return (
            <>

                <header id="mobileHeader" className="clearfix">
                    <Link to="/" id="crtLogo"/>
                {/*    <h1 id="crtMenu" onClick={e => this.handelMobileHeader()}>*/}
                {/*        Home&nbsp;*/}
                {/*        <span*/}
                {/*            className={*/}
                {/*                this.state.is_mobile_header_menu_open === true*/}
                {/*                    ? classNames('fa fa-sort-up')*/}
                {/*                    : classNames('fa fa-sort-down')*/}
                {/*            }*/}
                {/*        />*/}
                {/*    </h1>*/}
                </header>
                {/*<FadeIn*/}
                {/*    visible={this.state.is_mobile_header_menu_open}*/}
                {/*    delay={5}*/}
                {/*    transitionDuration={100}*/}
                {/*>*/}
                {/*    <div id="mbSub" className="on">*/}
                {/*        <h1>*/}
                {/*            VFUN&nbsp;*/}
                {/*            <span className="valofe-platform">*/}
                {/*                | VALOFE Platform*/}
                {/*            </span>*/}
                {/*        </h1>*/}
                {/*        <ul className="dep1">*/}
                {/*            <li*/}
                {/*                className={*/}
                {/*                    this.state.current_menu === 'home'*/}
                {/*                        ? 'on'*/}
                {/*                        : ''*/}
                {/*                }*/}
                {/*            >*/}
                {/*                <Link to="#" onClick={e => this.goMenu('/')}>*/}
                {/*                    홈*/}
                {/*                </Link>*/}
                {/*            </li>*/}
                {/*            <li*/}
                {/*                className={*/}
                {/*                    this.state.current_menu === 'all_games' ||*/}
                {/*                    this.state.current_menu === 'download'*/}
                {/*                        ? 'on'*/}
                {/*                        : ''*/}
                {/*                }*/}
                {/*            >*/}
                {/*                <Link to="#">*/}
                {/*                    게임*/}
                {/*                    <span className="fa fa-sort-down" />*/}
                {/*                </Link>*/}
                {/*                <ul className="dep2">*/}
                {/*                    <li*/}
                {/*                        className={*/}
                {/*                            this.state.current_menu ===*/}
                {/*                            'all_games'*/}
                {/*                                ? 'on'*/}
                {/*                                : ''*/}
                {/*                        }*/}
                {/*                    >*/}
                {/*                        <Link*/}
                {/*                            to="#"*/}
                {/*                            onClick={e =>*/}
                {/*                                this.goMenu('/game/all_games')*/}
                {/*                            }*/}
                {/*                        >*/}
                {/*                            전체 게임/!*<!-- 전체 게임 -->*!/*/}
                {/*                        </Link>*/}
                {/*                    </li>*/}
                {/*                </ul>*/}
                {/*            </li>*/}
                {/*            /!*vlounge*!/*/}
                {/*            <li className="">*/}
                {/*                <Link to="#" onClick={(e) => this.goMenu("https://vfun-lounge-kr.valofe.com/page/luniaz-ko")}>*/}
                {/*                    VFUN 라운지 <small className="new">N</small>*/}
                {/*                    /!*<span className="fa fa-sort-down" />*!/*/}
                {/*                </Link>*/}
                {/*                <ul className="dep2">*/}
                {/*                    <li className="">*/}

                {/*                        <Link to="#"*/}
                {/*                              onClick={(e) =>*/}
                {/*                                  this.goMenu("https://vfun-lounge-kr.valofe.com/page/luniaz-ko")*/}
                {/*                              }*/}
                {/*                        >루니아Z*/}
                {/*                        </Link>*/}
                {/*                    </li>*/}
                {/*                </ul>*/}
                {/*            </li>*/}
                {/*            <li className="">*/}
                {/*                <Link to="#">*/}
                {/*                    Membership&nbsp;*/}
                {/*                    <span className="fa fa-sort-down" />*/}
                {/*                </Link>*/}
                {/*                {this.state.isLogin === true ? (*/}
                {/*                    <ul className="dep2">*/}
                {/*                        <li className="">*/}
                {/*                            <Link*/}
                {/*                                to="#"*/}
                {/*                                onClick={e =>*/}
                {/*                                    redirectURL(*/}
                {/*                                        MY_INFO_URL,*/}
                {/*                                        '_self'*/}
                {/*                                    )*/}
                {/*                                }*/}
                {/*                            >*/}
                {/*                                내 정보*/}
                {/*                            </Link>*/}
                {/*                        </li>*/}
                {/*                        <li className="">*/}
                {/*                            <Link*/}
                {/*                                to="#"*/}
                {/*                                onClick={e =>*/}
                {/*                                    redirectURL(*/}
                {/*                                        BILLING_CHARGE_URL,*/}
                {/*                                        '_self'*/}
                {/*                                    )*/}
                {/*                                }*/}
                {/*                            >*/}
                {/*                                VCoin*/}
                {/*                            </Link>*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}
                {/*                ) : (*/}
                {/*                    <ul className="dep2">*/}
                {/*                        <li className="">*/}
                {/*                            <Link*/}
                {/*                                to="#"*/}
                {/*                                onClick={e =>*/}
                {/*                                    redirectURL(*/}
                {/*                                        SIGN_UP_URL,*/}
                {/*                                        '_self'*/}
                {/*                                    )*/}
                {/*                                }*/}
                {/*                            >*/}
                {/*                                회원가입*/}
                {/*                            </Link>*/}
                {/*                        </li>*/}
                {/*                        <li className="">*/}
                {/*                            <Link*/}
                {/*                                to="#"*/}
                {/*                                onClick={e =>*/}
                {/*                                    redirectURL(*/}
                {/*                                        SIGN_IN_URL,*/}
                {/*                                        '_self'*/}
                {/*                                    )*/}
                {/*                                }*/}
                {/*                            >*/}
                {/*                                로그인*/}
                {/*                            </Link>*/}
                {/*                        </li>*/}
                {/*                        <li className="">*/}
                {/*                            <Link*/}
                {/*                                to="#"*/}
                {/*                                onClick={e =>*/}
                {/*                                    redirectURL(*/}
                {/*                                        FIND_ID_URL,*/}
                {/*                                        '_self'*/}
                {/*                                    )*/}
                {/*                                }*/}
                {/*                            >*/}
                {/*                                아이디 / 비밀번호 찾기*/}
                {/*                            </Link>*/}
                {/*                        </li>*/}
                {/*                        <li className="">*/}
                {/*                            <Link*/}
                {/*                                to="#"*/}
                {/*                                onClick={e =>*/}
                {/*                                    redirectURL(*/}
                {/*                                        BILLING_CHARGE_URL,*/}
                {/*                                        '_self'*/}
                {/*                                    )*/}
                {/*                                }*/}
                {/*                            >*/}
                {/*                                VCoin*/}
                {/*                            </Link>*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}
                {/*                )}*/}
                {/*            </li>*/}
                {/*            <li className="">*/}
                {/*                <Link to="#">*/}
                {/*                    Support*/}
                {/*                    <span className="fa fa-sort-down" />*/}
                {/*                </Link>*/}
                {/*                <ul className="dep2">*/}
                {/*                    <li className="">*/}
                {/*                        <Link*/}
                {/*                            to="#"*/}
                {/*                            onClick={e =>*/}
                {/*                                redirectURL(TERMS_URL, '_self')*/}
                {/*                            }*/}
                {/*                        >*/}
                {/*                            이용 약관*/}
                {/*                        </Link>*/}
                {/*                    </li>*/}
                {/*                    <li className="">*/}
                {/*                        <Link*/}
                {/*                            to="#"*/}
                {/*                            onClick={e =>*/}
                {/*                                redirectURL(*/}
                {/*                                    PRIVACY_URL,*/}
                {/*                                    '_self'*/}
                {/*                                )*/}
                {/*                            }*/}
                {/*                        >*/}
                {/*                            개인정보 보호정책*/}
                {/*                        </Link>*/}
                {/*                    </li>*/}
                {/*                </ul>*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <CrtThemeNormal />*/}
                {/*                /!* 노멀  *!/*/}
                {/*                /!*<CrtThemeXmas />*!/*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*        <Link*/}
                {/*            to="#"*/}
                {/*            id="closeMbSub"*/}
                {/*            className="on"*/}
                {/*            onClick={e => this.handelMobileHeader()}*/}
                {/*        >*/}
                {/*            <span className="fa fa-times-circle-o" />*/}
                {/*        </Link>*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        id="mbMenuShadow"*/}
                {/*        className="on"*/}
                {/*        onClick={e => this.handelMobileHeader()}*/}
                {/*    />*/}
                {/*</FadeIn>*/}

            </>
        )
    }
}

export default MobileHeader
