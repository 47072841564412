import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Spinner from '../../components/common/Spinner'

import { IMAGE_DNS } from '../../utils/Config'
import { redirectURL } from '../../utils/CommonFunc'
import InfoContext from '../../contexts/InfoContext'

import '../../assets/css/main/AllGamesList.scoped.scss'

class AllGamesList extends React.Component {
    static contextType = InfoContext

    constructor(props, context) {
        super(props)
        this.state = {
            isLoading: true,
            ssoinfo:
                context.state.ssoinfo === false
                    ? 'none'
                    : context.state.ssoinfo,
            userid:
                context.state.user_id === false
                    ? 'none'
                    : context.state.user_id,
            jumin: context.state.jumin === false ? 'none' : context.state.jumin,
            PCGameList: '',
            MobileGameList: '',
        }
    }

    componentDidMount() {
        this.getAllGameList()
    }

    getAllGameList() {
        let data = {}
        axios
            .post(
                process.env.REACT_APP_VFUN_API_DNS + '/v1/Vfun/getAllGameInfo',
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ssoinfo: this.state.ssoinfo,
                        userid: this.state.userid,
                        jumin: this.state.jumin,
                    },
                }
            )
            .then(res => {
                if (parseInt(res.data.result_code) === 1) {
                    var PCGameList = []
                    var MobileGameList = []
                    var pc_key = 0
                    var mobile_key = 0
                    res.data.data.map((game, index) => {
                        if (game.platform.includes('PC')) {
                            PCGameList[pc_key] = game
                            ++pc_key
                        }
                        if (game.platform.includes('MOBILE')) {
                            MobileGameList[mobile_key] = game
                            ++mobile_key
                        }
                    })
                }
                this.setState({
                    PCGameList: PCGameList,
                    MobileGameList: MobileGameList,
                    isLoading: false,
                })
            })
            .catch(function(error) {
                if (error.response.status >= 500) {
                    alert(
                        '시스템에서 오류가 발생했습니다.\n잠시후에 다시 시도해주세요.'
                    )
                }
            })
    }

    render() {
        return (
            <div className="main all-list _2 on clearfix">
                {this.state.isLoading === true ? <Spinner /> : null}
                <div className="pc-list">
                    <h2>PC 게임</h2>
                    <ul className="clearfix">
                        {this.state.isLoading === true
                            ? null
                            : this.state.PCGameList.map((game, index) => (
                                  <li className="">
                                      <Link
                                          to="#"
                                          onClick={e =>
                                              redirectURL(game.url, '_blank')
                                          }
                                      >
                                          <img
                                              src={
                                                  IMAGE_DNS +
                                                  '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                  game.service_code.replace(
                                                      '-',
                                                      ''
                                                  ) +
                                                  '-icon-min.png'
                                              }
                                              alt={game.title}
                                          />
                                          {game.title}
                                      </Link>
                                  </li>
                              ))}
                    </ul>
                </div>
                <div className="mobile-list">
                    <h2>모바일 게임</h2>
                    <ul className="clearfix">
                        {this.state.isLoading === true
                            ? null
                            : this.state.MobileGameList.map((game, index) => (
                                  <li className="update">
                                      <Link
                                          to="#"
                                          onClick={e =>
                                              redirectURL(
                                                  game.service_code === 'fwtr'
                                                      ? game.url
                                                      : '/game/detail/' +
                                                            game.service_code.replace(
                                                                '-',
                                                                ''
                                                            ),
                                                  game.service_code === 'fwtr'
                                                      ? '_blank'
                                                      : '_self'
                                              )
                                          }
                                      >
                                          <img
                                              src={
                                                  IMAGE_DNS +
                                                  '/Valofe_file/web/vfun-ko/images/game/enroll/' +
                                                  game.service_code.replace(
                                                      '-',
                                                      ''
                                                  ) +
                                                  '-icon-min.png'
                                              }
                                              alt={game.title}
                                          />
                                          {game.title}
                                      </Link>
                                  </li>
                              ))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default AllGamesList
