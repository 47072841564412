import React from 'react'

import PageHeader from './pageHeader/PageHeader.js'
import PageProfile from './pageMenu/PageProfile'
import PageSearch from './pageMenu/PageSearch'
import PageCategory from './pageMenu/PageCategory'
import PageGameList from './pageMenu/PageGameList'

import PageRight from './pageRight/PageRight'
import './Page.scoped.scss'
import PageMobileActivity from './pageMenu/PageMobileActivity'
import BottomTheme from '../common/theme/BottomTheme'

class Page extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            service_code: props.service_code,
            articleUrl: '',
            articlePage: false,
            isOpenPopImg: props.isOpenPopImg,
            matches: window.matchMedia('(min-width: 768px)').matches,
        }
    }
    handler768(e) {
        this.setState({ matches: e.matches })
    }

    componentDidMount() {
        const handler768 = e => {
            this.setState({ matches: e.matches })
        }
        window
            .matchMedia('(min-width: 768px)')
            .addEventListener('change', e => handler768(e))
    }
    /*
    fnSeeMore(articleNum) {
        this.setState({
            articleUrl: articleNum,
            articlePage: !this.state.articlePage,
        })
    }*/
    render() {
        return (
            <>
                {this.state.matches && (
                    <section className={'page ' + this.state.service_code}>
                        <PageHeader service_code={this.state.service_code} />
                        <div className="page-body">
                            <div className="row-w clearfix">
                                <div className="page-left">
                                    <PageProfile />
                                    <PageSearch />
                                    <PageCategory
                                        service_code={this.state.service_code}
                                        selectBoardSection={e =>
                                            this.props.selectBoardSection(e)
                                        }
                                    />
                                    <PageGameList />
                                </div>
                                <PageRight
                                    handlePopPageWrite={e =>
                                        this.props.handlePopPageWrite()
                                    }
                                    handlePopPageImg={e =>
                                        this.props.handlePopPageImg(e)
                                    }
                                    service_code={this.state.service_code}
                                    isOpenPopImg={this.state.isOpenPopImg}
                                    boardRefresh={this.props.boardRefresh}
                                    editBoardInfo={e =>
                                        this.props.editBoardInfo(e)
                                    }
                                    section={this.props.section}
                                    handleReplyWrite={e =>
                                        this.props.handleReplyWrite(e)
                                    }
                                    handleBoardRefresh={e =>
                                        this.props.handleBoardRefresh()
                                    }
                                    handleReplyEdit={e =>
                                        this.props.handleReplyEdit(e)
                                    }
                                />
                            </div>
                        </div>
                        <BottomTheme />
                    </section>
                )}

                {!this.state.matches && (
                    <section className="page fishisland-ko">
                        <PageHeader service_code={this.state.service_code} />
                        <PageMobileActivity />
                        <PageCategory
                            handler768={e => this.props.handler768()}
                            service_code={this.state.service_code}
                            selectBoardSection={e =>
                                this.props.selectBoardSection(e)
                            }
                        />
                        <div className="page-body">
                            <div className="row-w clearfix">
                                <PageRight
                                    handlePopPageWrite={e =>
                                        this.props.handlePopPageWrite()
                                    }
                                    handlePopPageImg={e =>
                                        this.props.handlePopPageImg()
                                    }
                                    service_code={this.state.service_code}
                                    isOpenPopImg={this.state.isOpenPopImg}
                                    boardRefresh={this.props.boardRefresh}
                                    editBoardInfo={e =>
                                        this.props.editBoardInfo(e)
                                    }
                                    section={this.props.section}
                                />
                            </div>
                        </div>
                      {/*  <BottomTheme />*/}
                    </section>

                )}
            </>
        )
    }
}

export default Page
